import { useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { AppLayoutContext } from '../../../providers/AppLayout'
import { useAppNavigate, useUrlStateParams } from '../../../utils/url'
import { SnackbarContext } from '../../../providers/SnackbarContext'
import { formatDate } from '../../../utils/formatting'

import { AppPermissions } from '../../../models/AppPermissions.model'
import { TrailFeature } from '../../../models/TrailFeature.model'

import UserService from '../../../services/UserService/UserService'
import { TrailFeatureService } from '../../../services/TrailFeatureService/TrailFeatureService'

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Paper,
  Stack,
  Typography,
} from '@mui/material'
import Loading from '../../../components/Loading/Loading.component'
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs.component'
import TrailSearch from '../../Trails/TrailSearch/TrailSearch.route'
import Tabs from '../../../components/Tabs/Tabs.component'
import TrailFeatureForm from '../shared/TrailFeatureForm.component'
import FormDialog from '../../../components/FormDialog/FormDialog.component'
import TrailsWithFeatureForm from '../shared/TrailsWithFeatureForm.component'
import { useQuery, useQueryClient } from 'react-query'
import { AppStateContext } from '../../../providers/AppStateContext'
import ImportExportIcon from '@mui/icons-material/ImportExport'

const TrailFeatureDetail = (): JSX.Element => {
  const trailSearchRef = useRef<{ openImportExport: () => void }>(null)
  const navigate = useAppNavigate()
  const { id } = useParams<{ id: string }>()
  const [loadingError, setLoadingError] = useState('')

  const { user } = useAuth0()
  const hasWritePermissions = UserService.hasPermissions(user, [AppPermissions.editTrailFeature])
  const hasTrailPermissions = UserService.hasPermissions(user, [AppPermissions.viewTrail])

  const [isSaving, setIsSaving] = useState(false)
  const [isEditing, setIsEditing] = useUrlStateParams<boolean>(
    false,
    'edit',
    (value) => (value ? 't' : ''),
    (value) => value === 't',
  )
  const [isEditingTrailsWithFeature, setIsEditingTrailsWithFeature] = useState(false)
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false)

  const trailFeatureService = useMemo(() => new TrailFeatureService(), []) // memo to prevent re-creating service on every render

  useEffect(() => {
    AppLayoutContext.setPageName(`Trail Feature Detail`)
  }, [id])

  const queryClient = useQueryClient()
  const { isLoading, data: trailFeature } = useQuery(
    [
      'TrailService.get',
      {
        id: Number(id),
        relations: [],
      },
      AppStateContext.getRegion(),
    ],
    () =>
      trailFeatureService.get({
        id: Number(id),
        relations: [],
      }),
    {
      onError: (err) => {
        setLoadingError(typeof err === 'string' ? err : 'Failed to load trail feature.')
        console.error(err)
      },
    },
  )

  const handleEdit = () => {
    setIsEditing(false)
    setIsEditingTrailsWithFeature(false)
  }

  const handleDeleteClick = () => {
    // show a confirmation dialog before deleting
    setIsDeleteConfirmationOpen(true)
  }

  const handleDelete = () => {
    if (trailFeature?.id) {
      setIsSaving(true)
      setIsDeleteConfirmationOpen(false)
      trailFeatureService
        .delete(trailFeature.id)
        .then(() => {
          SnackbarContext.show(`Trail Feature deleted successfully. You will now be redirected.`)

          // after a delay of 1 second, redirect to the trailFeature list page
          setTimeout(() => {
            navigate(`/trail-features`)
            queryClient.removeQueries([
              'TrailFeatureService.get',
              {
                id: Number(id),
                relations: [],
              },
            ])
            // invalidate all queries to ensure any queries that are using the modified record are updated
            queryClient.invalidateQueries()
          }, 1000)
        })
        .catch((err) => {
          console.error(err)
          SnackbarContext.show(`Trail Feature failed to delete: ${err}`, 'error')
          setIsSaving(false)
        })
    }
  }

  const tabs = useMemo(() => {
    const tabs = []
    if (trailFeature) {
      if (hasTrailPermissions) {
        tabs.push({
          label: 'Trails',
          content: (
            <Paper sx={{ p: 2 }}>
              <Stack direction="row" justifyContent="space-between" spacing={2} mb={2}>
                <Button
                  variant="outlined"
                  startIcon={<ImportExportIcon />}
                  color="primary"
                  aria-label="show import export"
                  onClick={() => {
                    trailSearchRef.current?.openImportExport()
                  }}
                >
                  Export
                </Button>
                {hasWritePermissions && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setIsEditingTrailsWithFeature(true)
                    }}
                  >
                    Edit Trail Assignments
                  </Button>
                )}
              </Stack>
              <TrailSearch
                nested
                hideHeader
                hideBreadcrumbs
                hideActions
                disableImport
                disablePadding
                defaultParams={{
                  trailFeatureIds: [trailFeature.id],
                }}
                ref={trailSearchRef}
              />
            </Paper>
          ),
        })
      }
    }
    return tabs
  }, [trailFeature, hasTrailPermissions])

  return (
    <>
      {trailFeature && (
        <FormDialog<TrailFeature>
          open={isEditing}
          entity={trailFeature}
          entityLabel="Trail Feature"
          onClose={() => setIsEditing(false)}
        >
          <TrailFeatureForm trailFeature={trailFeature} onEdit={handleEdit} onCancel={() => setIsEditing(false)} />
        </FormDialog>
      )}
      {trailFeature && (
        <FormDialog<TrailFeature>
          open={isEditingTrailsWithFeature}
          entity={trailFeature}
          entityLabel="Trails with Feature"
          onClose={() => setIsEditingTrailsWithFeature(false)}
        >
          <TrailsWithFeatureForm
            trailFeature={trailFeature}
            onEdit={handleEdit}
            onCancel={() => setIsEditingTrailsWithFeature(false)}
          />
        </FormDialog>
      )}
      <Dialog
        fullWidth
        maxWidth="xs"
        open={isDeleteConfirmationOpen}
        onClose={() => setIsDeleteConfirmationOpen(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent dividers>Are you sure you want to delete this trail feature?</DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setIsDeleteConfirmationOpen(false)}>
            Cancel
          </Button>
          <Button onClick={handleDelete}>Confirm</Button>
        </DialogActions>
      </Dialog>
      <Paper sx={{ p: 2 }}>
        {isLoading && !loadingError && <Loading sx={{ py: 20 }} />}
        {loadingError && (
          <Stack direction="row" justifyContent="center" alignItems="center" sx={{ py: 20 }}>
            <Typography variant="error">{loadingError}</Typography>
          </Stack>
        )}
        {!isLoading && !loadingError && trailFeature && (
          <>
            {isSaving && <Loading sx={{ py: 20 }} />}
            {!isSaving && (
              <>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{ mb: 2 }}>
                  <Breadcrumbs
                    items={() => {
                      const breadcrumbs: Array<{ label: string; href?: string }> = [
                        {
                          label: 'Trail Features',
                          href: '/trail-features',
                        },
                      ]
                      breadcrumbs.push({ label: trailFeature.name })

                      return breadcrumbs
                    }}
                  />
                  {hasWritePermissions && (
                    <Stack direction="row" justifyContent="end" alignItems="center" spacing={2}>
                      <Button variant="text" onClick={handleDeleteClick}>
                        Delete
                      </Button>
                      <Button variant="contained" onClick={() => setIsEditing(true)}>
                        Edit
                      </Button>
                    </Stack>
                  )}
                </Stack>
                <Box>
                  <Box>
                    <Typography variant="h6" component="h3">
                      {trailFeature.name}
                    </Typography>
                    <Typography variant="subtitle1" color={(theme) => theme.palette.grey[500]}>
                      ID: {trailFeature.id}
                    </Typography>
                    {!trailFeature.active && (
                      <Typography variant="subtitle1" color={(theme) => theme.palette.error.main}>
                        Inactive
                      </Typography>
                    )}
                  </Box>
                  <Divider sx={{ my: 2 }} />
                  <Box>
                    <Typography variant="body1">Updated At: {formatDate(trailFeature.updatedAt)}</Typography>
                    <Typography variant="body1">Created At: {formatDate(trailFeature.createdAt)}</Typography>
                  </Box>
                </Box>
                {tabs.length > 0 && (
                  <Box sx={{ mt: 4 }}>
                    <Tabs tabs={tabs} />
                  </Box>
                )}
              </>
            )}
          </>
        )}
      </Paper>
    </>
  )
}
export default TrailFeatureDetail

import { useState } from 'react'

import { Box, Tab as MuiTab, Tabs as MuiTabs } from '@mui/material'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

type Tab = {
  label: string
  content: React.ReactNode
}

type TabsProps = {
  tabs: Tab[]
}
export default function Tabs(props: TabsProps): JSX.Element {
  const { tabs } = props
  const [value, setValue] = useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <MuiTabs value={value} onChange={handleChange} aria-label="tabs">
          {tabs.map((tab, index) => (
            <MuiTab key={tab.label} label={tab.label} {...a11yProps(index)} />
          ))}
        </MuiTabs>
      </Box>
      {tabs.map((tab, index) => (
        <TabPanel key={tab.label} value={value} index={index}>
          {tab.content}
        </TabPanel>
      ))}
    </Box>
  )
}

import React, { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

import Loading from '../components/Loading/Loading.component'
import { Box } from '@mui/material'

type AuthStateContextType = {
  getToken: () => string
}
export const AuthStateContext: AuthStateContextType = {
  getToken: () => {
    // if used outside of AuthStateProvider, this function will not have been replaced so throw error
    throw new Error('AuthStateContext must be used within a AuthStateProvider')
  },
}

type AuthStateProviderProps = { children: React.ReactNode }

export const AuthStateProvider = ({ children }: AuthStateProviderProps): JSX.Element => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN || ''

  const [authToken, setAuthToken] = React.useState<string>('')
  const [tokenLoading, setTokenLoading] = React.useState(true)

  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0()

  useEffect(() => {
    // format the user data
    if (isAuthenticated && user) {
      setTokenLoading(true)
      getAccessTokenSilently({
        authorizationParams: {
          audience: `https://${domain}/api/v2/`,
          scope: 'read:current_user',
        },
      })
        .then((accessToken) => {
          // console.log(accessToken)
          setAuthToken(accessToken)
          setTokenLoading(false)
        })
        .catch((error) => {
          console.error(error)
          setAuthToken('')
          setTokenLoading(false)
        })
    } else {
      setAuthToken('')
      setTokenLoading(false)
    }
  }, [isAuthenticated, user])

  AuthStateContext.getToken = () => {
    return authToken
  }
  return (
    <>
      {tokenLoading ? (
        <Box sx={{ width: '100%', height: '100vh' }}>
          <Loading />
        </Box>
      ) : (
        children
      )}
    </>
  )
}

import { useState } from 'react'
import * as d3 from 'd3'

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import FileInput from '../FileUploadInput/FileUploadInput.component'
import DataTable from '../DataTable/DataTable.component'
import { GridColDef } from '@mui/x-data-grid'
import CloseIcon from '@mui/icons-material/Close'

export interface ImportDialogProps {
  open: boolean
  exampleImportFileUrl?: string
  importFields: string[]
  onImport: ImportExportDialogProps['onImport']
  onClose: () => void
}

const ImportDialog = (props: ImportDialogProps): JSX.Element => {
  const { open, exampleImportFileUrl, importFields, onImport, onClose } = props
  const [stage, setStage] = useState<'upload' | 'map' | 'settings' | 'confirm'>('upload')
  const [file, setFile] = useState<File | null>(null)
  const [fileContents, setFileContents] = useState<Record<string, unknown>[] | null>(null)
  const [columnDefsMap, setColumnDefsMap] = useState<Record<string, string>>({})
  const [columnDefs, setColumnDefs] = useState<GridColDef[]>([])
  const [matchingField, setMatchingField] = useState<string | undefined>(undefined)
  const [tempMatchingField, setTempMatchingField] = useState<string | undefined>(undefined)
  const [deleteUnmatchedExisting, setDeleteUnmatchedExisting] = useState<boolean>(false)
  const [tempDeleteUnmatchedExisting, setTempDeleteUnmatchedExisting] = useState<boolean>(false)

  const handleImport = async () => {
    if (typeof onImport === 'function' && fileContents) {
      // generate importData array based on the contents of the data grid fileContents
      const importData: Record<string, unknown>[] = []
      fileContents.forEach((row) => {
        const importRow: Record<string, unknown> = {}
        columnDefs.forEach((colDef) => {
          const key = colDef.field
          const headerName = colDef.headerName
          if (key && headerName) {
            importRow[headerName] = row[key]
          }
        })
        importData.push(importRow)
      })
      setStage('upload') // reset the stage
      setFile(null) // clear the file input state
      setFileContents(null) // clear the file contents state
      setColumnDefsMap({}) // clear the column defs map state
      setColumnDefs([]) // clear the column defs state
      setMatchingField(undefined) // clear the matching field state
      setTempMatchingField(undefined) // clear the temp matching field state
      setDeleteUnmatchedExisting(false) // clear the delete missing state
      setTempDeleteUnmatchedExisting(false) // clear the temp delete missing state
      onImport(importData, { matchingField, deleteUnmatchedExisting })
    }
  }

  const applyImportSettings = () => {
    setMatchingField(tempMatchingField !== 'import_settings_no_matching_field' ? tempMatchingField : undefined)
    setDeleteUnmatchedExisting(
      tempMatchingField && tempMatchingField !== 'import_settings_no_matching_field'
        ? tempDeleteUnmatchedExisting
        : false,
    )

    setStage('confirm')
  }

  const applyColumnDefs = () => {
    let fileContentsColumnDefs: GridColDef[] = []
    if (columnDefsMap && fileContents) {
      // generate the column defs dynamically from the fileContents
      fileContentsColumnDefs = Object.keys(columnDefsMap)
        .filter((key) => columnDefsMap[key] !== 'import_exclude_field_from_data')
        .map((key) => {
          return {
            field: key,
            headerName: columnDefsMap[key],
            width: 200,
            sortable: false,
          }
        })
    }
    setColumnDefs(fileContentsColumnDefs)

    setStage('settings')
  }

  const readFile = async () => {
    let data: Record<string, unknown>[] | null = null
    if (file) {
      data = await new Promise((resolve) => {
        let fileData: Record<string, unknown>[]
        const reader = new FileReader()
        reader.onload = function (event) {
          const text = event?.target?.result
          if (typeof text === 'string') {
            fileData = d3.csvParse(text)
          }
          resolve(fileData)
        }
        reader.onerror = function (event) {
          console.error(event)
          resolve(null)
        }
        reader.readAsText(file)
      })
    }
    setFileContents(data)

    const fileContentsColumnDefsMap: Record<string, string> = {}
    if (data) {
      // generate the column defs map dynamically from the fileContents
      Object.keys(data[0]).forEach((key) => {
        fileContentsColumnDefsMap[key] = importFields.includes(key) ? key : 'import_exclude_field_from_data'
      })
    }
    setColumnDefsMap(fileContentsColumnDefsMap)

    setStage('map')
  }

  const handleFileChange = (file: File | null) => {
    setFile(file)
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={stage === 'upload' ? 'xs' : 'xl'}>
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between">
          <Typography>CSV Import</Typography>
          {typeof onClose === 'function' && (
            <IconButton
              aria-label="close"
              onClick={() => onClose()}
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </Stack>
      </DialogTitle>
      <DialogContent>
        {
          {
            upload: (
              <>
                {exampleImportFileUrl && (
                  <Box sx={{ mb: 1 }}>
                    <Button variant="outlined" href={exampleImportFileUrl} target="_blank">
                      Download Example File
                    </Button>
                  </Box>
                )}
                <Box sx={{ mb: 2 }}>
                  CSV file:
                  <FileInput onFileChange={handleFileChange} />
                </Box>
                <Button variant="contained" onClick={readFile} disabled={!file}>
                  Next
                </Button>
              </>
            ),
            map: (
              <>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  We've identified the following columns in your file and assigned them to known fields. Please verify
                  the columns are assigned to the desired fields and make any necessary changes before clicking Next at
                  the bottom.
                </Typography>
                <Grid container spacing={2} sx={{ mb: 2 }}>
                  {fileContents
                    ? Object.keys(fileContents[0]).map((key) => (
                        <Grid item zero={12} sm={6} md={4} key={key}>
                          <FormControl variant="outlined" fullWidth>
                            <InputLabel id={`import-column-map-select-label-${key}`}>{key}</InputLabel>
                            <Select
                              labelId={`import-column-map-select-label-${key}`}
                              id={`import-column-map-select-${key}`}
                              value={columnDefsMap[key] || ''}
                              label={key}
                              onChange={(e) => {
                                setColumnDefsMap((prev) => ({ ...prev, [key]: e.target.value }))
                              }}
                            >
                              <MenuItem value="import_exclude_field_from_data">
                                <em>Don't Import</em>
                              </MenuItem>
                              {importFields.map((importField) => (
                                <MenuItem key={importField} value={importField}>
                                  {importField}
                                </MenuItem>
                              ))}
                            </Select>
                            <FormHelperText sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                              <em>e.g. {String(fileContents[0][key])}</em>
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                      ))
                    : undefined}
                </Grid>
                <Button variant="contained" onClick={applyColumnDefs}>
                  Next
                </Button>
              </>
            ),
            settings: (
              <>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  If you specify a matching field, we will attempt to match the imported data to existing data in the
                  database. Existing data will be updated and data with no match will be added as new records. If you
                  specify no matching, all records in the imported data will be added as new records.
                </Typography>
                <Box sx={{ my: 2 }}>
                  <FormControl variant="outlined" fullWidth sx={{ mb: 2 }}>
                    <InputLabel id="matching-field-select-label">Matching Field</InputLabel>
                    <Select
                      labelId="matching-field-select-label"
                      id="matching-field-select"
                      value={tempMatchingField || 'import_settings_no_matching_field'}
                      label="Matching Field"
                      onChange={(e) => {
                        setTempMatchingField(e.target.value)
                      }}
                    >
                      <MenuItem value="import_settings_no_matching_field">
                        <em>No matching</em>
                      </MenuItem>
                      {columnDefs.map((columnDef) => (
                        <MenuItem key={columnDef.headerName} value={columnDef.headerName}>
                          {columnDef.headerName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {tempMatchingField && tempMatchingField !== 'import_settings_no_matching_field' && (
                    <>
                      <Typography variant="body2" sx={{ mb: 1 }}>
                        If you specify a matching field, you can choose to delete unmatched existing records. This will
                        delete any existing records that do not have a match in the imported data.
                      </Typography>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel id="delete-unmatched-existing-select-label">
                          Delete Records Not In Imported Data
                        </InputLabel>
                        <Select
                          labelId="delete-unmatched-existing-select-label"
                          id="delete-unmatched-existing-select"
                          value={tempDeleteUnmatchedExisting ? 'yes' : 'no'}
                          label="Delete Records Not In Imported Data"
                          onChange={(e) => {
                            setTempDeleteUnmatchedExisting(e.target.value === 'yes')
                          }}
                        >
                          <MenuItem value="no">No</MenuItem>
                          <MenuItem value="yes">Yes</MenuItem>
                        </Select>
                      </FormControl>
                    </>
                  )}
                </Box>
                <Button variant="contained" onClick={applyImportSettings}>
                  Next
                </Button>
              </>
            ),
            confirm: (
              <>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  Verify your data and import settings. Click Import to continue.
                </Typography>
                {matchingField && (
                  <>
                    <Divider sx={{ mb: 1 }} />
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      <b>Matching existing records by field: {matchingField}</b>
                    </Typography>
                  </>
                )}
                {columnDefs.some((columnDef) => columnDef.headerName === 'id' && matchingField !== 'id') && (
                  <>
                    <Divider sx={{ mb: 1 }} />
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      <b>
                        The id field will never be updated and can only be specified as a field for matching. It will be
                        ignored here.
                      </b>
                    </Typography>
                  </>
                )}
                {deleteUnmatchedExisting && (
                  <>
                    <Divider sx={{ mb: 1 }} />
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      <b>Deleting records no in imported data.</b>
                    </Typography>
                  </>
                )}
                {fileContents ? <DataTable columns={columnDefs} data={fileContents} sx={{ mb: 1 }} /> : undefined}
                <Button variant="contained" onClick={handleImport}>
                  Import
                </Button>
              </>
            ),
          }[stage]
        }
      </DialogContent>
    </Dialog>
  )
}

export interface ImportExportDialogProps {
  open: boolean
  disableImport?: boolean
  exampleImportFileUrl?: string
  importFields?: string[]
  onImport?: (
    data: Record<string, unknown>[],
    options: { matchingField?: string; deleteUnmatchedExisting: boolean },
  ) => void
  disableExport?: boolean
  onExport?: () => void
  onClose: () => void
}

const ImportExportDialog = (props: ImportExportDialogProps): JSX.Element => {
  const {
    open,
    disableImport = false,
    exampleImportFileUrl,
    importFields = [],
    onImport,
    disableExport = false,
    onExport,
    onClose,
  } = props
  const [isImportDialogOpen, setIsImportDialogOpen] = useState(false)

  const showImport = !disableImport && typeof onImport === 'function' && importFields?.length > 0
  const showExport = !disableExport && typeof onExport === 'function'

  const handleListItemClick = (value: string) => {
    if (value === 'import') {
      // display the import dialog
      setIsImportDialogOpen(true)
    } else if (value === 'export') {
      // initiate the export and then close this dialog
      if (typeof onExport === 'function') {
        onExport()
      }
    }
  }

  const handleImport: ImportExportDialogProps['onImport'] = (data, options) => {
    if (typeof onImport === 'function') {
      onImport(data, options)
    }
    setIsImportDialogOpen(false)
  }

  const handleImportClose = () => {
    setIsImportDialogOpen(false)
  }

  return open ? ( // this unload of the component ensure we don't have the dialog rendered unnecessarily when not open and ensures state is reset on each open
    <>
      {showImport && (
        <ImportDialog
          open={isImportDialogOpen}
          exampleImportFileUrl={exampleImportFileUrl}
          importFields={importFields}
          onImport={handleImport}
          onClose={handleImportClose}
        />
      )}
      <Dialog open={open} onClose={onClose}>
        <DialogContent sx={{ p: 0 }}>
          <List sx={{ py: 0 }}>
            {showImport && (
              <ListItem disableGutters disablePadding>
                <ListItemButton
                  onClick={() => handleListItemClick('import')}
                  sx={(theme) => ({ [theme.breakpoints.up('xxxs')]: { p: 10 } })}
                >
                  <ListItemIcon sx={{ minWidth: 'auto', pr: 2 }}>
                    <FileUploadIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="CSV Import"
                    sx={{
                      pr: 2, // this makes the text feel horizontally centered
                    }}
                  />
                </ListItemButton>
              </ListItem>
            )}
            {showImport && showExport && <Divider />}
            {showExport && (
              <ListItem disableGutters disablePadding>
                <ListItemButton
                  onClick={() => handleListItemClick('export')}
                  sx={(theme) => ({ [theme.breakpoints.up('xxxs')]: { p: 10 } })}
                >
                  <ListItemIcon sx={{ minWidth: 'auto', pr: 2 }}>
                    <FileDownloadIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="CSV Export"
                    sx={{
                      pr: 2, // this makes the text feel horizontally centered
                    }}
                  />
                </ListItemButton>
              </ListItem>
            )}
          </List>
        </DialogContent>
      </Dialog>
    </>
  ) : (
    <></>
  )
}
export default ImportExportDialog

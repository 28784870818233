import { AppRegions } from './AppRegions.model'
import { WeatherGroup } from './WeatherGroup.model'
import { WeatherRecordTypes } from './WeatherRecordTypes.model'
import { WeatherSources } from './WeatherSources.model'

export type WeatherRecord = {
  id: number
  region: AppRegions
  weatherGroup?: WeatherGroup
  weatherSource: WeatherSources
  weatherSourceId: string
  type: WeatherRecordTypes
  timestamp: Date
  temperature: number
  tempMin?: number
  tempMax?: number
  feelsLike?: number
  pressure?: number
  relativeHumidity?: number
  precipLast1hr: number
  snowLast1hr?: number
  windSpeed?: number
  windDirection?: number
  uv?: number
  brightness?: number
  sunrise?: Date
  sunset?: Date
  conditionId: number
  wordpressId?: number
  updatedAt: Date
  createdAt: Date
}

// we offer this to enable the enumerate the WeatherRecord type's fields in js/ts files
export const weatherRecordFields: (keyof WeatherRecord)[] = [
  'id',
  'region',
  'weatherGroup',
  'weatherSource',
  'weatherSourceId',
  'type',
  'timestamp',
  'temperature',
  'tempMin',
  'tempMax',
  'feelsLike',
  'pressure',
  'relativeHumidity',
  'precipLast1hr',
  'snowLast1hr',
  'windSpeed',
  'windDirection',
  'uv',
  'brightness',
  'sunrise',
  'sunset',
  'conditionId',
  'wordpressId',
  'updatedAt',
  'createdAt',
]

import { createSvgIcon, SvgIconProps } from '@mui/material'

const SignPostIconRaw = createSvgIcon(
  <path d="M26.7,37.7V48h-3.5V29.6H10.6l-4-4l4-4h12.7v-6.3V7.2V2h3.5v4h12.7l4,4l-4,4H26.7V25V37.7z" />,
  'Sign Post',
)

const SignPostIcon = (props: SvgIconProps) => {
  return <SignPostIconRaw {...props} viewBox="0 0 50 50" />
}

export default SignPostIcon

import { PaginatedResponse } from '../../models/PaginatedResponse.model'
import { Sponsor } from '../../models/Sponsor.model'
import { SponsorDTO } from '../../models/SponsorDTO.model'
import { SponsorAddEvent } from '../../events/SponsorAddEvent'
import { SponsorUpdateEvent } from '../../events/SponsorUpdateEvent'
import { SponsorSearchEvent } from '../../events/SponsorSearchEvent'
import { SponsorRelations } from '../../models/SponsorRelations.model'

import FlowfeedApiQueryService, {
  FlowfeedApiQueryServiceGetEvent,
} from '../FlowfeedApiQueryService/FlowfeedApiQueryService'
import { TrailSystemService } from '../TrailSystemService/TrailSystemService'
import { TrailService } from '../TrailService/TrailService'
import { formatToDateTypeProps, formatToNumberTypeProps } from '../../utils/formatting'

export class SponsorService {
  /**
   * @description Search for a single sponsor by id
   *
   * @param id
   * @param relations
   *
   * @returns promise for a Sponsor
   */
  public async get({ id, relations }: { id?: number; relations?: SponsorRelations[] }): Promise<Sponsor> {
    const params = {
      id,
      relations:
        Array.isArray(relations) && !relations.length
          ? 'false' // false is treated as an empty array by the API since an empty string would be treated as default relations normally
          : relations?.join(',') || undefined,
    }
    const flowfeedApiQueryRequest: FlowfeedApiQueryServiceGetEvent = {
      endpoint: `sponsor/get`,
      params,
    }

    const flowfeedApiQueryService = new FlowfeedApiQueryService()
    try {
      const sponsorRaw = await flowfeedApiQueryService.get<SponsorDTO>(flowfeedApiQueryRequest)
      return SponsorService.formatSponsor(sponsorRaw)
    } catch (error) {
      console.error(error)
      throw 'Sorry, there was a problem with your request.'
    }
  }

  public async search(event: SponsorSearchEvent): Promise<PaginatedResponse<Sponsor>> {
    const params = {
      ...event,
      name: event.name || undefined,
      orderDirection: event.orderDirection?.toUpperCase(),
      relations:
        Array.isArray(event.relations) && !event.relations.length
          ? 'false' // false is treated as an empty array by the API since an empty string would be treated as default relations normally
          : event.relations?.join(',') || undefined,
    }

    const flowfeedApiQueryRequest: FlowfeedApiQueryServiceGetEvent = {
      endpoint: 'sponsor',
      params,
    }

    const sponsors: {
      data: Sponsor[]
      total: number
    } = {
      data: [],
      total: 0,
    }
    try {
      const flowfeedApiQueryService = new FlowfeedApiQueryService()
      const rawSponsors = await flowfeedApiQueryService.get<PaginatedResponse<SponsorDTO>>(flowfeedApiQueryRequest)
      // wrap to avoid errors if the request is cancelled
      if (rawSponsors?.data) {
        sponsors.data = rawSponsors.data.map((sponsor) => SponsorService.formatSponsor(sponsor))
        sponsors.total = rawSponsors.total
      }
    } catch (error) {
      console.error(error)
      throw 'Sorry, there was a problem with your request.'
    }

    return sponsors
  }

  public async add(sponsor: SponsorAddEvent): Promise<Sponsor> {
    const flowfeedApiQueryRequest = {
      endpoint: 'sponsor',
      data: sponsor,
    }

    const flowfeedApiQueryService = new FlowfeedApiQueryService()
    try {
      const sponsorRaw = await flowfeedApiQueryService.post<SponsorDTO>(flowfeedApiQueryRequest)
      return SponsorService.formatSponsor(sponsorRaw)
    } catch (error) {
      console.error(error)
      if (error instanceof Error) {
        throw error.message
      } else if ((error as { data: { message: string } })?.data?.message) {
        throw (error as { data: { message: string } })?.data?.message
      }
      throw error
    }
  }

  public async update(id: number, sponsor: SponsorUpdateEvent): Promise<Sponsor> {
    const flowfeedApiQueryRequest = {
      endpoint: `sponsor/${id}`,
      data: sponsor,
    }
    try {
      const flowfeedApiQueryService = new FlowfeedApiQueryService()
      const sponsorRaw = await flowfeedApiQueryService.patch<SponsorDTO>(flowfeedApiQueryRequest)
      return SponsorService.formatSponsor(sponsorRaw)
    } catch (error) {
      console.error(error)
      if (error instanceof Error) {
        throw error.message
      } else if ((error as { data: { message: string } })?.data?.message) {
        throw (error as { data: { message: string } })?.data?.message
      }
      throw error
    }
  }

  public async delete(id: number): Promise<void> {
    const flowfeedApiQueryRequest = {
      endpoint: `sponsor/${id}`,
    }

    try {
      const flowfeedApiQueryService = new FlowfeedApiQueryService()
      await flowfeedApiQueryService.delete(flowfeedApiQueryRequest)
    } catch (error) {
      console.error(error)
      if (error instanceof Error) {
        throw error.message
      } else if ((error as { data: { message: string } })?.data?.message) {
        throw (error as { data: { message: string } })?.data?.message
      }
      throw error
    }
  }

  public async import(
    sponsors: (Partial<Sponsor> & {
      trailId?: number
      systemId?: number
    })[],
    options?: {
      matchingField?: string | undefined
      deleteUnmatchedExisting?: boolean
    },
  ): Promise<void> {
    const data = sponsors.map((sponsor) => {
      const numberTypeProps = ['id', 'trailId', 'systemId']
      const dateTypeProps = ['start', 'end']
      const formattedSponsor = formatToDateTypeProps(formatToNumberTypeProps(sponsor, numberTypeProps), dateTypeProps)
      return {
        ...formattedSponsor,
        start:
          (formattedSponsor?.start as unknown) === 'null' || (formattedSponsor?.start as unknown) === 'NULL'
            ? null
            : formattedSponsor?.start,
        end:
          (formattedSponsor?.end as unknown) === 'null' || (formattedSponsor?.end as unknown) === 'NULL'
            ? null
            : formattedSponsor?.end,
        active:
          (formattedSponsor?.active as unknown) === ''
            ? undefined
            : formattedSponsor?.active === true ||
              (formattedSponsor?.active as unknown) === 'true' ||
              (formattedSponsor?.active as unknown) === 'TRUE',
      }
    })
    const flowfeedApiQueryRequest = {
      endpoint: `sponsor/sync`,
      data: {
        data,
        matchingProp: options?.matchingField,
        deleteMissing: options?.deleteUnmatchedExisting,
      },
    }

    try {
      const flowfeedApiQueryService = new FlowfeedApiQueryService()
      await flowfeedApiQueryService.post(flowfeedApiQueryRequest)
    } catch (error) {
      console.error(error)
      if (error instanceof Error) {
        throw error.message
      } else if ((error as { data: { message: string } })?.data?.message) {
        throw (error as { data: { message: string } })?.data?.message
      }
      throw error
    }
  }

  public static formatSponsor(sponsor: SponsorDTO): Sponsor {
    return {
      id: sponsor.id,
      name: sponsor.name,
      url: sponsor.url,
      logoUrl: sponsor.logoUrl,
      active: sponsor.active,
      region: sponsor.region,
      system: sponsor.system ? TrailSystemService.formatTrailSystem(sponsor.system) : undefined,
      trail: sponsor.trail ? TrailService.formatTrail(sponsor.trail) : undefined,
      start: sponsor.start ? new Date(sponsor.start) : null,
      end: sponsor.end ? new Date(sponsor.end) : null,
      updatedAt: new Date(sponsor.updatedAt),
      createdAt: new Date(sponsor.createdAt),
    }
  }
}

import { useEffect, useMemo, useState } from 'react'
import lodash from 'lodash'
import { SnackbarContext } from '../../../providers/SnackbarContext'

import { TrailFeature } from '../../../models/TrailFeature.model'
import { TrailFeatureUpdateEvent } from '../../../events/TrailFeatureUpdateEvent'
import { Trail } from '../../../models/Trail.model'

import { TrailFeatureService } from '../../../services/TrailFeatureService/TrailFeatureService'

import { Stack, Button } from '@mui/material'
import Loading from '../../../components/Loading/Loading.component'
import { TrailFeatureRelations } from '../../../models/TrailFeatureRelations.model'
import TrailPicker from '../../../components/TrailPicker/TrailPicker.component'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { AppStateContext } from '../../../providers/AppStateContext'

export type TrailsWithFeatureFormProps = {
  trailFeature: TrailFeature
  onEdit?: (trail: TrailFeature) => void
  onCancel: () => void
  // used to remotely trigger the save action
  saveTrigger?: number
}
const TrailsWithFeatureForm = (props: TrailsWithFeatureFormProps) => {
  const { trailFeature, onCancel, onEdit, saveTrigger } = props

  const [isLoading, setIsLoading] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [originalTrails, setOriginalTrails] = useState<Trail[]>([])
  const [tempTrails, setTempTrails] = useState<Trail[]>([])

  const queryClient = useQueryClient()

  const trailFeatureService = useMemo(() => new TrailFeatureService(), []) // memo to prevent re-creating service on every render
  const { isLoading: isLoadingTrailFeatureWithTrails, data: trailFeatureWithTrails } = useQuery(
    [
      'TrailFeatureService.get',
      { id: trailFeature.id, relations: [TrailFeatureRelations.trails] },
      AppStateContext.getRegion(),
    ],
    () => trailFeatureService.get({ id: trailFeature.id, relations: [TrailFeatureRelations.trails] }),
    {
      onError: (err) => {
        SnackbarContext.show(`Failed to fetch trail feature existing trail assignments for the form: ${err}`, 'error')
        console.error(err)
      },
    },
  )
  // useEffect usage instead of onSuccess or onSettled to ensure we always trigger even when the query is cached
  useEffect(() => {
    if (!trailFeatureWithTrails) return
    setIsLoading(false) // using a separate loading state to prevent the trail picker from being rendered before the tempTrails are set
    setOriginalTrails(trailFeatureWithTrails?.trails ? [...trailFeatureWithTrails.trails] : [])
    setTempTrails(trailFeatureWithTrails?.trails ? [...trailFeatureWithTrails.trails] : [])
  }, [trailFeatureWithTrails])

  const updateTrailFeature = useMutation(
    (mutationParams: { id: number; changedProperties: TrailFeatureUpdateEvent }) => {
      setIsSubmitting(true)
      return trailFeatureService.update(mutationParams.id, mutationParams.changedProperties)
    },
    {
      onSuccess: (result) => {
        SnackbarContext.show('Trail Feature updated successfully!')

        // invalidate all queries to ensure any queries that are using the modified record are updated
        queryClient.invalidateQueries()
        setIsSubmitting(false)

        if (typeof onEdit === 'function') {
          onEdit(result)
        }
      },
      onError: (err) => {
        SnackbarContext.show(`Trail Feature failed to update: ${err}`, 'error')
        console.error(err)
        setIsSubmitting(false)
      },
    },
  )

  const handleSubmit = () => {
    setIsSubmitting(true)

    const changedProperties: TrailFeatureUpdateEvent = {
      id: trailFeature.id,
    }
    if (
      !lodash.isEqual(
        tempTrails.map((each) => each.id),
        originalTrails.map((each) => each.id),
      )
    ) {
      changedProperties.trailIds = tempTrails.map((each) => each.id)
    }

    // edit an existing trail feature
    if (!Object.keys(changedProperties).filter((key) => key !== 'id').length) {
      // nothing changed so just close the dialog
      onCancel()
    } else {
      updateTrailFeature.mutate({ id: trailFeature.id, changedProperties })
    }
  }

  useEffect(() => {
    if (saveTrigger) {
      // if outside consumer triggered save, then submit
      handleSubmit()
    }
  }, [saveTrigger])

  const handleCancel = () => {
    if (typeof onCancel === 'function') {
      onCancel()
    }
  }

  return (
    <>
      {(isLoading || isLoadingTrailFeatureWithTrails || isSubmitting) && <Loading sx={{ py: 20 }} />}
      {!isLoading && !isLoadingTrailFeatureWithTrails && !isSubmitting && (
        <>
          <Stack spacing={2} sx={{ my: 2 }}>
            <TrailPicker selectedTrails={tempTrails} setSelectedTrails={setTempTrails} />
          </Stack>
          <Stack direction="row" justifyContent="end" alignItems="center" spacing={2}>
            <Button variant="outlined" onClick={handleCancel}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSubmit}>
              Save
            </Button>
          </Stack>
        </>
      )}
    </>
  )
}
export default TrailsWithFeatureForm

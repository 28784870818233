import React from 'react'
import { useEffect, useState } from 'react'
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  NavigateOptions,
  useNavigate as routerUseNavigate,
  useSearchParams,
} from 'react-router-dom'

export function useUrlStateParams<T>(
  initialState: T,
  paramName: string,
  serialize: (state: T) => string,
  deserialize: (state: string) => T,
): [T, (state: T | ((prev: T) => T)) => void] {
  const [currentSearchParameters, setSearchParams] = useSearchParams()

  const existingValue = currentSearchParameters.get(paramName)
  const [state, setState] = useState<T>(existingValue ? deserialize(existingValue) : initialState)

  useEffect(() => {
    // Updates state when user navigates backwards or forwards in browser history
    if (existingValue === null && initialState !== state) {
      setState(initialState)
    } else if (existingValue && deserialize(existingValue) !== state) {
      setState(deserialize(existingValue))
    }
  }, [existingValue])

  const onChange = (newValue: T | ((prev: T) => T)) => {
    const newValueProcessed = newValue instanceof Function ? newValue(state) : newValue
    setState(newValueProcessed)
    // create a new read of the search params so we don't end up with a race condition
    // where a sibling call updates the search params using a stale version
    const newSearchParams = new URLSearchParams(window.location.search)
    // if the new value is the same as the initial state or blank, remove the param from the url
    const serializedValue = serialize(newValueProcessed)
    if (newValueProcessed === initialState || serializedValue === undefined || serializedValue === '') {
      newSearchParams.delete(paramName)
    } else {
      newSearchParams.set(paramName, serializedValue)
    }
    // finally update the url
    setSearchParams(newSearchParams)
  }

  return [state, onChange]
}

export function isExternal(url: string): boolean {
  return url.startsWith('http://') || url.startsWith('https://') || url.startsWith('www') || url.startsWith('//')
}

export function useAppNavigate() {
  const navigate = routerUseNavigate()
  const [currentSearchParameters] = useSearchParams()

  return (to: string, options?: NavigateOptions | undefined) => {
    if (isExternal(to)) {
      return navigate(to, options)
    }

    const region = currentSearchParameters.get('region')

    // Create a URL object to easily manipulate the URL
    const url = new URL(to, window.location.origin)

    // If the parameter is not already in the URL, add it
    if (!url.searchParams.has('region') && region) {
      url.searchParams.append('region', region)
    }

    const toWithParams = `${url.pathname}${url.search}${url.hash}`

    return navigate(toWithParams, options)
  }
}

export interface AppLinkProps extends Omit<RouterLinkProps, 'ref'> {
  to: string
}
export const AppLink = React.forwardRef<HTMLAnchorElement | null, AppLinkProps>((props, ref) => {
  const { to, ...other } = props

  if (isExternal(to)) {
    return <RouterLink ref={ref} to={to} {...other} />
  }

  const [currentSearchParameters] = useSearchParams()
  const region = currentSearchParameters.get('region')

  // Create a URL object to easily manipulate the URL
  const url = new URL(to, window.location.origin)

  // If the parameter is not already in the URL, add it
  if (!url.searchParams.has('region') && region) {
    url.searchParams.append('region', region)
  }

  const toWithParams = `${url.pathname}${url.search}${url.hash}`

  return <RouterLink ref={ref} to={toWithParams} {...other} />
})

import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { useQuery, useQueryClient } from 'react-query'
import { AppLayoutContext } from '../../../providers/AppLayout'
import { useAppNavigate, useUrlStateParams } from '../../../utils/url'
import { SnackbarContext } from '../../../providers/SnackbarContext'
import { formatDate } from '../../../utils/formatting'

import { AppPermissions } from '../../../models/AppPermissions.model'
import { WeatherGroup } from '../../../models/WeatherGroup.model'

import UserService from '../../../services/UserService/UserService'
import { WeatherGroupService } from '../../../services/WeatherGroupService/WeatherGroupService'

import WeatherRecordSearch from '../../WeatherRecords/WeatherRecordSearch/WeatherRecordSearch.route'

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Paper,
  Stack,
  Typography,
} from '@mui/material'
import Loading from '../../../components/Loading/Loading.component'
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs.component'
import Tabs from '../../../components/Tabs/Tabs.component'
import FormDialog from '../../../components/FormDialog/FormDialog.component'
import WeatherGroupForm from '../shared/WeatherGroupForm.component'
import { AppStateContext } from '../../../providers/AppStateContext'

const WeatherGroupDetail = (): JSX.Element => {
  const navigate = useAppNavigate()
  const { id } = useParams<{ id: string }>()
  const [loadingError, setLoadingError] = useState('')

  const { user } = useAuth0()
  const hasWritePermissions = UserService.hasPermissions(user, [AppPermissions.editWeatherGroup])
  const hasWeatherRecordPermissions = UserService.hasPermissions(user, [AppPermissions.viewWeatherRecord])

  const [isSaving, setIsSaving] = useState(false)
  const [isEditing, setIsEditing] = useUrlStateParams<boolean>(
    false,
    'edit',
    (value) => (value ? 't' : ''),
    (value) => value === 't',
  )
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false)

  const weatherGroupService = useMemo(() => new WeatherGroupService(), []) // memo to prevent re-creating service on every render

  useEffect(() => {
    AppLayoutContext.setPageName(`Weather Group Detail`)
  }, [id])

  const queryClient = useQueryClient()
  const { isLoading, data: weatherGroup } = useQuery(
    [
      'WeatherGroupService.get',
      {
        id: Number(id),
      },
      AppStateContext.getRegion(),
    ],
    () => weatherGroupService.get(Number(id)),
    {
      onError: (err) => {
        setLoadingError(typeof err === 'string' ? err : 'Failed to load weather group.')
        console.error(err)
      },
    },
  )

  const handleEdit = () => {
    setIsEditing(false)
  }

  const handleDeleteClick = () => {
    // show a confirmation dialog before deleting
    setIsDeleteConfirmationOpen(true)
  }

  const handleDelete = () => {
    if (weatherGroup?.id) {
      setIsSaving(true)
      setIsDeleteConfirmationOpen(false)
      weatherGroupService
        .delete(weatherGroup.id)
        .then(() => {
          SnackbarContext.show(`Weather Group deleted successfully. You will now be redirected.`)

          // after a delay of 1 second, redirect to the weatherGroup list page
          setTimeout(() => {
            navigate('/weather-groups')
            queryClient.removeQueries([
              'WeatherGroupService.get',
              {
                id: Number(id),
              },
            ])
            // invalidate all queries to ensure any queries that are using the modified record are updated
            queryClient.invalidateQueries()
          }, 1000)
        })
        .catch((err) => {
          console.error(err)
          SnackbarContext.show(`Weather Group failed to delete: ${err}`, 'error')
          setIsSaving(false)
        })
    }
  }

  const tabs = useMemo(() => {
    const tabs = []
    if (hasWeatherRecordPermissions) {
      tabs.push({
        label: 'Weather Records',
        content: <WeatherRecordSearch nested weatherGroupId={weatherGroup?.id} hideBreadcrumbs hideHeader />,
      })
    }
    return tabs
  }, [weatherGroup, hasWeatherRecordPermissions])

  return (
    <>
      {weatherGroup && (
        <FormDialog<WeatherGroup>
          open={isEditing}
          entity={weatherGroup}
          entityLabel="Weather Group"
          onClose={() => setIsEditing(false)}
        >
          <WeatherGroupForm weatherGroup={weatherGroup} onEdit={handleEdit} onCancel={() => setIsEditing(false)} />
        </FormDialog>
      )}
      <Dialog
        fullWidth
        maxWidth="xs"
        open={isDeleteConfirmationOpen}
        onClose={() => setIsDeleteConfirmationOpen(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent dividers>Are you sure you want to delete this weather group?</DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setIsDeleteConfirmationOpen(false)}>
            Cancel
          </Button>
          <Button onClick={handleDelete}>Confirm</Button>
        </DialogActions>
      </Dialog>
      <Paper sx={{ p: 2 }}>
        {isLoading && !loadingError && <Loading sx={{ py: 20 }} />}
        {loadingError && (
          <Stack direction="row" justifyContent="center" alignItems="center" sx={{ py: 20 }}>
            <Typography variant="error">{loadingError}</Typography>
          </Stack>
        )}
        {!isLoading && !loadingError && weatherGroup && (
          <>
            {isSaving && <Loading sx={{ py: 20 }} />}
            {!isSaving && (
              <>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{ mb: 2 }}>
                  <Breadcrumbs
                    items={[{ label: 'Weather Groups', href: '/weather-groups' }, { label: weatherGroup.name }]}
                  />
                  {hasWritePermissions && (
                    <Stack direction="row" justifyContent="end" alignItems="center" spacing={2}>
                      <Button variant="text" onClick={handleDeleteClick}>
                        Delete
                      </Button>
                      <Button variant="contained" onClick={() => setIsEditing(true)}>
                        Edit
                      </Button>
                    </Stack>
                  )}
                </Stack>
                <Box>
                  <Box>
                    <Typography variant="h5" component="h2">
                      {weatherGroup.name}
                    </Typography>
                    <Typography variant="subtitle1" color={(theme) => theme.palette.grey[500]}>
                      ID: {weatherGroup.id}
                    </Typography>
                  </Box>
                  <Divider sx={{ my: 2 }} />
                  <Box>
                    <Typography variant="h6" component="h3">
                      Source
                    </Typography>
                    <Typography variant="body1">{weatherGroup.weatherSource}</Typography>
                    <Typography variant="body1">{weatherGroup.weatherSourceId}</Typography>
                  </Box>
                  <Divider sx={{ my: 2 }} />
                  <Box>
                    <Typography variant="body1">Region: {weatherGroup.region}</Typography>
                    <Typography variant="body1">Type: {weatherGroup.type}</Typography>
                  </Box>
                  <Divider sx={{ my: 2 }} />
                  <Box>
                    <Typography variant="body1">Updated At: {formatDate(weatherGroup.updatedAt)}</Typography>
                    <Typography variant="body1">Created At: {formatDate(weatherGroup.createdAt)}</Typography>
                  </Box>
                </Box>
                {tabs.length > 0 && (
                  <Box sx={{ mt: 4 }}>
                    <Tabs tabs={tabs} />
                  </Box>
                )}
              </>
            )}
          </>
        )}
      </Paper>
    </>
  )
}
export default WeatherGroupDetail

import { useEffect, useMemo, useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { SnackbarContext } from '../../../providers/SnackbarContext'
import { IntRange } from '../../../utils/typing'

import { ManualTrailStatus } from '../../../models/ManualTrailStatus.model'
import { Trail } from '../../../models/Trail.model'
import { ManualTrailStatusUpdateEvent } from '../../../events/ManualTrailStatusUpdateEvent'
import { TrailSystem } from '../../../models/TrailSystem.model'

import { TrailSystemService } from '../../../services/TrailSystemService/TrailSystemService'
import { TrailService } from '../../../services/TrailService/TrailService'
import { ManualTrailStatusService } from '../../../services/ManualTrailStatusService/ManualTrailStatusService'

import { Stack, FormControl, InputLabel, Button, Select, MenuItem, Divider } from '@mui/material'
import Loading from '../../../components/Loading/Loading.component'
import DetailInput from '../../../components/DetailInput/DetailInput.component'
import { TrailRelations } from '../../../models/TrailRelations.model'
import DateTimePicker from '../../../components/DateTimePicker/DateTimePicker.component'
import TrailPicker from '../../../components/TrailPicker/TrailPicker.component'
import { AppStateContext } from '../../../providers/AppStateContext'

export type ManualTrailStatusFormProps = {
  manualTrailStatus?: ManualTrailStatus
  trail?: Trail
  trailSystem?: TrailSystem
  onAdd?: () => void
  onEdit?: (manualTrailStatus: ManualTrailStatus) => void
  onCancel: () => void
  // used to remotely trigger the save action
  saveTrigger?: number
}
const ManualTrailStatusForm = (props: ManualTrailStatusFormProps) => {
  const { manualTrailStatus, trail, trailSystem, onCancel, onAdd, onEdit, saveTrigger } = props

  const [isLoading, setIsLoading] = useState(true)

  const [tempManualTrailStatus, setTempManualTrailStatus] = useState<
    Partial<ManualTrailStatus> & {
      trailId?: number
      systemId?: number
    }
  >(
    {
      ...manualTrailStatus,
      trailId: manualTrailStatus?.trail?.id,
      systemId: manualTrailStatus?.system?.id,
    } || {},
  )

  const isAdding = typeof onAdd === 'function'

  const [isSubmitting, setIsSubmitting] = useState(false)

  // used to support doing multiple manual trail statuses at once when adding
  const [tempTrails, setTempTrails] = useState<Trail[]>(trail ? [trail] : [])
  const [tempTrailSystems, setTempTrailSystems] = useState<TrailSystem[]>([])

  // used for tracking the assignment changes when editing a single manual trail status
  const [tempTrailSystem, setTempTrailSystem] = useState<TrailSystem | undefined>(
    trailSystem || manualTrailStatus?.system, // if a system was provided, use it to initialize the form
  )
  const [tempTrail, setTempTrail] = useState<Trail | undefined>(trail || manualTrailStatus?.trail)

  const queryClient = useQueryClient()

  const trailService = useMemo(() => new TrailService(), []) // memo to prevent re-creating service on every render

  const { isLoading: isLoadingManualTrailStatusTrailWithSystem, data: manualTrailStatusTrailWithSystem } = useQuery(
    [
      'TrailService.get',
      { id: trail?.id || manualTrailStatus?.trail?.id, relations: [TrailRelations.system] },
      AppStateContext.getRegion(),
    ],
    () =>
      trailService.get({
        id: trail?.id || manualTrailStatus?.trail?.id,
        relations: [TrailRelations.system],
      }),
    {
      // no system was provided so this is either assigned by trail or nothing at all (a new manual trail status)
      enabled: !trailSystem?.id && !manualTrailStatus?.system?.id && (trail || manualTrailStatus?.trail) ? true : false,
      onError: (err) => {
        SnackbarContext.show(`Failed to fetch trail for the form: ${typeof err === 'string' ? err : ''}`, 'error')
        console.error(err)
      },
    },
  )
  useEffect(() => {
    if (!manualTrailStatusTrailWithSystem) return
    setTempTrail(manualTrailStatusTrailWithSystem)
    setTempTrailSystem(manualTrailStatusTrailWithSystem.system)
  }, [manualTrailStatusTrailWithSystem])

  const { isLoading: isLoadingTrails, data: trailsResponse } = useQuery(
    [
      'TrailService.search',
      {
        systemId: tempTrailSystem?.id || manualTrailStatusTrailWithSystem?.system?.id,
        orderBy: 'name',
        orderDirection: 'ASC',
        relations: [],
        pageSize: 0,
      },
      AppStateContext.getRegion(),
    ],
    () =>
      trailService.search({
        systemId: tempTrailSystem?.id || manualTrailStatusTrailWithSystem?.system?.id,
        orderBy: 'name',
        orderDirection: 'ASC',
        relations: [],
        pageSize: 0,
      }),
    {
      enabled: tempTrailSystem?.id || manualTrailStatusTrailWithSystem?.system?.id ? true : false,
      onError: (err) => {
        SnackbarContext.show(`Failed to fetch trails for the form: ${typeof err === 'string' ? err : ''}`, 'error')
        console.error(err)
      },
    },
  )

  const trailSystemService = useMemo(() => new TrailSystemService(), []) // memo to prevent re-creating service on every render

  const { isLoading: isLoadingTrailSystems, data: trailSystemsResponse } = useQuery(
    [
      'TrailSystemService.search',
      { orderBy: 'name', orderDirection: 'ASC', relations: [], pageSize: 0 },
      AppStateContext.getRegion(),
    ],
    () => trailSystemService.search({ orderBy: 'name', orderDirection: 'ASC', relations: [], pageSize: 0 }),
    {
      enabled: !isAdding, // no need to fetch anything if we're adding a new manual trail status since we use an abstracted component that does that internally
      onError: (err) => {
        SnackbarContext.show(
          `Failed to fetch trail systems for the form: ${typeof err === 'string' ? err : ''}`,
          'error',
        )
        console.error(err)
      },
    },
  )

  // consolidate the various loading states into one
  useEffect(() => {
    const shouldBeLoadingManualTrailStatusTrailWithSystem =
      !trailSystem?.id && !manualTrailStatus?.system?.id && (trail || manualTrailStatus?.trail) ? true : false
    const shouldBeLoadingTrails = tempTrailSystem?.id || manualTrailStatusTrailWithSystem?.system?.id ? true : false
    setIsLoading(true)
    if (
      ((shouldBeLoadingManualTrailStatusTrailWithSystem && !isLoadingManualTrailStatusTrailWithSystem) ||
        !shouldBeLoadingManualTrailStatusTrailWithSystem) &&
      ((shouldBeLoadingTrails && !isLoadingTrails) || !shouldBeLoadingTrails) &&
      ((!isAdding && !isLoadingTrailSystems) || isAdding)
    ) {
      setIsLoading(false)
    }
  }, [
    trailSystem,
    manualTrailStatus,
    trail,
    tempTrailSystem,
    manualTrailStatusTrailWithSystem,
    isLoadingManualTrailStatusTrailWithSystem,
    isLoadingTrails,
    isLoadingTrailSystems,
  ])

  const manualTrailStatusService = useMemo(() => new ManualTrailStatusService(), []) // memo to prevent re-creating service on every render

  const addManualTrailStatus = useMutation(
    (mutationParams: {
      manualTrailStatuses: (Partial<ManualTrailStatus> & {
        trailId?: number
        systemId?: number
      })[]
    }) => {
      setIsSubmitting(true)
      return manualTrailStatusService.import(mutationParams.manualTrailStatuses)
    },
    {
      onSuccess: (_result, variables) => {
        SnackbarContext.show(`Manual Status${variables.manualTrailStatuses.length > 1 ? 'es' : ''} added successfully!`)

        // invalidate all other queries to ensure any queries that are using the modified record are updated
        queryClient.invalidateQueries()
        setIsSubmitting(false)

        if (typeof onAdd === 'function') {
          onAdd()
        }
      },
      onError: (err) => {
        SnackbarContext.show(`Manual Status failed to add: ${err}`, 'error')
        console.error(err)
        setIsSubmitting(false)
      },
    },
  )

  const updateManualTrailStatus = useMutation(
    (mutationParams: { id: number; changedProperties: ManualTrailStatusUpdateEvent }) => {
      setIsSubmitting(true)
      return manualTrailStatusService.update(mutationParams.id, mutationParams.changedProperties)
    },
    {
      onSuccess: (result) => {
        SnackbarContext.show('Manual Status updated successfully!')

        // invalidate all queries to ensure any queries that are using the modified record are updated
        queryClient.invalidateQueries()
        setIsSubmitting(false)

        if (typeof onEdit === 'function') {
          onEdit(result)
        }
      },
      onError: (err) => {
        SnackbarContext.show(`Manual Status failed to update: ${err}`, 'error')
        console.error(err)
        setIsSubmitting(false)
      },
    },
  )

  const handleSubmit = () => {
    if (!manualTrailStatus) {
      if (!tempTrails?.length) {
        SnackbarContext.show(`Manual Status's trail assignment or system assignment required`, 'error')
        return
      }

      // loop over the tempTrails and tempTrailSystems and create an array of manual trail statuses to send to the import method for mass creation
      const formattedManualTrailStatuses: (Partial<ManualTrailStatus> & {
        trailId?: number
        systemId?: number
      })[] = []
      tempTrails
        .filter((eachTrail) => {
          // ensure the trail is not already assigned in the tempTrailSystems which we have to look in the trail system's trails prop to find
          return !tempTrailSystems.find((eachTrailSystem) => {
            return eachTrailSystem.trails?.find((eachTrailSystemTrail) => eachTrailSystemTrail.id === eachTrail.id)
          })
        })
        .forEach((eachTrail) => {
          formattedManualTrailStatuses.push({
            reason: tempManualTrailStatus.reason || '',
            trailId: eachTrail.id,
            status: tempManualTrailStatus.status || 0, // default to closed
            start: dayjs.isDayjs(tempManualTrailStatus.start)
              ? tempManualTrailStatus.start.toDate()
              : tempManualTrailStatus.start || null,
            end: dayjs.isDayjs(tempManualTrailStatus.end)
              ? tempManualTrailStatus.end.toDate()
              : tempManualTrailStatus.end || null,
          })
        })

      tempTrailSystems.forEach((eachTrailSystem) => {
        formattedManualTrailStatuses.push({
          reason: tempManualTrailStatus.reason || '',
          systemId: eachTrailSystem.id,
          status: tempManualTrailStatus.status || 0, // default to closed
          start: dayjs.isDayjs(tempManualTrailStatus.start)
            ? tempManualTrailStatus.start.toDate()
            : tempManualTrailStatus.start || null,
          end: dayjs.isDayjs(tempManualTrailStatus.end)
            ? tempManualTrailStatus.end.toDate()
            : tempManualTrailStatus.end || null,
        })
      })

      // adding new records
      addManualTrailStatus.mutate({ manualTrailStatuses: formattedManualTrailStatuses })
    } else {
      if (!tempTrailSystem?.id) {
        SnackbarContext.show(`Manual Status's trail assignment or system assignment required`, 'error')
        return
      }

      if (tempManualTrailStatus.status === undefined) {
        SnackbarContext.show(`Manual Status's desired status is required`, 'error')
        return
      }

      // identify the properties that have changed
      const didChange = (field: keyof ManualTrailStatus) => {
        return tempManualTrailStatus[field] !== undefined && tempManualTrailStatus[field] !== manualTrailStatus[field]
      }

      const changedProperties: ManualTrailStatusUpdateEvent = {
        id: manualTrailStatus.id,
        reason: didChange('reason') ? tempManualTrailStatus.reason : undefined,
        systemId:
          tempTrailSystem?.id && !tempTrail?.id && tempTrailSystem.id !== manualTrailStatus.system?.id
            ? tempTrailSystem.id
            : undefined,
        trailId: tempTrail?.id && tempTrail.id !== manualTrailStatus.trail?.id ? tempTrail.id : undefined,
        status: didChange('status') ? tempManualTrailStatus.status : undefined,
        start: didChange('start')
          ? dayjs.isDayjs(tempManualTrailStatus.start) || tempManualTrailStatus.start instanceof Date
            ? tempManualTrailStatus.start.toISOString()
            : tempManualTrailStatus.start || null
          : undefined,
        end: didChange('end')
          ? dayjs.isDayjs(tempManualTrailStatus.end) || tempManualTrailStatus.end instanceof Date
            ? tempManualTrailStatus.end.toISOString()
            : tempManualTrailStatus.end || null
          : undefined,
      }

      // unset the trail or system to ensure we don't pass both
      if (manualTrailStatus.system?.id && tempTrail?.id) {
        changedProperties.systemId = null
      }
      if (manualTrailStatus.trail?.id && !tempTrail?.id) {
        changedProperties.trailId = null
      }

      // edit an existing record
      if (!Object.keys(changedProperties).filter((key) => key !== 'id').length) {
        // nothing changed so just close the dialog
        onCancel()
      } else {
        updateManualTrailStatus.mutate({ id: manualTrailStatus.id, changedProperties })
      }
    }
  }

  useEffect(() => {
    if (saveTrigger) {
      // if outside consumer triggered save, then submit
      handleSubmit()
    }
  }, [saveTrigger])

  const handleCancel = () => {
    if (typeof onCancel === 'function') {
      onCancel()
    }
  }

  const keyDownSubmit = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSubmit()
    }
  }

  const setDateValue = (propName: keyof typeof tempManualTrailStatus, newValue: string | Date | Dayjs | null) => {
    setTempManualTrailStatus({
      ...tempManualTrailStatus,
      [propName]: dayjs.isDayjs(newValue) ? newValue : typeof newValue === 'string' ? dayjs(newValue) : null,
    })
  }

  return (
    <>
      {(isSubmitting || isLoading) && <Loading sx={{ py: 20 }} />}
      {!isSubmitting && !isLoading && (
        <>
          <Stack spacing={2} sx={{ my: 2 }}>
            {isAdding ? (
              // adding so show them a UI for picking multiple trails or systems which will generate multiple manual trail statuses at once
              <FormControl variant="outlined" fullWidth>
                <InputLabel shrink>Affected Trails</InputLabel>
                <TrailPicker
                  trailSystem={tempTrailSystem || trailSystem} // normally tempTrailSystem is undefined but if we are adding an mts when passed a trail, it would be populated and we want to restrict the picker to that trail's system
                  selectedTrails={tempTrails}
                  setSelectedTrails={setTempTrails}
                  setSelectedTrailSystems={setTempTrailSystems}
                />
              </FormControl>
            ) : (
              // editing so show them a selector for picking one system or one trail for this one manual trail status
              <>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="trail-system-select-label">Trail System</InputLabel>
                  <Select
                    labelId="trail-system-select-label"
                    id="trail-system-select"
                    value={tempTrailSystem ? tempTrailSystem.id : ''}
                    label="Trail System"
                    onChange={(e) => {
                      setTempTrailSystem(
                        trailSystemsResponse?.data.find((eachTrailSystem) => eachTrailSystem.id === e.target.value),
                      )
                      // clear temp trail since we are either initializing or changing the system
                      setTempTrail(undefined)
                    }}
                  >
                    <MenuItem value="">-Select-</MenuItem>
                    {trailSystemsResponse?.data.map((eachTrailSystem) => {
                      return (
                        <MenuItem key={eachTrailSystem.id} value={eachTrailSystem.id}>
                          {eachTrailSystem.name}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
                {tempTrailSystem && trailsResponse?.data && trailsResponse.data.length && (
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="trail-select-label" shrink>
                      Trail
                    </InputLabel>
                    <Select
                      labelId="trail-select-label"
                      id="trail-select"
                      displayEmpty
                      value={tempTrail ? tempTrail.id : ''}
                      label="Trail"
                      onChange={(e) => {
                        setTempTrail(trailsResponse.data.find((eachTrail) => eachTrail.id === e.target.value))
                      }}
                    >
                      <MenuItem value="">Apply To All Trails</MenuItem>
                      {trailsResponse.data.map((eachTrail) => {
                        return (
                          <MenuItem key={eachTrail.id} value={eachTrail.id}>
                            {eachTrail.name}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                )}
              </>
            )}
            <Divider />
            <DetailInput<ManualTrailStatus>
              label="Reason"
              field="reason"
              tempDetail={tempManualTrailStatus}
              setTempDetail={setTempManualTrailStatus}
              keyDownSubmit={keyDownSubmit}
            />
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="manual-trail-status-status-select-label">Status</InputLabel>
              <Select
                labelId="manual-trail-status-status-select-label"
                id="manual-trail-status-status-select"
                value={tempManualTrailStatus.status || '0'}
                label="Status"
                onChange={(e) => {
                  const value = Number(e.target.value)
                  setTempManualTrailStatus((prev) => ({
                    ...prev,
                    status: value >= 0 && value <= 3 ? (value as IntRange<0, 4>) : undefined,
                  }))
                }}
              >
                {/* Have to manually output these since the enum has numeric values which causes the js output to act funky */}
                <MenuItem value="0">Closed</MenuItem>
                <MenuItem value="1">Rutty</MenuItem>
                <MenuItem value="2">Fair</MenuItem>
                <MenuItem value="3">Firm</MenuItem>
              </Select>
            </FormControl>

            <FormControl variant="outlined" fullWidth>
              <DateTimePicker
                label="Start"
                value={tempManualTrailStatus.start}
                onChange={(newValue) => {
                  setDateValue('start', newValue)
                }}
              />
            </FormControl>

            <FormControl variant="outlined" fullWidth>
              <DateTimePicker
                label="End"
                value={tempManualTrailStatus.end}
                onChange={(newValue) => {
                  setDateValue('end', newValue)
                }}
              />
            </FormControl>
          </Stack>
          <Stack direction="row" justifyContent="end" alignItems="center" spacing={2}>
            <Button variant="outlined" onClick={handleCancel}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSubmit}>
              Save
            </Button>
          </Stack>
        </>
      )}
    </>
  )
}
export default ManualTrailStatusForm

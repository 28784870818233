export function removeTrailingSlash(string: string) {
  return string.replace(/\/$/, '')
}

export const getObjectKeyFromValue = (object: Record<string, unknown>, value: unknown): string | undefined => {
  return Object.keys(object)[Object.values(object).indexOf(value)]
}

export const generatePermalink = (name: string): string => {
  const lowercaseName = (name || '').toLowerCase()
  // take the name and remove all non-alphanumeric characters
  const nameWithoutNonAlphaNumeric = lowercaseName.replace(/[^a-z0-9-\s]/gi, '')
  // replace all spaces with dashes
  const nameWithDashes = nameWithoutNonAlphaNumeric.replace(/ /g, '-')
  // remove any consecutive dashes eg. --- => -
  const nameWithDashesAndNoConsecutiveDashes = nameWithDashes.replace(/-+/g, '-')

  return nameWithDashesAndNoConsecutiveDashes
}

// format a date or ISO datetime string to human readable datetime string in February 1, 2021 with 12 hour time format
export const formatDate = (date: string | Date): string => {
  if (!date) return ''

  const dateObj = date instanceof Date ? date : new Date(date)
  return dateObj.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  })
}

// Checks if each property of number type is not undefined or null and then converts it to a number
export const formatToNumberTypeProps = function <T = Record<string, unknown>>(obj: T, numberTypeProps: string[]): T {
  // if somehow bad data is passed in, just return it
  if (typeof obj !== 'object' || obj === null) return obj

  const newObj: T & object = { ...obj }
  const numberPropValues = numberTypeProps.reduce((acc, prop) => {
    const typedProp = prop as keyof T
    if (newObj[typedProp] !== undefined && newObj[typedProp] !== null && newObj[typedProp] !== '') {
      acc[typedProp] = Number(newObj[typedProp])
    } else if (newObj[typedProp] === '') {
      // treat empty string as if they never passed the prop
      delete newObj[typedProp]
    }
    return acc
  }, {} as Record<keyof T, number>)

  Object.assign(newObj, numberPropValues)

  return newObj
}

// Checks if each property of date type is not undefined or null and then converts it to a date
export const formatToDateTypeProps = function <T = Record<string, unknown>>(obj: T, dateTypeProps: string[]): T {
  // if somehow bad data is passed in, just return it
  if (typeof obj !== 'object' || obj === null) return obj

  const newObj: T & object = { ...obj }
  const datePropValues = dateTypeProps.reduce((acc, prop) => {
    const typedProp = prop as keyof T
    if (newObj[typedProp] !== undefined && newObj[typedProp] !== null && newObj[typedProp] !== '') {
      acc[typedProp] = new Date(String(newObj[typedProp]))
    } else if (newObj[typedProp] === '') {
      // treat empty string as if they never passed the prop
      delete newObj[typedProp]
    }
    return acc
  }, {} as Record<keyof T, Date>)

  Object.assign(newObj, datePropValues)

  return newObj
}

import { AppRegions } from './AppRegions.model'
import { Trail } from './Trail.model'

export type TrailFeature = {
  id: number
  region: AppRegions
  name: string
  trails?: Trail[]
  active: boolean
  updatedAt: Date
  createdAt: Date
}

// we offer this to enable the enumerate the TrailFeature type's fields in js/ts files
export const trailFeatureFields: (keyof TrailFeature)[] = [
  'id',
  'region',
  'name',
  'trails',
  'active',
  'updatedAt',
  'createdAt',
]

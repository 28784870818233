import React, { useState } from 'react'

import { Dialog, DialogContent, AppBar, Toolbar, IconButton, Button, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

type EntityWithId = {
  id: number
}

type FormDialogProps<T> = {
  open: boolean
  entity?: T
  entityLabel: string
  onClose: () => void
  children: React.ReactElement<{ saveTrigger?: number }>
}
function FormDialog<T extends EntityWithId>(props: FormDialogProps<T>) {
  const { children, entity, entityLabel, onClose, open } = props

  // used to remotely trigger the save of the child form
  const [saveTrigger, setSaveTrigger] = useState(0)

  const handleSave = () => {
    setSaveTrigger((prev) => prev + 1)
    // wait a second and reinitialize the save trigger
    setTimeout(() => {
      setSaveTrigger(0)
    }, 1000)
  }

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <Typography sx={{ mr: 2, flex: 1 }} variant="h6" component="div">
            {entity ? 'Edit' : 'Add'} {entityLabel}
            {entity ? ` (ID: ${entity.id})` : ''}
          </Typography>
          <Button autoFocus color="inherit" onClick={handleSave}>
            Save
          </Button>
          <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>{React.isValidElement(children) && React.cloneElement(children, { saveTrigger })}</DialogContent>
    </Dialog>
  )
}
export default FormDialog

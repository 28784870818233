import { useEffect, useMemo, useState } from 'react'
import lodash from 'lodash'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { SnackbarContext } from '../../../providers/SnackbarContext'

import { Trail } from '../../../models/Trail.model'
import { TrailUpdateEvent } from '../../../events/TrailUpdateEvent'
import { TrailFeature } from '../../../models/TrailFeature.model'
import { TrailRelations } from '../../../models/TrailRelations.model'

import { TrailFeatureService } from '../../../services/TrailFeatureService/TrailFeatureService'
import { TrailService } from '../../../services/TrailService/TrailService'

import { Stack, Button, List, ListItem, ListItemButton, ListItemIcon, Checkbox, ListItemText } from '@mui/material'
import Loading from '../../../components/Loading/Loading.component'
import FormDialog from '../../../components/FormDialog/FormDialog.component'
import TrailFeatureForm from '../../TrailFeatures/shared/TrailFeatureForm.component'
import { AppStateContext } from '../../../providers/AppStateContext'

export type FeaturesOnTrailFormProps = {
  trail: Trail
  onEdit?: (trail: Trail) => void
  onCancel: () => void
  // used to remotely trigger the save action
  saveTrigger?: number
}
const FeaturesOnTrailForm = (props: FeaturesOnTrailFormProps) => {
  const { trail, onCancel, onEdit, saveTrigger } = props

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isAddingFeature, setIsAddingFeature] = useState(false)

  const trailFeatureService = useMemo(() => new TrailFeatureService(), []) // memo to prevent re-creating service on every render
  const trailService = useMemo(() => new TrailService(), []) // memo to prevent re-creating service on every render

  const queryClient = useQueryClient()

  const [originalTrailFeatures, setOriginalTrailFeatures] = useState<TrailFeature[]>([])
  const [tempTrailFeatures, setTempTrailFeatures] = useState<TrailFeature[]>([])

  const { isLoading: isLoadingTrailWithFeatures, data: trailWithFeatures } = useQuery(
    ['TrailService.get', { id: trail.id, relations: [TrailRelations.trailFeatures] }, AppStateContext.getRegion()],
    () => trailService.get({ id: trail.id, relations: [TrailRelations.trailFeatures] }),
    {
      onError: (err) => {
        SnackbarContext.show(`Failed to fetch trail existing feature assignments for the form: ${err}`, 'error')
        console.error(err)
      },
      refetchOnMount: true, // force refetch when component mounts
    },
  )
  // useEffect usage instead of onSuccess or onSettled to ensure we always trigger even when the query is cached
  useEffect(() => {
    if (!trailWithFeatures) return
    setOriginalTrailFeatures(trailWithFeatures?.features ? [...trailWithFeatures.features] : [])
    setTempTrailFeatures(trailWithFeatures?.features ? [...trailWithFeatures.features] : [])
  }, [trailWithFeatures])

  const { isLoading: isLoadingTrailFeatures, data: trailFeaturesResponse } = useQuery(
    [
      'TrailFeatureService.search',
      { pageSize: 0, orderBy: 'name', orderDirection: 'ASC' },
      AppStateContext.getRegion(),
    ],
    () => trailFeatureService.search({ pageSize: 0, orderBy: 'name', orderDirection: 'ASC' }),
    {
      onError: (err) => {
        SnackbarContext.show(`Failed to fetch feature options for the form: ${err}`, 'error')
        console.error(err)
      },
    },
  )

  const updateTrail = useMutation(
    (mutationParams: { id: number; changedProperties: TrailUpdateEvent }) => {
      setIsSubmitting(true)
      return trailService.update(mutationParams.id, mutationParams.changedProperties)
    },
    {
      onSuccess: (result) => {
        SnackbarContext.show('Trail updated successfully!')

        // invalidate all queries to ensure any queries that are using the modified record are updated
        queryClient.invalidateQueries()
        setIsSubmitting(false)

        if (typeof onEdit === 'function') {
          onEdit(result)
        }
      },
      onError: (err) => {
        SnackbarContext.show(`Trail failed to update: ${err}`, 'error')
        console.error(err)
        setIsSubmitting(false)
      },
    },
  )

  const handleSubmit = () => {
    const changedProperties: TrailUpdateEvent = {
      id: trail.id,
    }
    if (
      !lodash.isEqual(
        tempTrailFeatures.map((each) => each.id),
        originalTrailFeatures.map((each) => each.id),
      )
    ) {
      changedProperties.featureIds = tempTrailFeatures.map((each) => each.id)
    }

    // edit an existing trail
    if (!Object.keys(changedProperties).filter((key) => key !== 'id').length) {
      // nothing changed so just close the dialog
      onCancel()
    } else {
      updateTrail.mutate({ id: trail.id, changedProperties })
    }
  }

  useEffect(() => {
    if (saveTrigger) {
      // if outside consumer triggered save, then submit
      handleSubmit()
    }
  }, [saveTrigger])

  const handleCancel = () => {
    if (typeof onCancel === 'function') {
      onCancel()
    }
  }

  const handleToggleFeature = (trailFeature: TrailFeature) => () => {
    const currentIndex = tempTrailFeatures.map((each) => each.id).indexOf(trailFeature.id)
    const newChecked = [...tempTrailFeatures]

    if (currentIndex === -1) {
      newChecked.push(trailFeature)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setTempTrailFeatures(newChecked)
  }

  const handleAddFeatureClick = () => {
    setIsAddingFeature(true)
  }

  const handleAddFeatureCancel = () => {
    setIsAddingFeature(false)
  }

  const handleAddFeatureSubmit = (newTrailFeature: TrailFeature) => {
    setIsAddingFeature(false)
    setTempTrailFeatures((prev) => {
      prev.push(newTrailFeature)
      return prev
    })
  }

  return (
    <>
      {(isLoadingTrailWithFeatures || isLoadingTrailFeatures || isSubmitting) && <Loading sx={{ py: 20 }} />}
      {!isLoadingTrailWithFeatures && !isLoadingTrailFeatures && !isSubmitting && (
        <>
          <FormDialog<TrailFeature> open={isAddingFeature} entityLabel="Trail Feature" onClose={handleAddFeatureCancel}>
            <TrailFeatureForm
              trail={trail}
              onAdd={handleAddFeatureSubmit}
              onCancel={handleAddFeatureCancel}
              disableRedirect
            />
          </FormDialog>
          <Stack spacing={2} sx={{ my: 2 }}>
            <List sx={{ width: '100%', maxHeight: 600, overflow: 'auto', bgcolor: 'background.paper' }}>
              {trailFeaturesResponse?.data?.map((trailFeature) => (
                <ListItem key={trailFeature.id} disablePadding>
                  <ListItemButton role={undefined} onClick={handleToggleFeature(trailFeature)} dense>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={tempTrailFeatures.map((each) => each.id).indexOf(trailFeature.id) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': `trail-feature-checkbox-list-label-${trailFeature.id}` }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      id={`trail-feature-checkbox-list-label-${trailFeature.id}`}
                      primary={trailFeature.name}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Stack>
          <Button variant="contained" onClick={handleAddFeatureClick}>
            Add New Feature
          </Button>
          <Stack direction="row" justifyContent="end" alignItems="center" spacing={2}>
            <Button variant="outlined" onClick={handleCancel}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSubmit}>
              Save
            </Button>
          </Stack>
        </>
      )}
    </>
  )
}
export default FeaturesOnTrailForm

import { Stack, CircularProgress, StackProps } from '@mui/material'

interface LoadingProps extends StackProps {
  size?: number
}
const Loading = (props: LoadingProps): JSX.Element => {
  const { size = 75, sx, ...rest } = props
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      sx={{ width: '100%', height: '100%', ...sx }}
      {...rest}
    >
      <CircularProgress size={size} />
    </Stack>
  )
}
export default Loading

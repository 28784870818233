import { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

import { AppLayoutContext } from '../../providers/AppLayout'

import { Paper, Box, Typography } from '@mui/material'

const Home = (): JSX.Element => {
  const { user, isAuthenticated } = useAuth0()

  useEffect(() => {
    AppLayoutContext.setPageName('Home')
  }, [])

  return (
    <Paper sx={{ p: 2 }}>
      {isAuthenticated && user && (
        <Box>
          <Typography variant="h5">Welcome {user.name}</Typography>
        </Box>
      )}
    </Paper>
  )
}
export default Home

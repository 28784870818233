import { useEffect, useMemo, useState } from 'react'
import * as d3 from 'd3'
import { useAuth0 } from '@auth0/auth0-react'
import { useSearchParams } from 'react-router-dom'
import pick from 'lodash/pick'
import dayjs from 'dayjs'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import { AppLayoutContext } from '../../../providers/AppLayout'
import { useUrlStateParams } from '../../../utils/url'
import { SnackbarContext } from '../../../providers/SnackbarContext'

import { AppPermissions } from '../../../models/AppPermissions.model'
import { WeatherRecord, weatherRecordFields } from '../../../models/WeatherRecord.model'

import {
  Box,
  MenuItem,
  Stack,
  Typography,
  Button,
  IconButton,
  Paper,
  Menu,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
} from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import AddIcon from '@mui/icons-material/Add'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import DataTable from '../../../components/DataTable/DataTable.component'
import Loading from '../../../components/Loading/Loading.component'
import ImportExportDialog from '../../../components/ImportExportDialog/ImportExportDialog.component'
import Link from '@mui/material/Link'
import UserService from '../../../services/UserService/UserService'
import { WeatherRecordService } from '../../../services/WeatherRecordService/WeatherRecordService'
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs.component'
import { WeatherGroup } from '../../../models/WeatherGroup.model'
import { WeatherGroupService } from '../../../services/WeatherGroupService/WeatherGroupService'
import { formatDate } from '../../../utils/formatting'
import FormDialog from '../../../components/FormDialog/FormDialog.component'
import WeatherRecordForm from '../shared/WeatherRecordForm.component'
import DateTimePicker from '../../../components/DateTimePicker/DateTimePicker.component'
import { AppStateContext } from '../../../providers/AppStateContext'

type WeatherRecordSearchProps = {
  nested?: boolean
  weatherGroupId?: number
  hideHeader?: boolean
  hideBreadcrumbs?: boolean
  excludeColumns?: string[]
}

const WeatherRecordSearch = (props: WeatherRecordSearchProps): JSX.Element => {
  const [isSaving, setIsSaving] = useState(false)

  const [currentSearchParameters] = useSearchParams()
  const {
    nested,
    weatherGroupId = Number(currentSearchParameters.get('weather-group')) || undefined,
    hideHeader = false,
    hideBreadcrumbs = false,
  } = props

  const { user } = useAuth0()
  const hasWritePermissions = UserService.hasPermissions(user, [AppPermissions.editWeatherRecord])

  const [searchError, setSearchError] = useState('')
  const [rowActionsAnchorEl, setRowActionsAnchorEl] = useState<null | HTMLElement>(null)
  const isActionsMenuOpen = Boolean(rowActionsAnchorEl)
  const [selectedRow, setSelectedRow] = useState<WeatherRecord | undefined>()
  const [isAdding, setIsAdding] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false)

  const [start, setStart] = useUrlStateParams<Date | undefined>(
    undefined,
    'start',
    (value) => encodeURIComponent(value instanceof Date ? value?.toISOString() : ''),
    (value) => (value ? dayjs(decodeURIComponent(value)).toDate() : undefined),
  )
  const [tempStart, setTempStart] = useState<Date | undefined>(start)

  const [end, setEnd] = useUrlStateParams<Date | undefined>(
    undefined,
    'end',
    (value) => encodeURIComponent(value instanceof Date ? value?.toISOString() : ''),
    (value) => (value ? dayjs(decodeURIComponent(value)).toDate() : undefined),
  )
  const [tempEnd, setTempEnd] = useState<Date | undefined>(end)

  const [orderBy, setOrderBy] = useState<keyof WeatherRecord | undefined>('timestamp')
  const [orderDirection, setOrderDirection] = useState<'asc' | 'desc' | undefined>('desc')
  const [page, setPage] = useUrlStateParams(
    1,
    'page',
    (value) => value.toString(),
    (value) => (!isNaN(Number(value)) ? Number(value) : 1),
  )
  const [pageSize, setPageSize] = useUrlStateParams(
    12,
    'page-size',
    (value) => value.toString(),
    (value) => (!isNaN(Number(value)) ? Number(value) : 12),
  )

  const [weatherGroup, setWeatherGroup] = useState<WeatherGroup>()
  const [isImportExportOpen, setIsImportExportOpen] = useState(false)

  const weatherRecordService = useMemo(() => new WeatherRecordService(), []) // memo to prevent re-creating service on every render

  const weatherGroupService = useMemo(() => new WeatherGroupService(), []) // memo to prevent re-creating service on every render

  useEffect(() => {
    if (!nested) {
      AppLayoutContext.setPageName('Weather Records')
    }
    if (weatherGroupId) {
      // fetch the weather group for use in the add dialog
      weatherGroupService
        .get(weatherGroupId)
        .then((resultWeatherGroup) => {
          setWeatherGroup(resultWeatherGroup)
        })
        .catch((err) => {
          console.error(err)
        })
    }
  }, [])

  const queryClient = useQueryClient()
  const { isLoading, data: weatherRecordsResponse } = useQuery(
    [
      'WeatherGroupService.search',
      { weatherGroupId, start, end, page, pageSize, orderBy, orderDirection },
      AppStateContext.getRegion(),
    ],
    () => weatherRecordService.search({ weatherGroupId, start, end, page, pageSize, orderBy, orderDirection }),
    {
      onError: (err) => {
        setSearchError(typeof err === 'string' ? err : 'An error occurred while searching for weather records.')
        console.error(err)
      },
    },
  )

  const handleRowActionsClick = (event: React.MouseEvent<HTMLButtonElement>, weatherRecord: WeatherRecord) => {
    setRowActionsAnchorEl(event.currentTarget)
    setSelectedRow(weatherRecord)
  }
  const handleRowActionsClose = () => {
    setRowActionsAnchorEl(null)
    setSelectedRow(undefined)
  }

  const columns = useMemo(() => {
    const columns: GridColDef<WeatherRecord>[] = [
      {
        field: 'actions',
        headerName: '',
        width: 60,
        sortable: false,
        renderCell: (params) => (
          <>
            <IconButton
              id={`row-actions-button-${params.row.id}`}
              color="primary"
              aria-label="row actions"
              aria-controls={selectedRow?.id === params.row.id ? 'row-actions-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={selectedRow?.id === params.row.id ? 'true' : undefined}
              onClick={(event) => handleRowActionsClick(event, params.row)}
            >
              <MoreVertIcon />
            </IconButton>
          </>
        ),
      },
      {
        field: 'id',
        headerName: 'ID',
        width: 200,
        renderCell: (params) => (
          <Link color="primary" aria-label="weather record detail" href={`/weather-records/${params.row.id}`}>
            {params.row.id}
          </Link>
        ),
      },
      {
        field: 'timestamp',
        headerName: 'Date',
        renderCell: (params) => (params.row.timestamp ? formatDate(params.row.timestamp) : '-'),
        width: 300,
      },
      {
        field: 'precipLast1hr',
        headerName: 'Precip (last 1hr)',
        width: 200,
        renderCell: (params) => `${params.row.precipLast1hr} mm`,
      },
      {
        field: 'temperature',
        headerName: 'Temperature',
        width: 200,
        renderCell: (params) => `${params.row.temperature} °C`,
      },
    ]
    return columns
  }, [])

  const handleSubmit = () => {
    setPage(1)
    setStart(tempStart || undefined)
    setEnd(tempEnd || undefined)
  }

  const handleAddClick = () => {
    setIsAdding(true)
  }

  const handleAddCancel = () => {
    setIsAdding(false)
  }

  const handleAddSubmit = () => {
    setIsAdding(false)
  }

  const handleEditClick = () => {
    setIsEditing(true)
  }

  const handleEditCancel = () => {
    setRowActionsAnchorEl(null)
    setSelectedRow(undefined)
    setIsEditing(false)
  }

  const handleEditSubmit = () => {
    setRowActionsAnchorEl(null)
    setSelectedRow(undefined)
    setIsEditing(false)
  }

  const handleDeleteClick = () => {
    // show a confirmation dialog before deleting
    setIsDeleteConfirmationOpen(true)
  }

  const handleDeleteConfirmationClose = () => {
    setIsDeleteConfirmationOpen(false)
    handleRowActionsClose()
  }

  const deleteWeatherRecord = useMutation(
    (mutationParams: { id: number }) => {
      setIsSaving(true)
      return weatherRecordService.delete(mutationParams.id)
    },
    {
      onSuccess: () => {
        SnackbarContext.show('Weather Record deleted successfully!')
        setRowActionsAnchorEl(null)
        setSelectedRow(undefined)
        // invalidate all queries to ensure any queries that are using the modified record are updated
        queryClient.invalidateQueries()
        setIsSaving(false)
      },
      onError: (err) => {
        SnackbarContext.show(`Weather Record failed to delete: ${err}`, 'error')
        console.error(err)
        setIsSaving(false)
      },
    },
  )

  const handleDelete = () => {
    if (selectedRow?.id) {
      setIsDeleteConfirmationOpen(false)
      deleteWeatherRecord.mutate({ id: selectedRow.id })
    }
  }

  const openImportExport = () => {
    setIsImportExportOpen(true)
  }

  const handleImportExportClose = () => {
    setIsImportExportOpen(false)
  }

  const handleExport = () => {
    // do a request to the API to get the data to export with no pagination
    setIsSaving(true)
    setIsImportExportOpen(false)
    weatherRecordService
      .search({ weatherGroupId, start, end, pageSize: 8760, orderBy, orderDirection })
      .then((results) => {
        const exportData = results.data.map((weatherRecord) => {
          const weatherRecordToExport: Record<string, unknown> = { ...weatherRecord }
          // set a prop for weatherGroup's id and name and remove the weatherGroup object prop
          weatherRecordToExport.weatherGroupId = weatherRecord.weatherGroup?.id
          weatherRecordToExport.weatherGroupName = weatherRecord.weatherGroup?.name
          delete weatherRecordToExport.weatherGroup

          weatherRecordToExport.timestamp =
            weatherRecordToExport.timestamp instanceof Date ? weatherRecordToExport.timestamp.toISOString() : ''
          weatherRecordToExport.sunrise =
            weatherRecordToExport.sunrise instanceof Date ? weatherRecordToExport.sunrise.toISOString() : ''
          weatherRecordToExport.sunset =
            weatherRecordToExport.sunset instanceof Date ? weatherRecordToExport.sunset.toISOString() : ''
          weatherRecordToExport.updatedAt =
            weatherRecordToExport.updatedAt instanceof Date ? weatherRecordToExport.updatedAt.toISOString() : ''
          weatherRecordToExport.createdAt =
            weatherRecordToExport.createdAt instanceof Date ? weatherRecordToExport.createdAt.toISOString() : ''

          // return the object with the props in the order we want them to appear in the csv
          const propsToExport: string[] = [...weatherRecordFields]
          // add system and trail props after id
          propsToExport.splice(propsToExport.indexOf('region') + 1, 0, 'weatherGroupId', 'weatherGroupName')
          return pick(weatherRecordToExport, propsToExport)
        })
        const csvData = d3.csvFormat(exportData)
        // initiate a download of the csv file
        const blob = new Blob([csvData], { type: 'text/csv' })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')

        link.setAttribute('href', url)
        link.setAttribute('download', 'weather-records.csv')
        link.click()
        setIsSaving(false)
      })
      .catch((err) => {
        SnackbarContext.show(`Failed to fetch weather records for export: ${err}`, 'error')
        console.error(err)
      })
  }

  return (
    <>
      {selectedRow && (
        <FormDialog<WeatherRecord>
          open={isEditing}
          entity={selectedRow}
          entityLabel="Weather Record"
          onClose={handleEditCancel}
        >
          <WeatherRecordForm weatherRecord={selectedRow} onEdit={handleEditSubmit} onCancel={handleEditCancel} />
        </FormDialog>
      )}
      <FormDialog<WeatherRecord> open={isAdding} entityLabel="Weather Record" onClose={handleAddCancel}>
        <WeatherRecordForm weatherGroup={weatherGroup} onAdd={handleAddSubmit} onCancel={handleAddCancel} />
      </FormDialog>
      <Dialog fullWidth maxWidth="xs" open={isDeleteConfirmationOpen} onClose={handleDeleteConfirmationClose}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent dividers>Are you sure you want to delete weather record id {selectedRow?.id}?</DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleDeleteConfirmationClose}>
            Cancel
          </Button>
          <Button onClick={handleDelete}>Confirm</Button>
        </DialogActions>
      </Dialog>
      <ImportExportDialog
        open={isImportExportOpen}
        disableImport
        onExport={handleExport}
        onClose={handleImportExportClose}
      />
      <Paper sx={{ p: 2 }}>
        {!hideBreadcrumbs && (
          <Box sx={{ mb: 2 }}>
            {' '}
            <Breadcrumbs items={[{ label: 'Weather Groups', href: '/weather-groups' }]} />
          </Box>
        )}
        {!hideHeader && (
          <Typography variant="h5" component="h2" sx={{ mb: 2 }}>
            {weatherGroup?.name ? `${weatherGroup.name} ` : ''} Weather Records
          </Typography>
        )}
        {(isLoading || isSaving) && !searchError && <Loading sx={{ py: 20 }} />}
        {searchError && (
          <Stack direction="row" justifyContent="center" alignItems="center" sx={{ py: 20 }}>
            <Typography variant="error">{searchError}</Typography>
          </Stack>
        )}
        {!isLoading && !isSaving && !searchError && (
          <>
            <Box sx={{ pt: 1, mb: 1 }}>
              <Stack direction={{ zero: 'column', md: 'row' }} spacing={1}>
                <FormControl variant="outlined" fullWidth>
                  <DateTimePicker
                    label="Start"
                    value={tempStart}
                    onChange={(newValue) => {
                      setTempStart(
                        dayjs.isDayjs(newValue)
                          ? newValue.toDate()
                          : typeof newValue === 'string'
                          ? dayjs(newValue).toDate()
                          : undefined,
                      )
                    }}
                  />
                </FormControl>

                <FormControl variant="outlined" fullWidth>
                  <DateTimePicker
                    label="End"
                    value={tempEnd}
                    onChange={(newValue) => {
                      setTempEnd(
                        dayjs.isDayjs(newValue)
                          ? newValue.toDate()
                          : typeof newValue === 'string'
                          ? dayjs(newValue).toDate()
                          : undefined,
                      )
                    }}
                  />
                </FormControl>

                <Button
                  variant="outlined"
                  onClick={handleSubmit}
                  sx={(theme) => ({ [theme.breakpoints.up('md')]: { width: 150 }, maxWidth: '100%' })}
                >
                  Search
                </Button>
              </Stack>

              <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1} sx={{ mt: 1 }}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  {hasWritePermissions && (
                    <Button
                      variant="outlined"
                      startIcon={<AddIcon />}
                      color="primary"
                      aria-label="add new"
                      onClick={handleAddClick}
                    >
                      Add
                    </Button>
                  )}
                  <IconButton
                    color="primary"
                    aria-label="show export"
                    onClick={() => {
                      openImportExport()
                    }}
                  >
                    <ImportExportIcon />
                  </IconButton>
                </Stack>
                <Button
                  variant="text"
                  size="small"
                  onClick={() => {
                    setPage(1)
                    setTempStart(undefined)
                    setTempEnd(undefined)
                    setStart(undefined)
                    setEnd(undefined)
                  }}
                >
                  Clear
                </Button>
              </Stack>
            </Box>

            {weatherRecordsResponse?.data && weatherRecordsResponse.data.length > 0 ? (
              <>
                <Menu
                  id="row-actions-menu"
                  anchorEl={rowActionsAnchorEl}
                  open={isActionsMenuOpen}
                  onClose={handleRowActionsClose}
                  MenuListProps={{
                    'aria-labelledby': `row-actions-button${selectedRow?.id}`,
                  }}
                >
                  <MenuItem href={`/weather-records/${selectedRow?.id}`} component={Link}>
                    View
                  </MenuItem>
                  {hasWritePermissions && <MenuItem onClick={handleEditClick}>Edit</MenuItem>}
                  {hasWritePermissions && <MenuItem onClick={handleDeleteClick}>Delete</MenuItem>}
                </Menu>
                <DataTable
                  columns={columns}
                  data={weatherRecordsResponse.data}
                  total={weatherRecordsResponse.total}
                  paginationMode="server"
                  page={page}
                  pageSize={pageSize}
                  onPageChange={(newPage) => {
                    setPage(newPage)
                  }}
                  onPageSizeChange={(newPageSize) => {
                    setPageSize(newPageSize)
                  }}
                  orderBy={orderBy}
                  orderDirection={orderDirection}
                  onOrderChange={(newOrder) => {
                    setOrderBy(newOrder.field ? (newOrder.field as keyof WeatherRecord) : undefined)
                    setOrderDirection(newOrder.sort)
                  }}
                />
              </>
            ) : (
              <Box sx={{ p: 2 }}>
                <Typography>No results found</Typography>
              </Box>
            )}
          </>
        )}
      </Paper>
    </>
  )
}
export default WeatherRecordSearch

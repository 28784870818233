export enum TrailStatuses {
  Closed = 0,
  Rutty = 1,
  Fair = 2,
  Firm = 3,
}

export const trailStatuses = [
  { name: 'Closed', id: 0 },
  { name: 'Rutty', id: 1 },
  { name: 'Fair', id: 2 },
  { name: 'Firm', id: 3 },
]

import { useLocation } from 'react-router-dom'

import { Paper, Stack } from '@mui/material'

interface UnauthorizedState {
  returnUrl?: string
}

const Unauthorized = (): JSX.Element => {
  const location = useLocation()
  const { returnUrl } = (location.state || {}) as UnauthorizedState

  return (
    <Paper sx={{ p: 2 }}>
      <Stack direction="row" alignItems="center" justifyContent="center" sx={{ width: '100%', height: '100%' }}>
        Your user is not authorized to access {returnUrl && returnUrl !== '/' ? returnUrl : 'this page'}.
      </Stack>
    </Paper>
  )
}

export default Unauthorized

import { AppRegions } from './AppRegions.model'
import { TrailFinderAnswer } from './TrailFinderAnswer.model'

export type TrailFinderQuestion = {
  id: number
  region: AppRegions
  question: string
  answers?: TrailFinderAnswer[]
  sortWeight: number
  active: boolean
  updatedAt: Date
  createdAt: Date
}

// we offer this to enable the enumerate the TrailFinderQuestion type's fields in js/ts files
export const trailFinderQuestionFields: (keyof TrailFinderQuestion)[] = [
  'id',
  'region',
  'question',
  'answers',
  'sortWeight',
  'active',
  'updatedAt',
  'createdAt',
]

import { AppRegions } from './AppRegions.model'
import { Trail } from './Trail.model'
import { TrailSystem } from './TrailSystem.model'

export type Sponsor = {
  id: number
  name: string
  region: AppRegions
  url?: string | null
  logoUrl?: string | null
  active?: boolean
  system?: TrailSystem
  trail?: Trail
  start: Date | null
  end: Date | null
  updatedAt: Date
  createdAt: Date
}

// we offer this to enable the enumerate the Sponsor type's fields in js/ts files
export const sponsorFields: (keyof Sponsor)[] = [
  'id',
  'name',
  'region',
  'url',
  'logoUrl',
  'active',
  'system',
  'trail',
  'start',
  'end',
  'updatedAt',
  'createdAt',
]

import { formatToNumberTypeProps } from '../../utils/formatting'

import { PaginatedResponse } from '../../models/PaginatedResponse.model'
import { TrailSystem } from '../../models/TrailSystem.model'
import { TrailSystemDTO } from '../../models/TrailSystemDTO.model'
import { TrailSystemAddEvent } from '../../events/TrailSystemAddEvent'
import { TrailSystemUpdateEvent } from '../../events/TrailSystemUpdateEvent'
import { TrailSystemRelations } from '../../models/TrailSystemRelations.model'
import { TrailSystemSearchEvent } from '../../events/TrailSystemSearchEvent'

import FlowfeedApiQueryService, {
  FlowfeedApiQueryServiceGetEvent,
} from '../FlowfeedApiQueryService/FlowfeedApiQueryService'
import { WeatherGroupService } from '../WeatherGroupService/WeatherGroupService'
import { TrailService } from '../TrailService/TrailService'
import { SponsorService } from '../SponsorService/SponsorService'
import { WeatherRecordService } from '../WeatherRecordService/WeatherRecordService'

export class TrailSystemService {
  /**
   * @description Search for a single trail system by id or permalink
   *
   * @param id
   * @param permalink
   * @param relations
   *
   * @returns promise for a TrailSystem
   */
  public async get({
    id,
    permalink,
    relations,
  }: {
    id?: number
    permalink?: string
    relations?: TrailSystemRelations[]
  }): Promise<TrailSystem> {
    const params = {
      id,
      permalink,
      relations:
        Array.isArray(relations) && !relations.length
          ? 'false' // false is treated as an empty array by the API since an empty string would be treated as default relations normally
          : relations?.join(',') || undefined,
    }
    const flowfeedApiQueryRequest: FlowfeedApiQueryServiceGetEvent = {
      endpoint: `trail-system/get`,
      params,
    }

    const flowfeedApiQueryService = new FlowfeedApiQueryService()
    try {
      const trailSystemRaw = await flowfeedApiQueryService.get<TrailSystemDTO>(flowfeedApiQueryRequest)
      return TrailSystemService.formatTrailSystem(trailSystemRaw)
    } catch (error) {
      console.error(error)
      throw 'Sorry, there was a problem with your request.'
    }
  }

  public async search(event: TrailSystemSearchEvent): Promise<PaginatedResponse<TrailSystem>> {
    const params = {
      ...event,
      name: event.name || undefined,
      orderDirection: event.orderDirection?.toUpperCase(),
      relations:
        Array.isArray(event.relations) && !event.relations.length
          ? 'false' // false is treated as an empty array by the API since an empty string would be treated as default relations normally
          : event.relations?.join(',') || undefined,
    }

    const flowfeedApiQueryRequest: FlowfeedApiQueryServiceGetEvent = {
      endpoint: 'trail-system',
      params,
    }

    const trailSystems: {
      data: TrailSystem[]
      total: number
    } = {
      data: [],
      total: 0,
    }
    try {
      const flowfeedApiQueryService = new FlowfeedApiQueryService()
      const rawTrailSystems = await flowfeedApiQueryService.get<PaginatedResponse<TrailSystemDTO>>(
        flowfeedApiQueryRequest,
      )
      // wrap to avoid errors if the request is cancelled
      if (rawTrailSystems?.data) {
        trailSystems.data = rawTrailSystems.data.map((trailSystem) => TrailSystemService.formatTrailSystem(trailSystem))
        trailSystems.total = rawTrailSystems.total
      }
    } catch (error) {
      console.error(error)
      throw 'Sorry, there was a problem with your request.'
    }

    return trailSystems
  }

  public async add(trailSystem: TrailSystemAddEvent): Promise<TrailSystem> {
    const flowfeedApiQueryRequest = {
      endpoint: 'trail-system',
      data: formatToNumberTypeProps(trailSystem, ['sortWeight']),
    }

    const flowfeedApiQueryService = new FlowfeedApiQueryService()
    try {
      const trailSystemRaw = await flowfeedApiQueryService.post<TrailSystemDTO>(flowfeedApiQueryRequest)
      return TrailSystemService.formatTrailSystem(trailSystemRaw)
    } catch (error) {
      console.error(error)
      if (error instanceof Error) {
        throw error.message
      } else if ((error as { data: { message: string } })?.data?.message) {
        throw (error as { data: { message: string } })?.data?.message
      }
      throw error
    }
  }

  public async update(id: number, trailSystem: TrailSystemUpdateEvent): Promise<TrailSystem> {
    const flowfeedApiQueryRequest = {
      endpoint: `trail-system/${id}`,
      data: formatToNumberTypeProps(trailSystem, ['sortWeight']),
    }
    try {
      const flowfeedApiQueryService = new FlowfeedApiQueryService()
      const trailSystemRaw = await flowfeedApiQueryService.patch<TrailSystemDTO>(flowfeedApiQueryRequest)
      return TrailSystemService.formatTrailSystem(trailSystemRaw)
    } catch (error) {
      console.error(error)
      if (error instanceof Error) {
        throw error.message
      } else if ((error as { data: { message: string } })?.data?.message) {
        throw (error as { data: { message: string } })?.data?.message
      }
      throw error
    }
  }

  public async delete(id: number): Promise<void> {
    const flowfeedApiQueryRequest = {
      endpoint: `trail-system/${id}`,
    }

    try {
      const flowfeedApiQueryService = new FlowfeedApiQueryService()
      await flowfeedApiQueryService.delete(flowfeedApiQueryRequest)
    } catch (error) {
      console.error(error)
      if (error instanceof Error) {
        throw error.message
      } else if ((error as { data: { message: string } })?.data?.message) {
        throw (error as { data: { message: string } })?.data?.message
      }
      throw error
    }
  }

  public async import(
    trailSystems: (Partial<TrailSystem> & {
      trailIds?: number[]
      weatherGroupId?: number
      displayWeatherGroupId?: number
    })[],
    options?: {
      matchingField?: string | undefined
      deleteUnmatchedExisting?: boolean
    },
  ): Promise<void> {
    const data = trailSystems.map((trailSystem) => {
      const numberTypeProps = ['id', 'weatherGroupId', 'displayWeatherGroupId']
      return {
        ...formatToNumberTypeProps(trailSystem, numberTypeProps),
        trailIds: trailSystem.trailIds?.map((trailId) => Number(trailId)) || undefined,
      }
    })
    const flowfeedApiQueryRequest = {
      endpoint: `trail-system/sync`,
      data: {
        data,
        matchingProp: options?.matchingField,
        deleteMissing: options?.deleteUnmatchedExisting,
      },
    }

    try {
      const flowfeedApiQueryService = new FlowfeedApiQueryService()
      await flowfeedApiQueryService.post(flowfeedApiQueryRequest)
    } catch (error) {
      console.error(error)
      if (error instanceof Error) {
        throw error.message
      } else if ((error as { data: { message: string } })?.data?.message) {
        throw (error as { data: { message: string } })?.data?.message
      }
      throw error
    }
  }

  public static formatTrailSystem(trailSystem: TrailSystemDTO): TrailSystem {
    return {
      id: trailSystem.id,
      name: trailSystem.name,
      region: trailSystem.region,
      trails:
        trailSystem.trails && Array.isArray(trailSystem.trails)
          ? trailSystem.trails.map(TrailService.formatTrail)
          : undefined,
      weatherGroup: trailSystem.weatherGroup
        ? WeatherGroupService.formatWeatherGroup(trailSystem.weatherGroup)
        : undefined,
      displayWeatherGroup: trailSystem.displayWeatherGroup
        ? WeatherGroupService.formatWeatherGroup(trailSystem.displayWeatherGroup)
        : undefined,
      sponsor: trailSystem.sponsor ? SponsorService.formatSponsor(trailSystem.sponsor) : undefined,
      mostRecentDisplayWeatherRecord: trailSystem.mostRecentDisplayWeatherRecord
        ? WeatherRecordService.formatWeatherRecord(trailSystem.mostRecentDisplayWeatherRecord)
        : undefined,
      description: trailSystem.description,
      parkingGpsLat: trailSystem.parkingGpsLat,
      parkingGpsLong: trailSystem.parkingGpsLong,
      street: trailSystem.street,
      city: trailSystem.city,
      state: trailSystem.state,
      sortWeight: trailSystem.sortWeight,
      permalink: trailSystem.permalink,
      keywords: trailSystem.keywords,
      active: trailSystem.active,
      updatedAt: new Date(trailSystem.updatedAt),
      createdAt: new Date(trailSystem.createdAt),
    }
  }
}

import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import camelCase from 'lodash/camelCase'
import startCase from 'lodash/startCase'
import { AppLayoutContext } from '../../../providers/AppLayout'
import { useAppNavigate, useUrlStateParams } from '../../../utils/url'
import { SnackbarContext } from '../../../providers/SnackbarContext'
import { formatDate } from '../../../utils/formatting'

import { AppPermissions } from '../../../models/AppPermissions.model'
import { ManualTrailStatus } from '../../../models/ManualTrailStatus.model'
import { ManualTrailStatusRelations } from '../../../models/ManualTrailStatusRelations.model'
import { TrailStatuses } from '../../../models/TrailStatuses.model'

import UserService from '../../../services/UserService/UserService'
import { ManualTrailStatusService } from '../../../services/ManualTrailStatusService/ManualTrailStatusService'

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Link,
  Paper,
  Stack,
  Typography,
} from '@mui/material'
import Loading from '../../../components/Loading/Loading.component'
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs.component'
import FormDialog from '../../../components/FormDialog/FormDialog.component'
import ManualTrailStatusForm from '../shared/ManualTrailStatusForm.component'
import { useQuery, useQueryClient } from 'react-query'
import { AppStateContext } from '../../../providers/AppStateContext'

const ManualTrailStatusDetail = (): JSX.Element => {
  const navigate = useAppNavigate()
  const { id } = useParams<{ id: string }>()
  const [loadingError, setLoadingError] = useState('')

  const { user } = useAuth0()
  const hasWritePermissions = UserService.hasPermissions(user, [AppPermissions.editManualTrailStatus])
  const hasTrailPermissions = UserService.hasPermissions(user, [AppPermissions.viewTrail])
  const hasTrailSystemPermissions = UserService.hasPermissions(user, [AppPermissions.viewTrailSystem])

  const [isSaving, setIsSaving] = useState(false)
  const [isEditing, setIsEditing] = useUrlStateParams<boolean>(
    false,
    'edit',
    (value) => (value ? 't' : ''),
    (value) => value === 't',
  )
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false)

  const manualTrailStatusService = useMemo(() => new ManualTrailStatusService(), []) // memo to prevent re-creating service on every render

  useEffect(() => {
    AppLayoutContext.setPageName(`Manual Status Detail`)
  }, [id])

  const queryClient = useQueryClient()
  const { isLoading, data: manualTrailStatus } = useQuery(
    [
      'ManualTrailStatusService.get',
      {
        id: Number(id),
        relations: [ManualTrailStatusRelations.system, ManualTrailStatusRelations.trail],
      },
      AppStateContext.getRegion(),
    ],
    () =>
      manualTrailStatusService.get({
        id: Number(id),
        relations: [ManualTrailStatusRelations.system, ManualTrailStatusRelations.trail],
      }),
    {
      onError: (err) => {
        setLoadingError(typeof err === 'string' ? err : 'Failed to load manual status.')
        console.error(err)
      },
    },
  )

  const handleEdit = () => {
    setIsEditing(false)
  }

  const handleDeleteClick = () => {
    // show a confirmation dialog before deleting
    setIsDeleteConfirmationOpen(true)
  }

  const handleDelete = () => {
    if (manualTrailStatus?.id) {
      setIsSaving(true)
      setIsDeleteConfirmationOpen(false)
      manualTrailStatusService
        .delete(manualTrailStatus.id)
        .then(() => {
          SnackbarContext.show(`Manual Status deleted successfully. You will now be redirected.`)

          // after a delay of 1 second, redirect to the manualTrailStatus list page
          setTimeout(() => {
            navigate(`/manual-trail-statuses`)
            queryClient.removeQueries([
              'ManualTrailStatusService.get',
              {
                id: Number(id),
                relations: [ManualTrailStatusRelations.system, ManualTrailStatusRelations.trail],
              },
            ])
            // invalidate all queries to ensure any queries that are using the modified record are updated
            queryClient.invalidateQueries()
          }, 1000)
        })
        .catch((err) => {
          console.error(err)
          SnackbarContext.show(`Manual Status failed to delete: ${err}`, 'error')
          setIsSaving(false)
        })
    }
  }

  const validStatus = Object.entries(TrailStatuses).find((trailStatus) => trailStatus[1] === manualTrailStatus?.status)

  return (
    <>
      {manualTrailStatus && (manualTrailStatus.system || manualTrailStatus.trail) && (
        <FormDialog<ManualTrailStatus>
          open={isEditing}
          entity={manualTrailStatus}
          entityLabel="Manual Trail Status"
          onClose={() => setIsEditing(false)}
        >
          <ManualTrailStatusForm
            manualTrailStatus={manualTrailStatus}
            trail={manualTrailStatus.trail}
            trailSystem={manualTrailStatus.system}
            onEdit={handleEdit}
            onCancel={() => setIsEditing(false)}
          />
        </FormDialog>
      )}
      <Dialog
        fullWidth
        maxWidth="xs"
        open={isDeleteConfirmationOpen}
        onClose={() => setIsDeleteConfirmationOpen(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent dividers>Are you sure you want to delete this manual status?</DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setIsDeleteConfirmationOpen(false)}>
            Cancel
          </Button>
          <Button onClick={handleDelete}>Confirm</Button>
        </DialogActions>
      </Dialog>
      <Paper sx={{ p: 2 }}>
        {isLoading && !loadingError && <Loading sx={{ py: 20 }} />}
        {loadingError && (
          <Stack direction="row" justifyContent="center" alignItems="center" sx={{ py: 20 }}>
            <Typography variant="error">{loadingError}</Typography>
          </Stack>
        )}
        {!isLoading && !loadingError && manualTrailStatus && !manualTrailStatus.system && !manualTrailStatus.trail && (
          <Stack direction="row" justifyContent="center" alignItems="center" sx={{ py: 20 }}>
            <Typography variant="error">
              Failed to locate the assigned trail system or trail for the requested manual status.
            </Typography>
          </Stack>
        )}
        {!isLoading && !loadingError && manualTrailStatus && (manualTrailStatus.system || manualTrailStatus.trail) && (
          <>
            {isSaving && <Loading sx={{ py: 20 }} />}
            {!isSaving && (
              <>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{ mb: 2 }}>
                  <Breadcrumbs
                    items={() => {
                      const breadcrumbs: Array<{ label: string; href?: string }> = [
                        {
                          label: 'Manual Statuses',
                          href: '/manual-trail-statuses',
                        },
                      ]
                      if (hasTrailSystemPermissions && manualTrailStatus.system) {
                        breadcrumbs.push({
                          label: manualTrailStatus.system.name || 'Trail System',
                          href: `/trail-systems/${manualTrailStatus.system.id}`,
                        })
                      }
                      if (hasTrailPermissions && manualTrailStatus.trail) {
                        breadcrumbs.push({
                          label: manualTrailStatus.trail.name || 'Trail',
                          href: `/trails/${manualTrailStatus.trail.id}`,
                        })
                      }
                      breadcrumbs.push({ label: `Manual Status ID: ${manualTrailStatus.id}` })

                      return breadcrumbs
                    }}
                  />
                  {hasWritePermissions && (
                    <Stack direction="row" justifyContent="end" alignItems="center" spacing={2}>
                      <Button variant="text" onClick={handleDeleteClick}>
                        Delete
                      </Button>
                      <Button variant="contained" onClick={() => setIsEditing(true)}>
                        Edit
                      </Button>
                    </Stack>
                  )}
                </Stack>
                <Box>
                  <Box>
                    <Typography variant="h5" component="h2">
                      Status: {validStatus ? startCase(camelCase(validStatus[0])) : 'Unknown'}
                    </Typography>
                    <Typography variant="h6" component="h3">
                      {manualTrailStatus.reason}
                    </Typography>
                    <Typography variant="subtitle1" color={(theme) => theme.palette.grey[500]}>
                      ID: {manualTrailStatus.id}
                    </Typography>
                    {manualTrailStatus.system?.id && (
                      <Typography variant="subtitle1">
                        System:{' '}
                        {hasTrailSystemPermissions ? (
                          <Link href={`/trail-systems/${manualTrailStatus.system?.id}`}>
                            {manualTrailStatus.system?.name}
                          </Link>
                        ) : (
                          manualTrailStatus.system?.name
                        )}
                      </Typography>
                    )}
                    {manualTrailStatus.trail?.id && (
                      <Typography variant="subtitle1">
                        Trail:{' '}
                        {hasTrailPermissions ? (
                          <Link href={`/trails/${manualTrailStatus.trail?.id}`}>{manualTrailStatus.trail?.name}</Link>
                        ) : (
                          manualTrailStatus.trail?.name
                        )}
                      </Typography>
                    )}
                    <Typography variant="subtitle1" color={(theme) => theme.palette.grey[500]}>
                      {manualTrailStatus.region}
                    </Typography>
                    <Box sx={{ mt: 2 }}>
                      <Typography variant="body1">
                        Start: {manualTrailStatus.start ? formatDate(manualTrailStatus.start) : 'Immediately'}
                      </Typography>
                      <Typography variant="body1">
                        End: {manualTrailStatus.end ? formatDate(manualTrailStatus.end) : 'Never'}
                      </Typography>
                    </Box>
                  </Box>
                  <Divider sx={{ my: 2 }} />
                  <Box>
                    <Typography variant="body1">Updated At: {formatDate(manualTrailStatus.updatedAt)}</Typography>
                    <Typography variant="body1">Created At: {formatDate(manualTrailStatus.createdAt)}</Typography>
                  </Box>
                </Box>
              </>
            )}
          </>
        )}
      </Paper>
    </>
  )
}
export default ManualTrailStatusDetail

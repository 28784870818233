export const weatherConditions = [
  {
    id: 1,
    openweatherConditionId: 200,
    name: 'Thunderstorm',
    description: 'Thunderstorm with light rain',
  },
  {
    id: 2,
    openweatherConditionId: 201,
    name: 'Thunderstorm',
    description: 'Thunderstorm with rain',
  },
  {
    id: 3,
    openweatherConditionId: 202,
    name: 'Thunderstorm',
    description: 'Thunderstorm with heavy rain',
  },
  {
    id: 4,
    openweatherConditionId: 210,
    name: 'Thunderstorm',
    description: 'Light thunderstorm',
  },
  {
    id: 5,
    openweatherConditionId: 211,
    name: 'Thunderstorm',
    description: 'Thunderstorm',
  },
  {
    id: 6,
    openweatherConditionId: 212,
    name: 'Thunderstorm',
    description: 'Heavy thunderstorm',
  },
  {
    id: 7,
    openweatherConditionId: 221,
    name: 'Thunderstorm',
    description: 'Ragged thunderstorm',
  },
  {
    id: 8,
    openweatherConditionId: 230,
    name: 'Thunderstorm',
    description: 'Thunderstorm with light drizzle',
  },
  {
    id: 9,
    openweatherConditionId: 231,
    name: 'Thunderstorm',
    description: 'Thunderstorm with drizzle',
  },
  {
    id: 10,
    openweatherConditionId: 232,
    name: 'Thunderstorm',
    description: 'Thunderstorm with heavy drizzle',
  },
  {
    id: 11,
    openweatherConditionId: 300,
    name: 'Drizzle',
    description: 'Light intensity drizzle',
  },
  {
    id: 12,
    openweatherConditionId: 301,
    name: 'Drizzle',
    description: 'Drizzle',
  },
  {
    id: 13,
    openweatherConditionId: 302,
    name: 'Drizzle',
    description: 'Heavy intensity drizzle',
  },
  {
    id: 14,
    openweatherConditionId: 310,
    name: 'Drizzle',
    description: 'Light intensity drizzle rain',
  },
  {
    id: 15,
    openweatherConditionId: 311,
    name: 'Drizzle',
    description: 'Drizzle rain',
  },
  {
    id: 16,
    openweatherConditionId: 312,
    name: 'Drizzle',
    description: 'Heavy intensity drizzle rain',
  },
  {
    id: 17,
    openweatherConditionId: 313,
    name: 'Drizzle',
    description: 'Shower rain and drizzle',
  },
  {
    id: 18,
    openweatherConditionId: 314,
    name: 'Drizzle',
    description: 'Heavy shower rain and drizzle',
  },
  {
    id: 19,
    openweatherConditionId: 321,
    name: 'Drizzle',
    description: 'Shower drizzle',
  },
  {
    id: 20,
    openweatherConditionId: 500,
    name: 'Rain',
    description: 'Light rain',
  },
  {
    id: 21,
    openweatherConditionId: 501,
    name: 'Rain',
    description: 'Moderate rain',
  },
  {
    id: 22,
    openweatherConditionId: 502,
    name: 'Rain',
    description: 'Heavy intensity rain',
  },
  {
    id: 23,
    openweatherConditionId: 503,
    name: 'Rain',
    description: 'Very heavy rain',
  },
  {
    id: 24,
    openweatherConditionId: 504,
    name: 'Rain',
    description: 'Extreme rain',
  },
  {
    id: 25,
    openweatherConditionId: 511,
    name: 'Rain',
    description: 'Freezing rain',
  },
  {
    id: 26,
    openweatherConditionId: 520,
    name: 'Rain',
    description: 'Light intensity shower rain',
  },
  {
    id: 27,
    openweatherConditionId: 521,
    name: 'Rain',
    description: 'Shower rain',
  },
  {
    id: 28,
    openweatherConditionId: 522,
    name: 'Rain',
    description: 'Heavy intensity shower rain',
  },
  {
    id: 29,
    openweatherConditionId: 531,
    name: 'Rain',
    description: 'Ragged shower rain',
  },
  {
    id: 30,
    openweatherConditionId: 600,
    name: 'Snow',
    description: 'Light snow',
  },
  {
    id: 31,
    openweatherConditionId: 601,
    name: 'Snow',
    description: 'Snow',
  },
  {
    id: 32,
    openweatherConditionId: 602,
    name: 'Snow',
    description: 'Heavy snow',
  },
  {
    id: 33,
    openweatherConditionId: 611,
    name: 'Snow',
    description: 'Sleet',
  },
  {
    id: 34,
    openweatherConditionId: 612,
    name: 'Snow',
    description: 'Light shower sleet',
  },
  {
    id: 35,
    openweatherConditionId: 613,
    name: 'Snow',
    description: 'Shower sleet',
  },
  {
    id: 36,
    openweatherConditionId: 615,
    name: 'Snow',
    description: 'Light rain and snow',
  },
  {
    id: 37,
    openweatherConditionId: 616,
    name: 'Snow',
    description: 'Rain and snow',
  },
  {
    id: 38,
    openweatherConditionId: 620,
    name: 'Snow',
    description: 'Light shower snow',
  },
  {
    id: 39,
    openweatherConditionId: 621,
    name: 'Snow',
    description: 'Shower snow',
  },
  {
    id: 40,
    openweatherConditionId: 622,
    name: 'Snow',
    description: 'Heavy shower snow',
  },
  {
    id: 41,
    openweatherConditionId: 701,
    name: 'Mist',
    description: 'Mist',
  },
  {
    id: 42,
    openweatherConditionId: 711,
    name: 'Smoke',
    description: 'Smoke',
  },
  {
    id: 43,
    openweatherConditionId: 731,
    name: 'Dust',
    description: 'Sand/Dust whirls',
  },
  {
    id: 44,
    openweatherConditionId: 741,
    name: 'Fog',
    description: 'Fog',
  },
  {
    id: 45,
    openweatherConditionId: 751,
    name: 'Sand',
    description: 'Sand',
  },
  {
    id: 46,
    openweatherConditionId: 761,
    name: 'Dust',
    description: 'Dust',
  },
  {
    id: 47,
    openweatherConditionId: 762,
    name: 'Ash',
    description: 'Volcanic ash',
  },
  {
    id: 48,
    openweatherConditionId: 771,
    name: 'Squall',
    description: 'Squalls',
  },
  {
    id: 49,
    openweatherConditionId: 781,
    name: 'Tornado',
    description: 'Tornado',
  },
  {
    id: 50,
    openweatherConditionId: 800,
    name: 'Clear',
    description: 'Clear sky',
  },
  {
    id: 51,
    openweatherConditionId: 801,
    name: 'Clouds',
    description: 'A Few clouds',
  },
  {
    id: 52,
    openweatherConditionId: 802,
    name: 'Clouds',
    description: 'Scattered clouds',
  },
  {
    id: 53,
    openweatherConditionId: 803,
    name: 'Clouds',
    description: 'Broken clouds',
  },
  {
    id: 54,
    openweatherConditionId: 804,
    name: 'Clouds',
    description: 'Overcast',
  },
  {
    id: 55,
    openweatherConditionId: 721,
    name: 'Haze',
    description: 'Haze',
  },
]

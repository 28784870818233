import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { useQuery, useQueryClient } from 'react-query'
import { AppLayoutContext } from '../../../providers/AppLayout'
import { useAppNavigate, useUrlStateParams } from '../../../utils/url'
import { SnackbarContext } from '../../../providers/SnackbarContext'

import { AppPermissions } from '../../../models/AppPermissions.model'
import { WeatherRecord } from '../../../models/WeatherRecord.model'

import UserService from '../../../services/UserService/UserService'
import { WeatherRecordService } from '../../../services/WeatherRecordService/WeatherRecordService'

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Link,
  Paper,
  Stack,
  Typography,
} from '@mui/material'
import Loading from '../../../components/Loading/Loading.component'
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs.component'
import { formatDate } from '../../../utils/formatting'
import { weatherConditions } from '../../../models/WeatherConditions.model'
import FormDialog from '../../../components/FormDialog/FormDialog.component'
import WeatherRecordForm from '../shared/WeatherRecordForm.component'
import { AppStateContext } from '../../../providers/AppStateContext'

const WeatherRecordDetail = (): JSX.Element => {
  const navigate = useAppNavigate()
  const { id } = useParams<{ id: string }>()
  const [loadingError, setLoadingError] = useState('')

  const { user } = useAuth0()
  const hasWritePermissions = UserService.hasPermissions(user, [AppPermissions.editWeatherRecord])

  const [isSaving, setIsSaving] = useState(false)
  const [isEditing, setIsEditing] = useUrlStateParams<boolean>(
    false,
    'edit',
    (value) => (value ? 't' : ''),
    (value) => value === 't',
  )
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false)

  const weatherRecordService = useMemo(() => new WeatherRecordService(), []) // memo to prevent re-creating service on every render

  useEffect(() => {
    AppLayoutContext.setPageName(`Weather Record Detail`)
  }, [id])

  const queryClient = useQueryClient()
  const { isLoading, data: weatherRecord } = useQuery(
    [
      'WeatherRecordService.get',
      {
        id: Number(id),
      },
      AppStateContext.getRegion(),
    ],
    () => weatherRecordService.get(Number(id)),
    {
      onError: (err) => {
        setLoadingError(typeof err === 'string' ? err : 'Failed to load weather record.')
        console.error(err)
      },
    },
  )

  const handleEdit = () => {
    setIsEditing(false)
  }

  const handleDeleteClick = () => {
    // show a confirmation dialog before deleting
    setIsDeleteConfirmationOpen(true)
  }

  const handleDelete = () => {
    if (weatherRecord?.id) {
      setIsSaving(true)
      setIsDeleteConfirmationOpen(false)
      weatherRecordService
        .delete(weatherRecord.id)
        .then(() => {
          SnackbarContext.show(`Weather Record deleted successfully. You will now be redirected.`)

          // after a delay of 1 second, redirect to the weatherRecord list page
          setTimeout(() => {
            navigate(
              weatherRecord.weatherGroup ? `/weather-groups/${weatherRecord.weatherGroup.id}` : '/weather-records',
            )
            queryClient.removeQueries([
              'WeatherRecordService.get',
              {
                id: Number(id),
              },
            ])
            // invalidate all queries to ensure any queries that are using the modified record are updated
            queryClient.invalidateQueries()
          }, 1000)
        })
        .catch((err) => {
          console.error(err)
          SnackbarContext.show(`Weather Record failed to delete: ${err}`, 'error')
          setIsSaving(false)
        })
    }
  }

  const fullWeatherCondition = weatherConditions.find((condition) => condition.id === weatherRecord?.conditionId)

  return (
    <>
      {weatherRecord && (
        <FormDialog<WeatherRecord>
          open={isEditing}
          entity={weatherRecord}
          entityLabel="Weather Record"
          onClose={() => setIsEditing(false)}
        >
          <WeatherRecordForm weatherRecord={weatherRecord} onEdit={handleEdit} onCancel={() => setIsEditing(false)} />
        </FormDialog>
      )}
      <Dialog
        fullWidth
        maxWidth="xs"
        open={isDeleteConfirmationOpen}
        onClose={() => setIsDeleteConfirmationOpen(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent dividers>Are you sure you want to delete this weather record?</DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setIsDeleteConfirmationOpen(false)}>
            Cancel
          </Button>
          <Button onClick={handleDelete}>Confirm</Button>
        </DialogActions>
      </Dialog>
      <Paper sx={{ p: 2 }}>
        {isLoading && !loadingError && <Loading sx={{ py: 20 }} />}
        {loadingError && (
          <Stack direction="row" justifyContent="center" alignItems="center" sx={{ py: 20 }}>
            <Typography variant="error">{loadingError}</Typography>
          </Stack>
        )}
        {!isLoading && !loadingError && weatherRecord && (
          <>
            {isSaving && <Loading sx={{ py: 20 }} />}
            {!isSaving && (
              <>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{ mb: 2 }}>
                  <Breadcrumbs
                    items={[
                      {
                        label: weatherRecord.weatherGroup ? weatherRecord.weatherGroup.name : 'Weather Records',
                        href: weatherRecord.weatherGroup
                          ? `/weather-groups/${weatherRecord.weatherGroup.id}`
                          : '/weather-records',
                      },
                      { label: weatherRecord.id.toString() },
                    ]}
                  />
                  {hasWritePermissions && (
                    <Stack direction="row" justifyContent="end" alignItems="center" spacing={2}>
                      <Button variant="text" onClick={handleDeleteClick}>
                        Delete
                      </Button>
                      <Button variant="contained" onClick={() => setIsEditing(true)}>
                        Edit
                      </Button>
                    </Stack>
                  )}
                </Stack>
                <Box>
                  <Box>
                    <Typography variant="h5" color={(theme) => theme.palette.grey[600]}>
                      ID: {weatherRecord.id}
                    </Typography>
                  </Box>
                  <Divider sx={{ my: 2 }} />
                  {weatherRecord.weatherGroup && (
                    <>
                      <Box>
                        <Typography variant="h6" component="h3">
                          Weather Group
                        </Typography>
                        <Typography variant="body1">
                          <Link href={`/weather-groups/${weatherRecord.weatherGroup?.id}`}>
                            {weatherRecord.weatherGroup?.name}
                          </Link>
                        </Typography>
                      </Box>
                      <Divider sx={{ my: 2 }} />
                    </>
                  )}
                  <Box>
                    <Typography variant="body1">Timestamp: {formatDate(weatherRecord.timestamp)}</Typography>
                  </Box>
                  <Divider sx={{ my: 2 }} />
                  <Box>
                    <Typography variant="body1">Region: {weatherRecord.region}</Typography>
                    <Typography variant="body1">Type: {weatherRecord.type}</Typography>
                  </Box>
                  <Divider sx={{ my: 2 }} />
                  <Box>
                    <Typography variant="body1">Temperature: {weatherRecord.temperature} °C</Typography>
                    <Typography variant="body1">Temperature Minimum: {weatherRecord.tempMin} °C</Typography>
                    <Typography variant="body1">Temperature Maximum: {weatherRecord.tempMax} °C</Typography>
                    <Typography variant="body1">Feels Like: {weatherRecord.feelsLike} °C</Typography>
                  </Box>
                  <Divider sx={{ my: 2 }} />
                  <Box>
                    <Typography variant="body1">Pressure: {weatherRecord.pressure} hPa</Typography>
                    <Typography variant="body1">Humidity: {weatherRecord.relativeHumidity}%</Typography>
                    <Typography variant="body1">
                      Precipitation (Last 1 Hour): {weatherRecord.precipLast1hr} mm
                    </Typography>
                    <Typography variant="body1">Snow (Last 1 Hour): {weatherRecord.snowLast1hr} mm</Typography>
                    <Typography variant="body1">Wind Speed: {weatherRecord.windSpeed} meters/sec</Typography>
                    <Typography variant="body1">Wind Direction: {weatherRecord.windDirection} °</Typography>
                    <Typography variant="body1">UV Rating: {weatherRecord.uv}</Typography>
                    <Typography variant="body1">Brightness: {weatherRecord.brightness} mb</Typography>
                    <Typography variant="body1">
                      Sunrise: {weatherRecord.sunrise ? formatDate(weatherRecord.sunrise) : 'N/A'}
                    </Typography>
                    <Typography variant="body1">
                      Sunset: {weatherRecord.sunset ? formatDate(weatherRecord.sunset) : 'N/A'}
                    </Typography>
                  </Box>
                  <Divider sx={{ my: 2 }} />
                  <Box>
                    <Typography variant="h6" component="h3">
                      Weather Condition
                    </Typography>
                    <Typography variant="body1">
                      {fullWeatherCondition
                        ? `${fullWeatherCondition.name}: ${fullWeatherCondition.description}`
                        : 'N/A'}
                    </Typography>
                  </Box>
                  <Divider sx={{ my: 2 }} />
                  <Box>
                    <Typography variant="body1">Updated At: {formatDate(weatherRecord.updatedAt)}</Typography>
                    <Typography variant="body1">Created At: {formatDate(weatherRecord.createdAt)}</Typography>
                  </Box>
                </Box>
              </>
            )}
          </>
        )}
      </Paper>
    </>
  )
}
export default WeatherRecordDetail

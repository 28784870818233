import { PaginatedResponse } from '../../models/PaginatedResponse.model'
import { WeatherRecord } from '../../models/WeatherRecord.model'
import { WeatherRecordAddEvent } from '../../events/WeatherRecordAddEvent'
import { WeatherRecordUpdateEvent } from '../../events/WeatherRecordUpdateEvent'
import { WeatherRecordSearchEvent } from '../../events/WeatherRecordSearchEvent'

import FlowfeedApiQueryService, {
  FlowfeedApiQueryServiceGetEvent,
} from '../FlowfeedApiQueryService/FlowfeedApiQueryService'
import { WeatherRecordDTO } from '../../models/WeatherRecordDTO.model'
import { WeatherGroupService } from '../WeatherGroupService/WeatherGroupService'

export class WeatherRecordService {
  /**
   * @description Search for a single weatherRecord by id
   *
   * @param id
   *
   * @returns promise for a WeatherRecord
   */
  public async get(id?: number): Promise<WeatherRecord> {
    const flowfeedApiQueryRequest: FlowfeedApiQueryServiceGetEvent = {
      endpoint: `weather-record/${id}`,
    }

    const flowfeedApiQueryService = new FlowfeedApiQueryService()
    try {
      const weatherRecordRaw = await flowfeedApiQueryService.get<WeatherRecordDTO>(flowfeedApiQueryRequest)
      return WeatherRecordService.formatWeatherRecord(weatherRecordRaw)
    } catch (error) {
      console.error(error)
      throw 'Sorry, there was a problem with your request.'
    }
  }

  public async search(event: WeatherRecordSearchEvent): Promise<PaginatedResponse<WeatherRecord>> {
    const params = {
      ...event,
      orderDirection: event.orderDirection?.toUpperCase(),
    }

    const flowfeedApiQueryRequest: FlowfeedApiQueryServiceGetEvent = {
      endpoint: 'weather-record',
      params,
    }

    const weatherRecords: {
      data: WeatherRecord[]
      total: number
    } = {
      data: [],
      total: 0,
    }
    try {
      const flowfeedApiQueryService = new FlowfeedApiQueryService()
      const rawWeatherRecords = await flowfeedApiQueryService.get<PaginatedResponse<WeatherRecordDTO>>(
        flowfeedApiQueryRequest,
      )
      // wrap to avoid errors if the request is cancelled
      if (rawWeatherRecords?.data) {
        weatherRecords.data = rawWeatherRecords.data.map((weatherRecord) =>
          WeatherRecordService.formatWeatherRecord(weatherRecord),
        )
        weatherRecords.total = rawWeatherRecords.total
      }
    } catch (error) {
      console.error(error)
      throw 'Sorry, there was a problem with your request.'
    }

    return weatherRecords
  }

  public async add(weatherRecord: WeatherRecordAddEvent): Promise<WeatherRecord> {
    const flowfeedApiQueryRequest = {
      endpoint: 'weather-record',
      data: weatherRecord,
    }

    const flowfeedApiQueryService = new FlowfeedApiQueryService()
    try {
      const weatherRecordRaw = await flowfeedApiQueryService.post<WeatherRecordDTO>(flowfeedApiQueryRequest)
      return WeatherRecordService.formatWeatherRecord(weatherRecordRaw)
    } catch (error) {
      console.error(error)
      if (error instanceof Error) {
        throw error.message
      } else if ((error as { data: { message: string } })?.data?.message) {
        throw (error as { data: { message: string } })?.data?.message
      }
      throw error
    }
  }

  public async update(id: number, weatherRecord: WeatherRecordUpdateEvent): Promise<WeatherRecord> {
    const flowfeedApiQueryRequest = {
      endpoint: `weather-record/${id}`,
      data: weatherRecord,
    }
    try {
      const flowfeedApiQueryService = new FlowfeedApiQueryService()
      const weatherRecordRaw = await flowfeedApiQueryService.patch<WeatherRecordDTO>(flowfeedApiQueryRequest)
      return WeatherRecordService.formatWeatherRecord(weatherRecordRaw)
    } catch (error) {
      console.error(error)
      if (error instanceof Error) {
        throw error.message
      } else if ((error as { data: { message: string } })?.data?.message) {
        throw (error as { data: { message: string } })?.data?.message
      }
      throw error
    }
  }

  public async delete(id: number): Promise<void> {
    const flowfeedApiQueryRequest = {
      endpoint: `weather-record/${id}`,
    }

    try {
      const flowfeedApiQueryService = new FlowfeedApiQueryService()
      await flowfeedApiQueryService.delete(flowfeedApiQueryRequest)
    } catch (error) {
      console.error(error)
      if (error instanceof Error) {
        throw error.message
      } else if ((error as { data: { message: string } })?.data?.message) {
        throw (error as { data: { message: string } })?.data?.message
      }
      throw error
    }
  }

  public async import(
    weatherRecords: (Partial<WeatherRecord> & { weatherGroupId?: number })[],
    options?: {
      matchingField?: string | undefined
      deleteUnmatchedExisting?: boolean
    },
  ): Promise<void> {
    const data = weatherRecords.map((weatherRecord) => {
      return {
        ...weatherRecord,
        id: weatherRecord.id ? Number(weatherRecord.id) : undefined,
        weatherGroupId: weatherRecord.weatherGroupId ? Number(weatherRecord.weatherGroupId) : undefined,
      }
    })
    const flowfeedApiQueryRequest = {
      endpoint: `weather-record/sync`,
      data: {
        data,
        matchingProp: options?.matchingField,
        deleteMissing: options?.deleteUnmatchedExisting,
      },
    }

    try {
      const flowfeedApiQueryService = new FlowfeedApiQueryService()
      await flowfeedApiQueryService.post(flowfeedApiQueryRequest)
    } catch (error) {
      console.error(error)
      if (error instanceof Error) {
        throw error.message
      } else if ((error as { data: { message: string } })?.data?.message) {
        throw (error as { data: { message: string } })?.data?.message
      }
      throw error
    }
  }

  public static formatWeatherRecord(weatherRecord: WeatherRecordDTO): WeatherRecord {
    return {
      id: weatherRecord.id,
      region: weatherRecord.region,
      weatherGroup: weatherRecord.weatherGroup
        ? WeatherGroupService.formatWeatherGroup(weatherRecord.weatherGroup)
        : undefined,
      weatherSource: weatherRecord.weatherSource,
      weatherSourceId: weatherRecord.weatherSourceId,
      type: weatherRecord.type,
      timestamp: new Date(weatherRecord.timestamp),
      temperature: weatherRecord.temperature,
      tempMin: weatherRecord.tempMin,
      tempMax: weatherRecord.tempMax,
      feelsLike: weatherRecord.feelsLike,
      pressure: weatherRecord.pressure,
      relativeHumidity: weatherRecord.relativeHumidity,
      precipLast1hr: weatherRecord.precipLast1hr,
      snowLast1hr: weatherRecord.snowLast1hr,
      windSpeed: weatherRecord.windSpeed,
      windDirection: weatherRecord.windDirection,
      uv: weatherRecord.uv,
      brightness: weatherRecord.brightness,
      sunrise: weatherRecord.sunrise ? new Date(weatherRecord.sunrise) : undefined,
      sunset: weatherRecord.sunset ? new Date(weatherRecord.sunset) : undefined,
      conditionId: weatherRecord.conditionId,
      updatedAt: new Date(weatherRecord.updatedAt),
      createdAt: new Date(weatherRecord.createdAt),
    }
  }
}

import { useEffect, useMemo, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import lodash from 'lodash'
import { SnackbarContext } from '../../../providers/SnackbarContext'

import { TrailFinderAnswer } from '../../../models/TrailFinderAnswer.model'
import { TrailFinderAnswerAddEvent } from '../../../events/TrailFinderAnswerAddEvent'
import { TrailFinderAnswerUpdateEvent } from '../../../events/TrailFinderAnswerUpdateEvent'
import { TrailFinderQuestion } from '../../../models/TrailFinderQuestion.model'
import { AffectedFilters } from '../../../models/AffectedFilters.model'
import { Difficulties } from '../../../models/Difficulties.model'
import { trailStatuses } from '../../../models/TrailStatuses.model'

import { TrailFinderQuestionService } from '../../../services/TrailFinderQuestionService/TrailFinderQuestionService'
import { TrailFinderAnswerService } from '../../../services/TrailFinderAnswerService/TrailFinderAnswerService'
import { TrailFeatureService } from '../../../services/TrailFeatureService/TrailFeatureService'
import { TrailService } from '../../../services/TrailService/TrailService'

import {
  Stack,
  FormControl,
  InputLabel,
  Button,
  Select,
  MenuItem,
  Typography,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import Loading from '../../../components/Loading/Loading.component'
import DetailInput from '../../../components/DetailInput/DetailInput.component'
import { AppStateContext } from '../../../providers/AppStateContext'

export type TrailFinderAnswerFormProps = {
  trailFinderAnswer?: TrailFinderAnswer
  trailFinderQuestion?: TrailFinderQuestion
  onAdd?: (trailFinderAnswer: TrailFinderAnswer) => void
  onEdit?: (trailFinderAnswer: TrailFinderAnswer) => void
  onCancel: () => void
  // used to remotely trigger the save action
  saveTrigger?: number
}
const TrailFinderAnswerForm = (props: TrailFinderAnswerFormProps) => {
  const { trailFinderAnswer, trailFinderQuestion, onCancel, onAdd, onEdit, saveTrigger } = props

  const [tempTrailFinderAnswer, setTempTrailFinderAnswer] = useState<Partial<TrailFinderAnswer>>(
    trailFinderAnswer || {},
  )
  const [isLoading, setIsLoading] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [tempTrailFinderQuestion, setTempTrailFinderQuestion] = useState<TrailFinderQuestion | undefined>(
    trailFinderQuestion,
  )

  const [tempDifficulties, setTempDifficulties] = useState<NonNullable<AffectedFilters['difficulties']>>(
    trailFinderAnswer?.affectedFilters?.difficulties || [],
  )
  const [tempRadius, setTempRadius] = useState<NonNullable<AffectedFilters['radius']>>(
    trailFinderAnswer?.affectedFilters?.radius || [],
  )
  const [tempFeatures, setTempFeatures] = useState<NonNullable<AffectedFilters['features']>>(
    trailFinderAnswer?.affectedFilters?.features || [],
  )
  const [tempStatuses, setTempStatuses] = useState<NonNullable<AffectedFilters['statuses']>>(
    trailFinderAnswer?.affectedFilters?.statuses || [],
  )
  const [tempSurfaceTypes, setTempSurfaceTypes] = useState<NonNullable<AffectedFilters['surfaceTypes']>>(
    trailFinderAnswer?.affectedFilters?.surfaceTypes || [],
  )

  const queryClient = useQueryClient()

  const trailFinderQuestionService = useMemo(() => new TrailFinderQuestionService(), []) // memo to prevent re-creating service on every render
  const { isLoading: isLoadingTrailFinderQuestions, data: trailFinderQuestionsResponse } = useQuery(
    ['TrailFinderQuestionService.get', { pageSize: 0 }, AppStateContext.getRegion()],
    () => trailFinderQuestionService.search({ pageSize: 0 }),
    {
      // not allowed to edit the question if editing an answer or if a question was specified
      enabled: !trailFinderAnswer && !tempTrailFinderQuestion ? true : false,
      onError: (err) => {
        SnackbarContext.show(
          `Failed to fetch trail finder questions for the form: ${typeof err === 'string' ? err : ''}`,
          'error',
        )
        console.error(err)
      },
    },
  )

  const trailService = useMemo(() => new TrailService(), []) // memo to prevent re-creating service on every render
  const { isLoading: isLoadingSurfaceTypes, data: surfaceTypes } = useQuery(
    ['TrailService.listSurfaceTypes', AppStateContext.getRegion()],
    () => trailService.listSurfaceTypes(),
    {
      onError: (err) => {
        SnackbarContext.show(
          `Failed to fetch surface types for the form: ${typeof err === 'string' ? err : ''}`,
          'error',
        )
        console.error(err)
      },
    },
  )

  const trailFeatureService = useMemo(() => new TrailFeatureService(), []) // memo to prevent re-creating service on every render
  const { isLoading: isLoadingTrailFeatures, data: trailFeaturesResponse } = useQuery(
    ['TrailFeatureService.search', { pageSize: 0 }, AppStateContext.getRegion()],
    () => trailFeatureService.search({ pageSize: 0 }),
    {
      onError: (err) => {
        SnackbarContext.show(
          `Failed to fetch trail features for the form: ${typeof err === 'string' ? err : ''}`,
          'error',
        )
        console.error(err)
      },
    },
  )

  // consolidate the various loading states into one
  useEffect(() => {
    const shouldBeFetchingTrailFinderQuestions = !trailFinderAnswer && !tempTrailFinderQuestion ? true : false
    setIsLoading(
      ((shouldBeFetchingTrailFinderQuestions && !isLoadingTrailFinderQuestions) ||
        !shouldBeFetchingTrailFinderQuestions) &&
        !isLoadingTrailFeatures &&
        !isLoadingSurfaceTypes
        ? false
        : true,
    )
  }, [
    trailFinderAnswer,
    tempTrailFinderQuestion,
    isLoadingTrailFinderQuestions,
    isLoadingTrailFeatures,
    isLoadingSurfaceTypes,
  ])

  const trailFinderAnswerService = useMemo(() => new TrailFinderAnswerService(), []) // memo to prevent re-creating service on every render

  const addTrailFinderAnswer = useMutation(
    (mutationParams: { trailFinderAnswer: TrailFinderAnswerAddEvent }) => {
      setIsSubmitting(true)
      return trailFinderAnswerService.add(mutationParams.trailFinderAnswer)
    },
    {
      onSuccess: (result) => {
        SnackbarContext.show('Trail Finder Answer added successfully!')

        // invalidate all other queries to ensure any queries that are using the modified record are updated
        queryClient.invalidateQueries()
        setIsSubmitting(false)

        if (typeof onAdd === 'function') {
          onAdd(result)
        }
      },
      onError: (err) => {
        SnackbarContext.show(`Trail Finder Answer failed to add: ${err}`, 'error')
        console.error(err)
        setIsSubmitting(false)
      },
    },
  )

  const updateTrailFinderAnswer = useMutation(
    (mutationParams: { id: number; changedProperties: TrailFinderAnswerUpdateEvent }) => {
      setIsSubmitting(true)
      return trailFinderAnswerService.update(mutationParams.id, mutationParams.changedProperties)
    },
    {
      onSuccess: (result) => {
        SnackbarContext.show('Trail Finder Answer updated successfully!')

        // invalidate all queries to ensure any queries that are using the modified record are updated
        queryClient.invalidateQueries()
        setIsSubmitting(false)

        if (typeof onEdit === 'function') {
          onEdit(result)
        }
      },
      onError: (err) => {
        SnackbarContext.show(`Trail Finder Answer failed to update: ${err}`, 'error')
        console.error(err)
        setIsSubmitting(false)
      },
    },
  )

  const handleSubmit = () => {
    if (!tempTrailFinderAnswer.answer) {
      SnackbarContext.show(`Trail Finder Answer answer text is required`, 'error')
      return
    }

    setIsSubmitting(true)

    const newAffectedFilters: AffectedFilters = {}
    if (tempDifficulties.length > 0) {
      newAffectedFilters.difficulties = tempDifficulties
    }
    if (tempRadius.length > 0) {
      newAffectedFilters.radius = tempRadius
    }
    if (tempFeatures.length > 0) {
      newAffectedFilters.features = tempFeatures
    }
    if (tempStatuses.length > 0) {
      newAffectedFilters.statuses = tempStatuses
    }
    if (tempSurfaceTypes.length > 0) {
      newAffectedFilters.surfaceTypes = tempSurfaceTypes
    }

    if (!trailFinderAnswer) {
      // only support question assignment when adding a new record
      if (!tempTrailFinderQuestion?.id) {
        SnackbarContext.show(`Trail Finder Answer question assignment is required`, 'error')
        return
      }

      // format the data to match the API
      const formattedTrailFinderAnswer: TrailFinderAnswerAddEvent = {
        answer: tempTrailFinderAnswer.answer,
        affectedFilters: Object.keys(newAffectedFilters).length > 0 ? newAffectedFilters : null,
        questionId: tempTrailFinderQuestion.id,
        sortWeight: tempTrailFinderAnswer.sortWeight,
        active: tempTrailFinderAnswer.active,
      }

      // adding a new record
      addTrailFinderAnswer.mutate({ trailFinderAnswer: formattedTrailFinderAnswer })
    } else {
      // identify the properties that have changed
      const didChange = (field: keyof TrailFinderAnswer) => {
        return tempTrailFinderAnswer[field] !== undefined && tempTrailFinderAnswer[field] !== trailFinderAnswer[field]
      }

      const changedProperties: TrailFinderAnswerUpdateEvent = {
        id: trailFinderAnswer.id,
        answer: didChange('answer') ? tempTrailFinderAnswer.answer : undefined,
        affectedFilters: !lodash.isEqual(newAffectedFilters, trailFinderAnswer.affectedFilters)
          ? Object.keys(newAffectedFilters).length > 0
            ? newAffectedFilters
            : null
          : undefined,
        sortWeight: didChange('sortWeight') ? tempTrailFinderAnswer.sortWeight : undefined,
        active: didChange('active') ? tempTrailFinderAnswer.active : undefined,
      }

      // edit an existing record
      if (!Object.keys(changedProperties).filter((key) => key !== 'id').length) {
        // nothing changed so just close the dialog
        onCancel()
      } else {
        updateTrailFinderAnswer.mutate({ id: trailFinderAnswer.id, changedProperties })
      }
    }
  }

  useEffect(() => {
    if (saveTrigger) {
      // if outside consumer triggered save, then submit
      handleSubmit()
    }
  }, [saveTrigger])

  const handleCancel = () => {
    if (typeof onCancel === 'function') {
      onCancel()
    }
  }

  const keyDownSubmit = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSubmit()
    }
  }

  const handleToggleSurfaceType = (surfaceType: string) => () => {
    const currentIndex = tempSurfaceTypes.indexOf(surfaceType)
    const newChecked = [...tempSurfaceTypes]

    if (currentIndex === -1) {
      newChecked.push(surfaceType)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setTempSurfaceTypes(newChecked)
  }

  const handleToggleStatus = (status: 0 | 1 | 2 | 3) => () => {
    const currentIndex = tempStatuses.indexOf(status)
    const newChecked = [...tempStatuses]

    if (currentIndex === -1) {
      newChecked.push(status)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setTempStatuses(newChecked)
  }

  const handleToggleTrailFeature = (trailFeatureId: number) => () => {
    const currentIndex = tempFeatures.indexOf(trailFeatureId)
    const newChecked = [...tempFeatures]

    if (currentIndex === -1) {
      newChecked.push(trailFeatureId)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setTempFeatures(newChecked)
  }

  const handleToggleRadius = (radius: number) => () => {
    const currentIndex = tempRadius.indexOf(radius)
    const newChecked = [...tempRadius]

    if (currentIndex === -1) {
      newChecked.push(radius)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setTempRadius(newChecked)
  }

  const handleToggleDifficulty = (difficulty: Difficulties) => () => {
    const currentIndex = tempDifficulties.indexOf(difficulty)
    const newChecked = [...tempDifficulties]

    if (currentIndex === -1) {
      newChecked.push(difficulty)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setTempDifficulties(newChecked)
  }

  return (
    <>
      {(isSubmitting || isLoading) && <Loading sx={{ py: 20 }} />}
      {!isSubmitting && !isLoading && (
        <>
          <Stack spacing={2} sx={{ my: 2 }}>
            {!trailFinderAnswer && !trailFinderQuestion ? (
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="trail-finder-answer-question-select-label">Trail Finder Question</InputLabel>
                <Select
                  labelId="trail-finder-answer-question-select-label"
                  id="trail-finder-answer-question-select"
                  value={tempTrailFinderQuestion ? tempTrailFinderQuestion.id : ''}
                  label="Trail Finder Question"
                  onChange={(e) => {
                    setTempTrailFinderQuestion(
                      trailFinderQuestionsResponse?.data.find(
                        (eachTrailFinderQuestion) => eachTrailFinderQuestion.id === e.target.value,
                      ),
                    )
                  }}
                >
                  <MenuItem value="">-Select-</MenuItem>
                  {trailFinderQuestionsResponse?.data.map((eachTrailFinderQuestion) => {
                    return (
                      <MenuItem key={eachTrailFinderQuestion.id} value={eachTrailFinderQuestion.id}>
                        {eachTrailFinderQuestion.question}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            ) : (
              <Typography variant="h6">Answer for question: {tempTrailFinderQuestion?.question}</Typography>
            )}
            <DetailInput<TrailFinderAnswer>
              label="Answer"
              field="answer"
              tempDetail={tempTrailFinderAnswer}
              setTempDetail={setTempTrailFinderAnswer}
              keyDownSubmit={keyDownSubmit}
            />
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="trail-finder-answer-affected-difficulties-select-label" shrink>
                Affected Difficulties
              </InputLabel>
              <List sx={{ width: '100%', maxHeight: 600, overflow: 'auto', bgcolor: 'background.paper' }}>
                {Object.entries(Difficulties).map(([difficultyName, difficultyCode]) => (
                  <ListItem key={difficultyCode} disablePadding>
                    <ListItemButton role={undefined} onClick={handleToggleDifficulty(difficultyCode)} dense>
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={tempDifficulties.indexOf(difficultyCode) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{
                            'aria-labelledby': `trail-finder-answer-affected-difficulties-checkbox-list-label-${difficultyCode}`,
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        id={`trail-finder-answer-affected-difficulties-checkbox-list-label-${difficultyCode}`}
                        primary={difficultyName}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </FormControl>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="trail-finder-answer-affected-radius-select-label" shrink>
                Affected Radius
              </InputLabel>
              <List sx={{ width: '100%', maxHeight: 600, overflow: 'auto', bgcolor: 'background.paper' }}>
                {[5, 10, 25, 50, 100].map((radius) => (
                  <ListItem key={radius} disablePadding>
                    <ListItemButton role={undefined} onClick={handleToggleRadius(radius)} dense>
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={tempRadius.indexOf(radius) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{
                            'aria-labelledby': `trail-finder-answer-affected-radius-checkbox-list-label-${radius}`,
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        id={`trail-finder-answer-affected-radius-checkbox-list-label-${radius}`}
                        primary={`${radius} Miles`}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </FormControl>
            {trailFeaturesResponse?.data && trailFeaturesResponse.data.length > 0 && (
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="trail-finder-answer-affected-features-select-label" shrink>
                  Affected Trail Features
                </InputLabel>
                <List sx={{ width: '100%', maxHeight: 600, overflow: 'auto', bgcolor: 'background.paper' }}>
                  {trailFeaturesResponse.data.map((trailFeature) => (
                    <ListItem key={trailFeature.id} disablePadding>
                      <ListItemButton role={undefined} onClick={handleToggleTrailFeature(trailFeature.id)} dense>
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={tempFeatures.indexOf(trailFeature.id) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{
                              'aria-labelledby': `trail-finder-answer-affected-features-checkbox-list-label-${trailFeature.id}`,
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          id={`trail-finder-answer-affected-features-checkbox-list-label-${trailFeature.id}`}
                          primary={trailFeature.name}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </FormControl>
            )}
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="trail-finder-answer-affected-statuses-select-label" shrink>
                Affected Statuses
              </InputLabel>
              <List sx={{ width: '100%', maxHeight: 600, overflow: 'auto', bgcolor: 'background.paper' }}>
                {trailStatuses.map(({ name: statusName, id: statusId }) => (
                  <ListItem key={statusId} disablePadding>
                    <ListItemButton role={undefined} onClick={handleToggleStatus(statusId as 0 | 1 | 2 | 3)} dense>
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={tempStatuses.indexOf(statusId as 0 | 1 | 2 | 3) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{
                            'aria-labelledby': `trail-finder-answer-affected-statuses-checkbox-list-label-${statusId}`,
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        id={`trail-finder-answer-affected-statuses-checkbox-list-label-${statusId}`}
                        primary={statusName}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </FormControl>
            {surfaceTypes && surfaceTypes.length > 0 && (
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="trail-finder-answer-affected-surface-type-select-label" shrink>
                  Affected Surface Types
                </InputLabel>
                <List sx={{ width: '100%', maxHeight: 600, overflow: 'auto', bgcolor: 'background.paper' }}>
                  {surfaceTypes.map((surfaceType, surfaceTypeIndex) => (
                    <ListItem key={surfaceType} disablePadding>
                      <ListItemButton role={undefined} onClick={handleToggleSurfaceType(surfaceType)} dense>
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={tempSurfaceTypes.indexOf(surfaceType) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{
                              'aria-labelledby': `trail-finder-answer-affected-surface-type-checkbox-list-label-${surfaceTypeIndex}`,
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          id={`trail-finder-answer-affected-surface-type-checkbox-list-label-${surfaceTypeIndex}`}
                          primary={surfaceType}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </FormControl>
            )}
            <DetailInput<TrailFinderAnswer>
              label="Sort Weight"
              field="sortWeight"
              type="number"
              tempDetail={tempTrailFinderAnswer}
              setTempDetail={setTempTrailFinderAnswer}
              keyDownSubmit={keyDownSubmit}
            />
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="trail-finder-answer-active-select-label">Active</InputLabel>
              <Select
                labelId="trail-finder-answer-active-select-label"
                id="trail-finder-answer-active-select"
                value={tempTrailFinderAnswer.active === false ? 'false' : 'true'}
                label="Active"
                onChange={(e) => {
                  setTempTrailFinderAnswer((prev) => ({ ...prev, active: e.target.value === 'false' ? false : true }))
                }}
              >
                <MenuItem value="true">True</MenuItem>
                <MenuItem value="false">False</MenuItem>
              </Select>
            </FormControl>
          </Stack>
          <Stack direction="row" justifyContent="end" alignItems="center" spacing={2}>
            <Button variant="outlined" onClick={handleCancel}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSubmit}>
              Save
            </Button>
          </Stack>
        </>
      )}
    </>
  )
}
export default TrailFinderAnswerForm

import { AppRegions } from './AppRegions.model'
import { WeatherGroupTypes } from './WeatherGroupTypes.model'
import { WeatherSources } from './WeatherSources.model'

export type WeatherGroup = {
  id: number
  name: string
  region: AppRegions
  weatherSource: WeatherSources
  weatherSourceId: string
  type: WeatherGroupTypes
  updatedAt: Date
  createdAt: Date
}

// we offer this to enable the enumerate the WeatherGroup type's fields in js/ts files
export const weatherGroupFields: (keyof WeatherGroup)[] = [
  'id',
  'name',
  'region',
  'weatherSource',
  'weatherSourceId',
  'type',
  'updatedAt',
  'createdAt',
]

import dayjs from 'dayjs'

import { IconButton, Stack } from '@mui/material'
import { LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers'
import CloseIcon from '@mui/icons-material/Close'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

type DateTimePickerProps = {
  label: string
  value: Date | dayjs.Dayjs | null | undefined
  onChange: (newValue: dayjs.Dayjs | null) => void
}
const DateTimePicker = (props: DateTimePickerProps): JSX.Element => {
  const { label, value, onChange } = props

  const handleClear = () => {
    onChange(null)
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack direction="row" alignItems="center">
        <MobileDateTimePicker<dayjs.Dayjs>
          label={label}
          value={dayjs.isDayjs(value) ? value : value instanceof Date ? dayjs(value.toISOString()) : null}
          onChange={onChange}
          sx={{ width: '100%' }}
        />
        {value && (
          <IconButton
            aria-label="clear date"
            onClick={() => {
              handleClear()
            }}
            sx={{ ml: '-50px' }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </Stack>
    </LocalizationProvider>
  )
}
export default DateTimePicker

import { useAuth0 } from '@auth0/auth0-react'
import { Stack } from '@mui/material'

const Logout = (): JSX.Element => {
  const { logout } = useAuth0()

  logout({ logoutParams: { returnTo: window.location.origin } })

  return (
    <Stack direction="row" alignItems="center" justifyContent="center" sx={{ width: '100%', height: '100%' }}>
      Logging out...
    </Stack>
  )
}

export default Logout

import { useEffect, useMemo, useState } from 'react'
import { SnackbarContext } from '../../providers/SnackbarContext'

import { Trail } from '../../models/Trail.model'
import { TrailSystemRelations } from '../../models/TrailSystemRelations.model'
import { TrailSystem } from '../../models/TrailSystem.model'

import { TrailSystemService } from '../../services/TrailSystemService/TrailSystemService'

import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { List, ListItemButton, ListItemText, Collapse, ListItem, ListItemIcon, Checkbox, Box } from '@mui/material'
import Loading from '../Loading/Loading.component'

type TrailPickerProps = {
  selectedTrails: Trail[]
  setSelectedTrails: (selectedTrails: Trail[]) => void
  setSelectedTrailSystems?: (selectedTrailSystems: TrailSystem[]) => void
  trailSystem?: TrailSystem
}
const TrailPicker = (props: TrailPickerProps) => {
  const {
    selectedTrails: externalSelectedTrails,
    setSelectedTrails: externalSetSelectedTrails,
    setSelectedTrailSystems: externalSetSelectedTrailSystems,
    trailSystem,
  } = props

  const [isLoading, setIsLoading] = useState(true)
  const [openSystems, setOpenSystems] = useState<Record<string, boolean>>({})
  const [selectedTrails, setSelectedTrails] = useState<Trail[]>(externalSelectedTrails)
  const [trailSystems, setTrailSystems] = useState<TrailSystem[]>([])

  const trailSystemService = useMemo(() => new TrailSystemService(), []) // memo to prevent re-creating service on every render

  useEffect(() => {
    trailSystemService
      .search({
        id: trailSystem?.id, // if a trail system is specified, only fetch that one
        pageSize: 0,
        orderBy: 'name',
        orderDirection: 'ASC',
        relations: [TrailSystemRelations.trails],
      })
      .then((trailSystemResults) => {
        setTrailSystems(trailSystemResults.data)
        setIsLoading(false)
      })
      .catch((err) => {
        SnackbarContext.show(`Failed to fetch trail options for the trail picker: ${err}`, 'error')
        console.error(err)
      })
  }, [])

  useEffect(() => {
    externalSetSelectedTrails(selectedTrails)
    if (typeof externalSetSelectedTrailSystems === 'function') {
      // check if all trails in each trail system are selected and update the selected trail systems accordingly
      const newSelectedTrailSystems = trailSystems.map((trailSystem) => {
        if (!trailSystem.trails?.length) return trailSystem // skip if there are no trails in this system (shouldn't happen but typescript needs this to be happy)
        // identify the trails in this system that are selected
        const selectedSystemTrails = trailSystem.trails.filter((trail) =>
          selectedTrails.map((each) => each.id).includes(trail.id),
        )
        // if all trails in this system are selected, add the system to the selected trail systems
        if (selectedSystemTrails.length === trailSystem.trails.length) {
          return trailSystem
        }
        // otherwise remove the system from the selected trail systems
        return undefined
      })
      externalSetSelectedTrailSystems(
        // we have to assert here because we know that the filter will remove all undefined values but typescript doesn't get the point
        newSelectedTrailSystems.filter((each) => each !== undefined) as TrailSystem[],
      )
    }
  }, [selectedTrails])

  const handleToggleTrailSystem = (trailSystem: TrailSystem, selectedSystemTrails: Trail[]) => () => {
    if (!trailSystem.trails?.length) return // check for typescript happiness even though it should always be true

    if (selectedSystemTrails.length === trailSystem.trails.length) {
      // if all trails in this system are selected, remove them all
      const trailsToUncheck = selectedSystemTrails.map((trail) => trail.id)
      // remove the trails from the selected trails
      setSelectedTrails((prev) => prev.filter((trail) => !trailsToUncheck.includes(trail.id)))
    } else {
      // if some or none of the trails in this system are selected, add all trails not already selected
      const trailsToCheck = trailSystem.trails.filter(
        (trail) => !selectedTrails.map((each) => each.id).includes(trail.id), // filter out trails that are already selected
      )
      // add the trails to the selected trails
      setSelectedTrails([...selectedTrails, ...trailsToCheck])
    }
  }

  const handleToggleTrail = (trail: Trail) => () => {
    const currentIndex = selectedTrails.map((each) => each.id).indexOf(trail.id)
    const newChecked = [...selectedTrails]

    if (currentIndex === -1) {
      newChecked.push(trail)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setSelectedTrails(newChecked)
  }

  const handleSystemClick = (id: number) => () => {
    setOpenSystems((prevOpen) => ({
      ...prevOpen,
      [id]: prevOpen[id] === undefined ? true : !prevOpen[id],
    }))
  }

  return (
    <>
      {isLoading && <Loading sx={{ py: 20 }} />}
      {!isLoading && (
        <List sx={{ width: '100%', maxHeight: 600, overflow: 'auto', bgcolor: 'background.paper' }}>
          {trailSystems.map((trailSystem) => {
            if (!trailSystem.trails) return null // skip if there are no trails in this system (shouldn't happen but typescript needs this to be happy)
            // identify the trails in this system that are selected
            const selectedSystemTrails = trailSystem.trails.filter((trail) =>
              selectedTrails.map((each) => each.id).includes(trail.id),
            )
            return (
              <Box key={trailSystem.id}>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <Checkbox
                      checked={selectedSystemTrails.length === trailSystem.trails.length}
                      indeterminate={
                        selectedSystemTrails.length > 0 && selectedSystemTrails.length !== trailSystem.trails.length
                      }
                      onClick={handleToggleTrailSystem(trailSystem, selectedSystemTrails)}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': `trail-system-checkbox-list-label-${trailSystem.id}` }}
                    />
                  </ListItemIcon>
                  <ListItemButton onClick={handleSystemClick(trailSystem.id)}>
                    <ListItemText
                      primary={`${
                        trailSystem.name
                      } ${`(${selectedSystemTrails.length}/${trailSystem.trails.length} selected)`}`}
                      id={`trail-system-checkbox-list-label-${trailSystem.id}`}
                    />
                    {openSystems[trailSystem.id] ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <Collapse in={openSystems[trailSystem.id]} timeout="auto" sx={{ width: '100%' }} unmountOnExit>
                    <List disablePadding sx={{ pl: 2 }}>
                      {trailSystem.trails.map((trail) => (
                        <ListItem key={trail.id} disablePadding>
                          <ListItemButton role={undefined} onClick={handleToggleTrail(trail)} dense>
                            <ListItemIcon>
                              <Checkbox
                                edge="start"
                                checked={selectedTrails.map((each) => each.id).indexOf(trail.id) !== -1}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ 'aria-labelledby': `trail-checkbox-list-label-${trail.id}` }}
                              />
                            </ListItemIcon>
                            <ListItemText id={`trail-checkbox-list-label-${trail.id}`} primary={trail.name} />
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                </ListItem>
              </Box>
            )
          })}
        </List>
      )}
    </>
  )
}
export default TrailPicker

import { Box } from '@mui/material'

type FileInputProps = {
  onFileChange: (file: File | null) => void
  name?: string
}
const FileInput = (props: FileInputProps): JSX.Element => {
  const { name, onFileChange } = props

  const uploadFile = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const file = event?.target?.files?.[0] || null
    onFileChange(file)
  }

  return (
    <Box>
      <input type="file" name={name} onChange={uploadFile} />
    </Box>
  )
}
export default FileInput

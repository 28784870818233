import { Box, Button, Dialog } from '@mui/material'
import Typography from '@mui/material/Typography'
import { Stack } from '@mui/system'
import { useEffect, useState } from 'react'

export const AppUpdatePrompter = () => {
  const [isOpen, setIsOpen] = useState(true)
  const [countdown, setCountdown] = useState(20)
  const [reloadInterval, setReloadInterval] = useState<NodeJS.Timeout>()

  useEffect(() => {
    // setup a countdown timer to show on the update prompt. After 20 seconds, automatically refresh the page
    const newReloadInterval = setInterval(() => {
      setCountdown((prev) => {
        const newCountdown = prev - 1
        if (newCountdown <= 0) {
          window.location.reload()
        }
        return newCountdown
      })
    }, 1000)
    setReloadInterval(newReloadInterval)
    // clear out the interval when unmounting the component
    return () => clearInterval(newReloadInterval)
  }, [])

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        // cancel the interval
        clearInterval(reloadInterval)
      }}
    >
      <Box sx={{ p: 2 }}>
        <Typography variant="h4" component="h1" sx={{ mb: 2 }}>
          App Update Required
        </Typography>
        <Box>Please refresh your app to fetch the latest version.</Box>
        <Box sx={{ mb: 2 }}>This page will auto-refresh in {countdown} seconds.</Box>
        <Stack direction="row" justifyContent="end">
          <Button
            onClick={() => {
              // cancel the interval
              clearInterval(reloadInterval)

              setIsOpen(false)
            }}
          >
            Cancel
          </Button>
          <Button onClick={() => window.location.reload()}>Refresh</Button>
        </Stack>
      </Box>
    </Dialog>
  )
}

import { useEffect, useMemo, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import camelCase from 'lodash/camelCase'
import startCase from 'lodash/startCase'
import { SnackbarContext } from '../../../providers/SnackbarContext'

import { WeatherGroup } from '../../../models/WeatherGroup.model'
import { WeatherGroupAddEvent } from '../../../events/WeatherGroupAddEvent'
import { WeatherGroupUpdateEvent } from '../../../events/WeatherGroupUpdateEvent'

import { WeatherGroupService } from '../../../services/WeatherGroupService/WeatherGroupService'

import { Stack, FormControl, InputLabel, OutlinedInput, Button, Select, MenuItem } from '@mui/material'
import Loading from '../../../components/Loading/Loading.component'
import { WeatherSources } from '../../../models/WeatherSources.model'
import { WeatherGroupTypes } from '../../../models/WeatherGroupTypes.model'
import DetailInput from '../../../components/DetailInput/DetailInput.component'
import { useAppNavigate } from '../../../utils/url'

export type WeatherGroupFormProps = {
  weatherGroup?: WeatherGroup
  onAdd?: (weatherGroup: WeatherGroup) => void
  onEdit?: (weatherGroup: WeatherGroup) => void
  onCancel: () => void
  // used to remotely trigger the save action
  saveTrigger?: number
  disableRedirect?: boolean
}
const WeatherGroupForm = (props: WeatherGroupFormProps) => {
  const { weatherGroup, onCancel, onAdd, onEdit, saveTrigger, disableRedirect = false } = props

  const navigate = useAppNavigate()

  const [tempWeatherGroup, setTempWeatherGroup] = useState<Partial<WeatherGroup>>(weatherGroup || {})
  const [isSubmitting, setIsSubmitting] = useState(false)

  const weatherGroupService = useMemo(() => new WeatherGroupService(), []) // memo to prevent re-creating service on every render

  const queryClient = useQueryClient()

  const addWeatherGroup = useMutation(
    (mutationParams: { weatherGroup: WeatherGroupAddEvent }) => {
      setIsSubmitting(true)
      return weatherGroupService.add(mutationParams.weatherGroup)
    },
    {
      onSuccess: (result) => {
        SnackbarContext.show('Weather Group added successfully!')

        if (!disableRedirect) {
          // redirect to the new weather group
          navigate(`/weather-groups/${result.id}`)
          // remove the search query to prevent auto refresh of the search results in case we are on that page and redirecting
          queryClient.removeQueries(['WeatherGroupService.search'])
        }
        // invalidate all other queries to ensure any queries that are using the modified record are updated
        queryClient.invalidateQueries()
        setIsSubmitting(false)

        if (typeof onAdd === 'function') {
          onAdd(result)
        }
      },
      onError: (err) => {
        SnackbarContext.show(`Weather Group failed to add: ${err}`, 'error')
        console.error(err)
        setIsSubmitting(false)
      },
    },
  )

  const updateWeatherGroup = useMutation(
    (mutationParams: { id: number; changedProperties: WeatherGroupUpdateEvent }) => {
      setIsSubmitting(true)
      return weatherGroupService.update(mutationParams.id, mutationParams.changedProperties)
    },
    {
      onSuccess: (result) => {
        SnackbarContext.show('Weather Group updated successfully!')

        // invalidate all queries to ensure any queries that are using the modified record are updated
        queryClient.invalidateQueries()
        setIsSubmitting(false)

        if (typeof onEdit === 'function') {
          onEdit(result)
        }
      },
      onError: (err) => {
        SnackbarContext.show(`Weather Group failed to update: ${err}`, 'error')
        console.error(err)
        setIsSubmitting(false)
      },
    },
  )

  const handleSubmit = () => {
    if (!tempWeatherGroup.name) {
      SnackbarContext.show(`Weather Group name is required`, 'error')
      return
    }
    if (!tempWeatherGroup.weatherSource) {
      SnackbarContext.show(`Weather Group weatherSource is required`, 'error')
      return
    }
    if (!tempWeatherGroup.weatherSourceId) {
      SnackbarContext.show(`Weather Group weatherSourceId is required`, 'error')
      return
    }
    if (!tempWeatherGroup.type) {
      SnackbarContext.show(`Weather Group type is required`, 'error')
      return
    }

    if (!weatherGroup) {
      // format the data to match the API
      const formattedWeatherGroup: WeatherGroupAddEvent = {
        name: tempWeatherGroup.name,
        weatherSource: tempWeatherGroup.weatherSource,
        weatherSourceId: tempWeatherGroup.weatherSourceId,
        type: tempWeatherGroup.type,
      }

      // adding a new weatherGroup
      addWeatherGroup.mutate({ weatherGroup: formattedWeatherGroup })
    } else {
      // identify the properties that have changed
      const didChange = (field: keyof WeatherGroup) => {
        return tempWeatherGroup[field] !== undefined && tempWeatherGroup[field] !== weatherGroup[field]
      }

      const changedProperties: WeatherGroupUpdateEvent = {
        id: weatherGroup.id,
        name: didChange('name') ? tempWeatherGroup.name : undefined,
        weatherSource: didChange('weatherSource') ? tempWeatherGroup.weatherSource : undefined,
        weatherSourceId: didChange('weatherSourceId') ? tempWeatherGroup.weatherSourceId : undefined,
        type: didChange('type') ? tempWeatherGroup.type : undefined,
      }

      // edit an existing weatherGroup
      if (!Object.keys(changedProperties).filter((key) => key !== 'id').length) {
        // nothing changed so just close the dialog
        onCancel()
      } else {
        // edit an existing weatherGroup
        updateWeatherGroup.mutate({ id: weatherGroup.id, changedProperties })
      }
    }
  }

  useEffect(() => {
    if (saveTrigger) {
      // if outside consumer triggered save, then submit
      handleSubmit()
    }
  }, [saveTrigger])

  const handleCancel = () => {
    if (typeof onCancel === 'function') {
      onCancel()
    }
  }

  const keyDownSubmit = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSubmit()
    }
  }

  return (
    <>
      {isSubmitting && <Loading sx={{ py: 20 }} />}
      {!isSubmitting && (
        <>
          <Stack spacing={2} sx={{ my: 2 }}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Name</InputLabel>
              <OutlinedInput
                id="weatherGroup-name"
                label="Name"
                value={tempWeatherGroup.name || ''}
                required
                onChange={(e) => {
                  setTempWeatherGroup((prev) => ({ ...prev, name: e.target.value }))
                }}
                onKeyDown={keyDownSubmit}
              />
            </FormControl>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="weather-record-weather-source-select-label">Weather Source</InputLabel>
              <Select
                labelId="weather-record-weather-source-select-label"
                id="weather-record-weather-source-select"
                value={tempWeatherGroup.weatherSource || ''}
                label="Weather Source"
                onChange={(e) => {
                  setTempWeatherGroup((prev) => ({ ...prev, weatherSource: e.target.value as WeatherSources }))
                }}
              >
                {Object.entries(WeatherSources).map((eachWeatherSource) => {
                  return (
                    <MenuItem key={eachWeatherSource[1]} value={eachWeatherSource[1]}>
                      {startCase(camelCase(eachWeatherSource[0]))}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
            <DetailInput<WeatherGroup>
              label="Weather Source Id"
              field="weatherSourceId"
              tempDetail={tempWeatherGroup}
              setTempDetail={setTempWeatherGroup}
              keyDownSubmit={keyDownSubmit}
            />
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="weather-record-type-select-label">Type</InputLabel>
              <Select
                labelId="weather-record-type-select-label"
                id="weather-record-type-select"
                value={tempWeatherGroup.type || ''}
                label="State"
                onChange={(e) => {
                  setTempWeatherGroup((prev) => ({ ...prev, type: e.target.value as WeatherGroupTypes }))
                }}
              >
                {Object.entries(WeatherGroupTypes).map((eachWeatherGroupType) => {
                  return (
                    <MenuItem key={eachWeatherGroupType[1]} value={eachWeatherGroupType[1]}>
                      {startCase(camelCase(eachWeatherGroupType[0]))}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Stack>
          <Stack direction="row" justifyContent="end" alignItems="center" spacing={2}>
            <Button variant="outlined" onClick={handleCancel}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSubmit}>
              Save
            </Button>
          </Stack>
        </>
      )}
    </>
  )
}
export default WeatherGroupForm

import { forwardRef } from 'react'
import { createTheme, LinkProps } from '@mui/material'
import { grey } from '@mui/material/colors'
import { renderTimeViewClock } from '@mui/x-date-pickers'
import { AppLinkProps, AppLink } from './utils/url'
declare module '@mui/material/styles' {
  interface Palette {
    gradient: Palette['primary']
    gradientLight: Palette['primary']
  }

  interface PaletteOptions {
    gradient: PaletteOptions['primary']
    gradientLight: PaletteOptions['primary']
  }

  interface BreakpointOverrides {
    zero: true // custom breakpoint
    xxxs: true // custom breakpoint
    xxs: true // custom breakpoint
    xs: true
    sm: true
    md: true
    lg: true
    xl: true
  }
}

declare module '@mui/material/styles' {
  interface TypographyOptions {
    letterSpacing?: number
  }

  interface TypographyVariants {
    error: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    error?: React.CSSProperties
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    error: true
  }
}

// Updates MUI links and buttons to use react-router instead of standard anchor tags
const LinkBehavior = forwardRef<HTMLAnchorElement, Omit<AppLinkProps, 'to'> & { href: AppLinkProps['to'] }>(
  (props, ref) => {
    const { href, ...other } = props
    // Map href (MUI) -> to (react-router)
    return <AppLink ref={ref} to={href} {...other} />
  },
)

let AppTheme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        html, body, p, h1, h2, h3, h4, h5, h6, span, button, input, textarea {
          letter-spacing: 0.1rem;
        }
      `,
    },
    MuiTypography: {
      // set the default letter spacing for all typography components to 0.1rem
      styleOverrides: {
        root: {
          letterSpacing: '0.1rem',
        },
      },
    },
  },
  palette: {
    mode: 'dark',
    primary: { main: '#ff7900', contrastText: '#000' },
    secondary: { main: grey[900], contrastText: '#fff' },
    success: { main: '#8ec666', contrastText: '#000' },
    warning: { main: '#ffc05f', contrastText: '#000' },
    error: { main: '#ec6458', contrastText: '#000' },
    text: {
      primary: '#fff',
      secondary: '#fff',
    },
    gradient: {
      main: 'linear-gradient(340deg, rgba(0, 0, 0, 1) 0%, rgba(41, 41, 41, 1) 73%, #4a4a4c 100%)',
      contrastText: '#fff',
    },
    gradientLight: {
      main: 'linear-gradient(340deg, rgb(32, 32, 32) 0%, rgba(41, 41, 41, 1) 73%, #4a4a4c 100%)',
      contrastText: '#fff',
    },
  },
  breakpoints: {
    values: {
      zero: 0,
      xxxs: 300,
      xxs: 390,
      xs: 425,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
})

// this section is for theme props that rely on palette or breakpoints defined above dynamically
AppTheme = createTheme(AppTheme, {
  typography: {
    error: {
      color: AppTheme.palette.error.main,
      fontWeight: 700,
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
      styleOverrides: {
        root: {
          textDecoration: 'none',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          background: AppTheme.palette.gradientLight.main,
          color: AppTheme.palette.gradientLight.contrastText,
          '& .MuiDateTimePickerToolbar-ampmLabel:not(.Mui-selected)': {
            // fix the styling of the datetime picker am/pm selector being all white no matter what is selected
            color: AppTheme.palette.grey[600],
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          background: AppTheme.palette.secondary.main,
          '&.Mui-focused': {
            color: AppTheme.palette.secondary.contrastText,
          },
        },
        shrink: {
          borderRadius: '4px',
          padding: '2px 8px',
          marginLeft: '-4px',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          background: AppTheme.palette.secondary.main,
        },
      },
    },
    MuiDesktopTimePicker: {
      defaultProps: {
        viewRenderers: {
          hours: renderTimeViewClock,
          minutes: renderTimeViewClock,
          seconds: renderTimeViewClock,
        },
      },
    },
    MuiDesktopDateTimePicker: {
      defaultProps: {
        viewRenderers: {
          hours: renderTimeViewClock,
          minutes: renderTimeViewClock,
          seconds: renderTimeViewClock,
        },
      },
    },
  },
})

export default AppTheme

import { useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import camelCase from 'lodash/camelCase'
import startCase from 'lodash/startCase'
import { useQuery, useQueryClient } from 'react-query'
import { AppLayoutContext } from '../../../providers/AppLayout'
import { useAppNavigate, useUrlStateParams } from '../../../utils/url'
import { SnackbarContext } from '../../../providers/SnackbarContext'
import { AppStateContext } from '../../../providers/AppStateContext'
import { formatDate } from '../../../utils/formatting'

import { AppPermissions } from '../../../models/AppPermissions.model'
import { Trail } from '../../../models/Trail.model'
import { TrailRelations } from '../../../models/TrailRelations.model'
import { Difficulties } from '../../../models/Difficulties.model'

import UserService from '../../../services/UserService/UserService'
import { TrailService } from '../../../services/TrailService/TrailService'

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Link,
  Paper,
  Stack,
  Typography,
} from '@mui/material'
import Loading from '../../../components/Loading/Loading.component'
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs.component'
import LightboxImage from '../../../components/LightboxImage/LightboxImage.component'
import Tabs from '../../../components/Tabs/Tabs.component'
import AlgoVisualizer from '../../../components/AlgoVisualizer/AlgoVisualizer.component'
import ManualTrailStatusSearch from '../../ManualTrailStatuses/ManualTrailStatusSearch/ManualTrailStatusSearch.route'
import SponsorSearch from '../../Sponsors/SponsorSearch/SponsorSearch.route'
import TrailFeatureSearch from '../../TrailFeatures/TrailFeatureSearch/TrailFeatureSearch.route'
import FormDialog from '../../../components/FormDialog/FormDialog.component'
import TrailForm from '../shared/TrailForm.component'
import FeaturesOnTrailForm from '../shared/FeaturesOnTrailForm.component'
import ImportExportIcon from '@mui/icons-material/ImportExport'

const TrailDetail = (): JSX.Element => {
  const trailFeatureSearchRef = useRef<{ openImportExport: () => void }>(null)
  const navigate = useAppNavigate()
  const { id } = useParams<{ id: string }>()
  const [loadingError, setLoadingError] = useState('')

  const { user } = useAuth0()
  const hasWritePermissions = UserService.hasPermissions(user, [AppPermissions.editTrail])
  const hasWeatherGroupPermissions = UserService.hasPermissions(user, [AppPermissions.viewWeatherGroup])
  const hasWeatherRecordPermissions = UserService.hasPermissions(user, [AppPermissions.viewWeatherRecord])
  const hasManualTrailStatusPermissions = UserService.hasPermissions(user, [AppPermissions.editManualTrailStatus])
  const hasSponsorPermissions = UserService.hasPermissions(user, [AppPermissions.viewSponsor])
  const hasTrailFeaturePermissions = UserService.hasPermissions(user, [AppPermissions.viewTrailFeature])

  const [isSaving, setIsSaving] = useState(false)
  const [isEditing, setIsEditing] = useUrlStateParams<boolean>(
    false,
    'edit',
    (value) => (value ? 't' : ''),
    (value) => value === 't',
  )
  const [isEditingFeaturesOnTrail, setIsEditingFeaturesOnTrail] = useState<boolean>(false)
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false)

  const trailService = useMemo(() => new TrailService(), []) // memo to prevent re-creating service on every render

  useEffect(() => {
    AppLayoutContext.setPageName(`Trail Detail`)
  }, [id])

  const queryClient = useQueryClient()
  const { isLoading, data: trail } = useQuery(
    [
      'TrailService.get',
      {
        id: Number(id),
        relations: hasWeatherGroupPermissions ? [TrailRelations.system, TrailRelations.weatherGroups] : undefined,
      },
      AppStateContext.getRegion(),
    ],
    () =>
      trailService.get({
        id: Number(id),
        relations: hasWeatherGroupPermissions ? [TrailRelations.system, TrailRelations.weatherGroups] : undefined,
      }),
    {
      onError: (err) => {
        setLoadingError(typeof err === 'string' ? err : 'Failed to load trail.')
        console.error(err)
      },
    },
  )

  const handleEdit = () => {
    setIsEditing(false)
    setIsEditingFeaturesOnTrail(false)
  }

  const handleDeleteClick = () => {
    // show a confirmation dialog before deleting
    setIsDeleteConfirmationOpen(true)
  }

  const handleDelete = () => {
    if (trail?.id) {
      setIsSaving(true)
      setIsDeleteConfirmationOpen(false)
      trailService
        .delete(trail.id)
        .then(() => {
          SnackbarContext.show(`Trail deleted successfully. You will now be redirected.`)

          // after a delay of 1 second, redirect to the trail system detail page (since normally someone comes via the trail system detail page)
          setTimeout(() => {
            navigate(`/trail-systems/${trail.system?.id}`)
            queryClient.removeQueries([
              'TrailService.get',
              {
                id: Number(id),
                relations: hasWeatherGroupPermissions
                  ? [TrailRelations.system, TrailRelations.weatherGroups]
                  : undefined,
              },
            ])
            // invalidate all queries to ensure any queries that are using the modified record are updated
            queryClient.invalidateQueries()
          }, 1000)
        })
        .catch((err) => {
          console.error(err)
          SnackbarContext.show(`Trail failed to delete: ${err}`, 'error')
          setIsSaving(false)
        })
    }
  }

  const friendlyDifficulty = Object.entries(Difficulties).find(
    (eachDifficulty) => eachDifficulty[1] === trail?.difficultyId,
  )

  const tabs = useMemo(() => {
    const tabs = []
    if (trail) {
      if (hasWeatherRecordPermissions) {
        tabs.push({
          label: 'Algorithm',
          content: <AlgoVisualizer trail={trail} />,
        })
      }
      if (hasManualTrailStatusPermissions) {
        tabs.push({
          label: 'Manual Statuses',
          content: <ManualTrailStatusSearch nested trailId={trail.id} hideHeader excludeColumns={['system']} />,
        })
      }
      if (hasSponsorPermissions) {
        tabs.push({
          label: 'Sponsors',
          content: <SponsorSearch nested trailId={trail.id} hideHeader excludeColumns={['system']} />,
        })
      }
      if (hasTrailFeaturePermissions) {
        tabs.push({
          label: 'Features',
          content: (
            <Paper sx={{ p: 2 }}>
              <Stack direction="row" justifyContent="space-between" spacing={2} mb={2}>
                <Button
                  variant="outlined"
                  startIcon={<ImportExportIcon />}
                  color="primary"
                  aria-label="show import export"
                  onClick={() => {
                    trailFeatureSearchRef.current?.openImportExport()
                  }}
                >
                  Export
                </Button>
                {hasWritePermissions && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setIsEditingFeaturesOnTrail(true)
                    }}
                  >
                    Edit Features On Trail
                  </Button>
                )}
              </Stack>
              <TrailFeatureSearch
                nested
                hideHeader
                hideActions
                disableImport
                disablePadding
                trailId={trail.id}
                ref={trailFeatureSearchRef}
              />
            </Paper>
          ),
        })
      }
    }
    return tabs
  }, [trail, hasWeatherRecordPermissions, hasManualTrailStatusPermissions, hasSponsorPermissions])

  return (
    <>
      {trail && trail.system && (
        <FormDialog<Trail> open={isEditing} entity={trail} entityLabel="Trail" onClose={() => setIsEditing(false)}>
          <TrailForm
            trail={trail}
            trailSystem={trail.system}
            onEdit={handleEdit}
            onCancel={() => setIsEditing(false)}
          />
        </FormDialog>
      )}
      {trail && (
        <FormDialog<Trail>
          open={isEditingFeaturesOnTrail}
          entity={trail}
          entityLabel="Features On Trail"
          onClose={() => setIsEditingFeaturesOnTrail(false)}
        >
          <FeaturesOnTrailForm trail={trail} onEdit={handleEdit} onCancel={() => setIsEditingFeaturesOnTrail(false)} />
        </FormDialog>
      )}
      <Dialog
        fullWidth
        maxWidth="xs"
        open={isDeleteConfirmationOpen}
        onClose={() => setIsDeleteConfirmationOpen(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent dividers>Are you sure you want to delete this trail?</DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setIsDeleteConfirmationOpen(false)}>
            Cancel
          </Button>
          <Button onClick={handleDelete}>Confirm</Button>
        </DialogActions>
      </Dialog>
      <Paper sx={{ p: 2 }}>
        {isLoading && !loadingError && <Loading sx={{ py: 20 }} />}
        {loadingError && (
          <Stack direction="row" justifyContent="center" alignItems="center" sx={{ py: 20 }}>
            <Typography variant="error">{loadingError}</Typography>
          </Stack>
        )}
        {!isLoading && !loadingError && trail && !trail.system && (
          <Stack direction="row" justifyContent="center" alignItems="center" sx={{ py: 20 }}>
            <Typography variant="error">Failed to locate parent trail system.</Typography>
          </Stack>
        )}
        {!isLoading && !loadingError && trail && trail.system && (
          <>
            {isSaving && <Loading sx={{ py: 20 }} />}
            {!isSaving && (
              <>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{ mb: 2 }}>
                  <Breadcrumbs
                    items={[
                      {
                        label: trail.system.name || 'Trail System',
                        href: `/trail-systems/${trail.system.id}`,
                      },
                      { label: trail.name },
                    ]}
                  />
                  {hasWritePermissions && (
                    <Stack direction="row" justifyContent="end" alignItems="center" spacing={2}>
                      <Button variant="text" onClick={handleDeleteClick}>
                        Delete
                      </Button>
                      <Button variant="contained" onClick={() => setIsEditing(true)}>
                        Edit
                      </Button>
                    </Stack>
                  )}
                </Stack>
                <Box>
                  <Stack direction={{ sm: 'row' }} justifyContent="space-between" alignItems="start" spacing={2}>
                    <Box>
                      <Typography variant="h5" component="h2">
                        {trail.name}
                      </Typography>
                      <Typography variant="subtitle1" color={(theme) => theme.palette.grey[500]}>
                        ID: {trail.id}
                      </Typography>
                      {!trail.active && (
                        <Typography variant="subtitle1" color={(theme) => theme.palette.error.main}>
                          Inactive
                        </Typography>
                      )}
                      <Typography variant="subtitle1" color={(theme) => theme.palette.grey[500]}>
                        {trail.system?.name}, {trail.region}
                      </Typography>
                      <Box sx={{ mt: 2 }}>
                        <Typography variant="body1">
                          Difficulty: {friendlyDifficulty ? startCase(camelCase(friendlyDifficulty[0])) : ''}
                        </Typography>
                        <Typography variant="body1">Surface Type: {trail.surfaceType}</Typography>
                        <Typography variant="body1">Distance: {trail.distance || 0} miles</Typography>
                        <Typography variant="body1">Elevation Descent: {trail.descent || 0} feet</Typography>
                        <Typography variant="body1">Elevation Climb: {trail.climb || 0} feet</Typography>
                      </Box>
                    </Box>
                    <Divider sx={{ my: 2 }} />
                    <Box>
                      <Typography variant="h6" component="h3">
                        Featured Image
                      </Typography>
                      <Box
                        sx={(theme) => ({
                          maxWidth: '100%',
                          maxHeight: 200,
                          [theme.breakpoints.up('sm')]: { width: 200, height: 200 },
                        })}
                      >
                        {trail.featuredImgUrl ? (
                          <LightboxImage
                            src={trail.featuredImgUrl}
                            alt={`Image for ${trail.name}`}
                            sx={{
                              maxWidth: '100%',
                              maxHeight: 200,
                            }}
                          />
                        ) : (
                          <Typography variant="body1">-</Typography>
                        )}
                      </Box>
                    </Box>
                  </Stack>
                  <Divider sx={{ my: 2 }} />
                  <Box>
                    <Typography variant="h6" component="h3">
                      In The App:
                    </Typography>
                    <Link
                      variant="body1"
                      component="a"
                      href={`https://${AppStateContext.getRegion().toLowerCase()}.flowfeed.app/trail-system/${
                        trail.system?.permalink
                      }/${trail.permalink}`}
                      target="_blank"
                    >
                      https://{AppStateContext.getRegion().toLowerCase()}.flowfeed.app/trail-system/
                      {trail.system?.permalink}/{trail.permalink}
                    </Link>
                  </Box>
                  <Divider sx={{ my: 2 }} />
                  <Box>
                    <Typography variant="h6" component="h3">
                      Description
                    </Typography>
                    <Box sx={{ maxHeight: 500, overflowY: 'auto' }}>
                      <Typography variant="body1">{trail.description || '-'}</Typography>
                    </Box>
                  </Box>
                  <Divider sx={{ my: 2 }} />
                  <Box>
                    <Typography variant="h6" component="h3">
                      Address
                    </Typography>
                    <Typography variant="body1">{trail.street}</Typography>
                    <Typography variant="body1">
                      {trail.city}, {trail.state}
                    </Typography>
                    {trail.gpsLat !== null && trail.gpsLong !== null && (
                      <Typography variant="body1" color={(theme) => theme.palette.grey[500]} sx={{ mt: 1 }}>
                        GPS: {[trail.gpsLat, trail.gpsLong].join(', ')}
                      </Typography>
                    )}
                    {trail.parkingGpsLat !== null && trail.parkingGpsLong !== null && (
                      <Typography variant="body1" color={(theme) => theme.palette.grey[500]} sx={{ mt: 1 }}>
                        Parking GPS: {[trail.parkingGpsLat, trail.parkingGpsLong].join(', ')}
                      </Typography>
                    )}
                  </Box>
                  {hasWeatherGroupPermissions && (
                    <>
                      <Divider sx={{ my: 2 }} />
                      <Box>
                        <Typography variant="h6" component="h3">
                          Weather
                        </Typography>
                        <Typography variant="body1">
                          Algo:{' '}
                          {trail.weatherGroup?.id ? (
                            <Link href={`/weather-groups/${trail.weatherGroup?.id}`}>{trail.weatherGroup?.name}</Link>
                          ) : (
                            'Inherit from parent'
                          )}
                        </Typography>
                        <Typography variant="body1">
                          Display:{' '}
                          {trail.displayWeatherGroup?.id ? (
                            <Link href={`/weather-groups/${trail.displayWeatherGroup?.id}`}>
                              {trail.displayWeatherGroup?.name}
                            </Link>
                          ) : (
                            'Inherit from parent'
                          )}
                        </Typography>
                        <Typography variant="body1">Weather Resistance: {trail.weatherResistance}</Typography>
                        <Typography variant="body1">Saturation Modifier: {trail.saturationModifier * 100}%</Typography>
                        <Typography variant="body1">Freeze Modifier: {trail.freezeModifier * 100}%</Typography>
                        <Typography variant="body1">Thaw Modifier: {trail.thawModifier * 100}%</Typography>
                      </Box>
                    </>
                  )}
                  <Divider sx={{ my: 2 }} />
                  <Box>
                    <Typography variant="body1">Sort Weight: {trail.sortWeight}</Typography>
                    <Typography variant="body1">
                      Trailforks Url:{' '}
                      {trail.tfTrailUrl ? (
                        <Link variant="body1" component="a" href={trail.tfTrailUrl} target="_blank">
                          {trail.tfTrailUrl}
                        </Link>
                      ) : (
                        '-'
                      )}
                    </Typography>
                    <Typography variant="body1">Updated At: {formatDate(trail.updatedAt)}</Typography>
                    <Typography variant="body1">Created At: {formatDate(trail.createdAt)}</Typography>
                  </Box>
                </Box>
                {tabs.length > 0 && (
                  <Box sx={{ mt: 4 }}>
                    <Tabs tabs={tabs} />
                  </Box>
                )}
              </>
            )}
          </>
        )}
      </Paper>
    </>
  )
}
export default TrailDetail

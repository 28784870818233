import { AppRegions } from './AppRegions.model'
import { Trail } from './Trail.model'
import { TrailSystem } from './TrailSystem.model'

export type ManualTrailStatus = {
  id: number
  reason: string
  region: AppRegions
  status: 0 | 1 | 2 | 3
  system?: TrailSystem
  trail?: Trail
  start: Date | null
  end: Date | null
  updatedAt: Date
  createdAt: Date
}

// we offer this to enable the enumerate the ManualTrailStatus type's fields in js/ts files
export const manualTrailStatusFields: (keyof ManualTrailStatus)[] = [
  'id',
  'region',
  'status',
  'system',
  'trail',
  'start',
  'end',
  'reason',
  'updatedAt',
  'createdAt',
]

import { useEffect, useMemo, useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { SnackbarContext } from '../../../providers/SnackbarContext'

import { Sponsor } from '../../../models/Sponsor.model'
import { Trail } from '../../../models/Trail.model'
import { SponsorAddEvent } from '../../../events/SponsorAddEvent'
import { SponsorUpdateEvent } from '../../../events/SponsorUpdateEvent'
import { TrailSystem } from '../../../models/TrailSystem.model'

import { TrailSystemService } from '../../../services/TrailSystemService/TrailSystemService'
import { TrailService } from '../../../services/TrailService/TrailService'
import { SponsorService } from '../../../services/SponsorService/SponsorService'

import { Stack, FormControl, InputLabel, Button, Select, MenuItem, Divider, FormHelperText } from '@mui/material'
import Loading from '../../../components/Loading/Loading.component'
import DetailInput from '../../../components/DetailInput/DetailInput.component'
import { TrailRelations } from '../../../models/TrailRelations.model'
import DateTimePicker from '../../../components/DateTimePicker/DateTimePicker.component'
import { AppStateContext } from '../../../providers/AppStateContext'
import { useAppNavigate } from '../../../utils/url'

export type SponsorFormProps = {
  sponsor?: Sponsor
  trail?: Trail
  trailSystem?: TrailSystem
  onAdd?: (sponsor: Sponsor) => void
  onEdit?: (sponsor: Sponsor) => void
  onCancel: () => void
  // used to remotely trigger the save action
  saveTrigger?: number
  disableRedirect?: boolean
}
const SponsorForm = (props: SponsorFormProps) => {
  const { sponsor, trail, trailSystem, onCancel, onAdd, onEdit, saveTrigger, disableRedirect = false } = props

  const navigate = useAppNavigate()

  const [tempSponsor, setTempSponsor] = useState<
    Partial<Sponsor> & {
      trailId?: number
      systemId?: number
    }
  >(
    {
      active: true,
      ...sponsor,
      trailId: sponsor?.trail?.id,
      systemId: sponsor?.system?.id,
    } || {},
  )

  const [isLoading, setIsLoading] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)

  // if a system was provided, use it to initialize the form
  const [tempTrailSystem, setTempTrailSystem] = useState<TrailSystem | undefined>(trailSystem || sponsor?.system)
  const [tempTrail, setTempTrail] = useState<Trail | undefined>(trail || sponsor?.trail)

  const queryClient = useQueryClient()

  const trailService = useMemo(() => new TrailService(), []) // memo to prevent re-creating service on every render

  const { isLoading: isLoadingSponsorTrailWithSystem, data: sponsorTrailWithSystem } = useQuery(
    [
      'TrailService.get',
      { id: trail?.id || sponsor?.trail?.id, relations: [TrailRelations.system] },
      AppStateContext.getRegion(),
    ],
    () =>
      trailService.get({
        id: trail?.id || sponsor?.trail?.id,
        relations: [TrailRelations.system],
      }),
    {
      // no system was provided so this is either assigned by trail or nothing at all (a new sponsor)
      enabled: !trailSystem?.id && !sponsor?.system?.id && (trail || sponsor?.trail) ? true : false,
      onError: (err) => {
        SnackbarContext.show(`Failed to fetch trail for the form: ${typeof err === 'string' ? err : ''}`, 'error')
        console.error(err)
      },
    },
  )
  useEffect(() => {
    if (!sponsorTrailWithSystem) return
    setTempTrail(sponsorTrailWithSystem)
    setTempTrailSystem(sponsorTrailWithSystem.system)
  }, [sponsorTrailWithSystem])

  const { isLoading: isLoadingTrails, data: trailsResponse } = useQuery(
    [
      'TrailService.search',
      {
        systemId: tempTrailSystem?.id || sponsorTrailWithSystem?.system?.id,
        orderBy: 'name',
        orderDirection: 'ASC',
        relations: [],
        pageSize: 0,
      },
      AppStateContext.getRegion(),
    ],
    () =>
      trailService.search({
        systemId: tempTrailSystem?.id || sponsorTrailWithSystem?.system?.id,
        orderBy: 'name',
        orderDirection: 'ASC',
        relations: [],
        pageSize: 0,
      }),
    {
      enabled: tempTrailSystem?.id || sponsorTrailWithSystem?.system?.id ? true : false,
      onError: (err) => {
        SnackbarContext.show(`Failed to fetch trails for the form: ${typeof err === 'string' ? err : ''}`, 'error')
        console.error(err)
      },
    },
  )

  const trailSystemService = useMemo(() => new TrailSystemService(), []) // memo to prevent re-creating service on every render

  const { isLoading: isLoadingTrailSystems, data: trailSystemsResponse } = useQuery(
    [
      'TrailSystemService.search',
      { orderBy: 'name', orderDirection: 'ASC', relations: [], pageSize: 0 },
      AppStateContext.getRegion(),
    ],
    () => trailSystemService.search({ orderBy: 'name', orderDirection: 'ASC', relations: [], pageSize: 0 }),
    {
      onError: (err) => {
        SnackbarContext.show(
          `Failed to fetch trail systems for the form: ${typeof err === 'string' ? err : ''}`,
          'error',
        )
        console.error(err)
      },
    },
  )

  // consolidate the various loading states into one
  useEffect(() => {
    const shouldBeLoadingSponsorTrailWithSystem =
      !trailSystem?.id && !sponsor?.system?.id && (trail || sponsor?.trail) ? true : false
    const shouldBeLoadingTrails = tempTrailSystem?.id || sponsorTrailWithSystem?.system?.id ? true : false
    setIsLoading(true)
    if (
      ((shouldBeLoadingSponsorTrailWithSystem && !isLoadingSponsorTrailWithSystem) ||
        !shouldBeLoadingSponsorTrailWithSystem) &&
      ((shouldBeLoadingTrails && !isLoadingTrails) || !shouldBeLoadingTrails) &&
      !isLoadingTrailSystems
    ) {
      setIsLoading(false)
    }
  }, [
    trailSystem,
    sponsor,
    trail,
    tempTrailSystem,
    sponsorTrailWithSystem,
    isLoadingSponsorTrailWithSystem,
    isLoadingTrails,
    isLoadingTrailSystems,
  ])

  const sponsorService = useMemo(() => new SponsorService(), []) // memo to prevent re-creating service on every render

  const addSponsor = useMutation(
    (mutationParams: { sponsor: SponsorAddEvent }) => {
      setIsSubmitting(true)
      return sponsorService.add(mutationParams.sponsor)
    },
    {
      onSuccess: (result) => {
        SnackbarContext.show('Sponsor added successfully!')

        if (!disableRedirect) {
          // redirect to the new sponsor system
          navigate(`/sponsors/${result.id}`)
          // remove the search query to prevent auto refresh of the search results in case we are on that page and redirecting
          queryClient.removeQueries(['SponsorService.search'])
        }
        // invalidate all other queries to ensure any queries that are using the modified record are updated
        queryClient.invalidateQueries()
        setIsSubmitting(false)

        if (typeof onAdd === 'function') {
          onAdd(result)
        }
      },
      onError: (err) => {
        SnackbarContext.show(`Sponsor failed to add: ${err}`, 'error')
        console.error(err)
        setIsSubmitting(false)
      },
    },
  )

  const updateSponsor = useMutation(
    (mutationParams: { id: number; changedProperties: SponsorUpdateEvent }) => {
      setIsSubmitting(true)
      return sponsorService.update(mutationParams.id, mutationParams.changedProperties)
    },
    {
      onSuccess: (result) => {
        SnackbarContext.show('Sponsor updated successfully!')

        // invalidate all queries to ensure any queries that are using the modified record are updated
        queryClient.invalidateQueries()
        setIsSubmitting(false)

        if (typeof onEdit === 'function') {
          onEdit(result)
        }
      },
      onError: (err) => {
        SnackbarContext.show(`Sponsor failed to update: ${err}`, 'error')
        console.error(err)
        setIsSubmitting(false)
      },
    },
  )

  const handleSubmit = () => {
    if (!tempTrailSystem?.id) {
      SnackbarContext.show(`Sponsor's trail assignment or system assignment required`, 'error')
      return
    }

    if (!tempSponsor.name) {
      SnackbarContext.show(`Sponsor's name required`, 'error')
      return
    }

    setIsSubmitting(true)

    if (!sponsor) {
      // format the data to match the API
      const formattedSponsor: SponsorAddEvent = {
        name: tempSponsor.name,
        systemId: tempTrailSystem?.id && !tempTrail?.id ? tempTrailSystem?.id : undefined, // only add system if we didn't select a trail
        trailId: tempTrail?.id || undefined,
        start:
          dayjs.isDayjs(tempSponsor.start) || tempSponsor.start instanceof Date
            ? tempSponsor.start.toISOString()
            : tempSponsor.start || null,
        end:
          dayjs.isDayjs(tempSponsor.end) || tempSponsor.end instanceof Date
            ? tempSponsor.end.toISOString()
            : tempSponsor.end || null,
        url: tempSponsor.url,
        logoUrl: tempSponsor.logoUrl,
        active: tempSponsor.active,
      }

      // adding a new record
      addSponsor.mutate({ sponsor: formattedSponsor })
    } else {
      // identify the properties that have changed
      const didChange = (field: keyof Sponsor) => {
        return tempSponsor[field] !== undefined && tempSponsor[field] !== sponsor[field]
      }

      const changedProperties: SponsorUpdateEvent = {
        id: sponsor.id,
        name: didChange('name') ? tempSponsor.name : undefined,
        systemId:
          tempTrailSystem?.id && !tempTrail?.id && tempTrailSystem.id !== sponsor.system?.id
            ? tempTrailSystem.id
            : undefined,
        trailId: tempTrail?.id && tempTrail.id !== sponsor.trail?.id ? tempTrail.id : undefined,
        // status: didChange('status') ? tempSponsor.status : undefined,
        start: didChange('start')
          ? dayjs.isDayjs(tempSponsor.start) || tempSponsor.start instanceof Date
            ? tempSponsor.start.toISOString()
            : tempSponsor.start || null
          : undefined,
        end: didChange('end')
          ? dayjs.isDayjs(tempSponsor.end) || tempSponsor.end instanceof Date
            ? tempSponsor.end.toISOString()
            : tempSponsor.end || null
          : undefined,
        url: tempSponsor.url,
        logoUrl: tempSponsor.logoUrl,
        active: tempSponsor.active,
      }

      // unset the trail or system to ensure we don't pass both
      if (sponsor.system?.id && tempTrail?.id) {
        changedProperties.systemId = null
      }
      if (sponsor.trail?.id && !tempTrail?.id) {
        changedProperties.trailId = null
      }

      // edit an existing record
      if (!Object.keys(changedProperties).filter((key) => key !== 'id').length) {
        // nothing changed so just close the dialog
        onCancel()
      } else {
        updateSponsor.mutate({ id: sponsor.id, changedProperties })
      }
    }
  }

  useEffect(() => {
    if (saveTrigger) {
      // if outside consumer triggered save, then submit
      handleSubmit()
    }
  }, [saveTrigger])

  const handleCancel = () => {
    if (typeof onCancel === 'function') {
      onCancel()
    }
  }

  const keyDownSubmit = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSubmit()
    }
  }

  const setDateValue = (propName: keyof typeof tempSponsor, newValue: string | Date | Dayjs | null) => {
    setTempSponsor({
      ...tempSponsor,
      [propName]: dayjs.isDayjs(newValue) ? newValue : typeof newValue === 'string' ? dayjs(newValue) : null,
    })
  }

  return (
    <>
      {(isSubmitting || isLoading) && <Loading sx={{ py: 20 }} />}
      {!isSubmitting && !isLoading && (
        <>
          <Stack spacing={2} sx={{ my: 2 }}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="trail-system-select-label">Trail System</InputLabel>
              <Select
                labelId="trail-system-select-label"
                id="trail-system-select"
                value={tempTrailSystem ? tempTrailSystem.id : ''}
                label="Trail System"
                onChange={(e) => {
                  setTempTrailSystem(
                    trailSystemsResponse?.data.find((eachTrailSystem) => eachTrailSystem.id === e.target.value),
                  )
                  // clear temp trail since we are either initializing or changing the system
                  setTempTrail(undefined)
                }}
              >
                <MenuItem value="">-Select-</MenuItem>
                {trailSystemsResponse?.data.map((eachTrailSystem) => {
                  return (
                    <MenuItem key={eachTrailSystem.id} value={eachTrailSystem.id}>
                      {eachTrailSystem.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
            {tempTrailSystem && trailsResponse?.data && trailsResponse.data.length && (
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="trail-select-label" shrink>
                  Trail
                </InputLabel>
                <Select
                  labelId="trail-select-label"
                  id="trail-select"
                  displayEmpty
                  value={tempTrail ? tempTrail.id : ''}
                  label="Trail"
                  onChange={(e) => {
                    setTempTrail(trailsResponse.data.find((eachTrail) => eachTrail.id === e.target.value))
                  }}
                >
                  <MenuItem value="">Apply To All Trails</MenuItem>
                  {trailsResponse.data.map((eachTrail) => {
                    return (
                      <MenuItem key={eachTrail.id} value={eachTrail.id}>
                        {eachTrail.name}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            )}
            <Divider />
            <DetailInput<Sponsor>
              label="Name"
              field="name"
              tempDetail={tempSponsor}
              setTempDetail={setTempSponsor}
              keyDownSubmit={keyDownSubmit}
            />

            <DetailInput<Sponsor>
              label="URL"
              field="url"
              tempDetail={tempSponsor}
              setTempDetail={setTempSponsor}
              keyDownSubmit={keyDownSubmit}
            />

            <DetailInput<Sponsor>
              label="Logo URL"
              field="logoUrl"
              tempDetail={tempSponsor}
              setTempDetail={setTempSponsor}
              keyDownSubmit={keyDownSubmit}
            />

            <FormControl variant="outlined" fullWidth>
              <DateTimePicker
                label="Start"
                value={tempSponsor.start}
                onChange={(newValue) => {
                  setDateValue('end', newValue)
                }}
              />
            </FormControl>

            <FormControl variant="outlined" fullWidth>
              <DateTimePicker
                label="End"
                value={tempSponsor.end}
                onChange={(newValue) => {
                  setDateValue('end', newValue)
                }}
              />
            </FormControl>

            <FormControl variant="outlined" fullWidth>
              <InputLabel id="trail-system-active-select-label">Active</InputLabel>
              <Select
                labelId="trail-system-active-select-label"
                id="trail-system-active-select"
                value={String(tempSponsor.active)}
                label="Active"
                onChange={(e) => {
                  setTempSponsor((prev) => ({ ...prev, active: e.target.value === 'false' ? false : true }))
                }}
              >
                <MenuItem value="true">True</MenuItem>
                <MenuItem value="false">False</MenuItem>
              </Select>
              <FormHelperText>
                This can be set to False to override the start / end date in case we need to temporarily deactivate a
                sponsor mid start / end date. Setting it to True doesn't bypass the start / end date.
              </FormHelperText>
            </FormControl>
          </Stack>
          <Stack direction="row" justifyContent="end" alignItems="center" spacing={2}>
            <Button variant="outlined" onClick={handleCancel}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSubmit}>
              Save
            </Button>
          </Stack>
        </>
      )}
    </>
  )
}
export default SponsorForm

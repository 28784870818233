import { AppRegions } from './AppRegions.model'
import { Keyword } from './Keyword.model'
import { Sponsor } from './Sponsor.model'
import { Trail } from './Trail.model'
import { USStates } from './USStates.model'
import { WeatherGroup } from './WeatherGroup.model'
import { WeatherRecord } from './WeatherRecord.model'

export type TrailSystem = {
  id: number
  region: AppRegions
  name: string
  trails?: Trail[]
  weatherGroup?: WeatherGroup
  displayWeatherGroup?: WeatherGroup
  sponsor?: Sponsor
  mostRecentDisplayWeatherRecord?: WeatherRecord
  description: string
  parkingGpsLat: number
  parkingGpsLong: number
  street: string
  city: string
  state: USStates
  sortWeight: number
  permalink: string
  keywords?: Keyword[]
  active: boolean
  updatedAt: Date
  createdAt: Date
}

// we offer this to enable the enumerate the TrailSystem type's fields in js/ts files
export const trailSystemFields: (keyof TrailSystem)[] = [
  'id',
  'name',
  'region',
  'weatherGroup',
  'displayWeatherGroup',
  'description',
  'parkingGpsLat',
  'parkingGpsLong',
  'street',
  'city',
  'state',
  'sortWeight',
  'permalink',
  'keywords',
  'active',
  'updatedAt',
  'createdAt',
]

import { User } from '@auth0/auth0-react'

import { AppPermissions } from '../../models/AppPermissions.model'
import { UserDTO } from '../../models/UserDTO.model'

import FlowfeedApiQueryService from '../FlowfeedApiQueryService/FlowfeedApiQueryService'
import { AppRegions } from '../../models/AppRegions.model'

export default class UserService {
  /**
   * @description Updates the user
   *
   * @param id
   * @param data
   * @param token
   *
   * @returns Promise<void>
   */
  public static async updateUser(
    id: string,
    data: Partial<Record<'name' | 'position_title' | 'nursing_specialization', string>>,
  ): Promise<UserDTO> {
    const flowfeedApiQueryService = new FlowfeedApiQueryService()
    return await flowfeedApiQueryService.patch<UserDTO>({
      endpoint: `user/${encodeURIComponent(id)}`,
      data,
    })
  }

  /**
   * @description Removes the user from the system and deletes all their data
   *
   * @param id
   * @param token
   *
   * @returns Promise<void>
   */
  public static async deleteUser(id: string): Promise<void> {
    const flowfeedApiQueryService = new FlowfeedApiQueryService()
    await flowfeedApiQueryService.delete<boolean>({
      endpoint: `user/${encodeURIComponent(id)}`,
    })
  }

  public static hasPermissions = (user?: User, permissions?: AppPermissions[]): boolean => {
    if (!user) return false
    if (!permissions?.length) return true

    const formattedUser = UserService.formatUser(user)
    // check if the user is logged in and if so, if they have one of the required permissions
    return formattedUser && permissions.some((permission) => formattedUser?.permissions.includes(permission))
  }

  public static hasRegion = (user?: User, region?: AppRegions): boolean => {
    if (!user) return false
    if (!region) return true

    const formattedUser = UserService.formatUser(user)
    // check if the user is logged in and if so, if they have the specified region
    return formattedUser && formattedUser?.regions.includes(region)
  }

  public static hasTrailSystem = (user?: User, trailSystemId?: number): boolean => {
    if (!user) return false
    if (!trailSystemId) return true

    const formattedUser = UserService.formatUser(user)
    // check if the user is logged in and if so, if they have the specified trail system id
    return formattedUser && formattedUser?.trailSystems.includes(trailSystemId)
  }

  /**
   * @description Takes in a user object fetched from the Auth0 hook and formats it to match the UserDTO model
   *
   * @param user
   *
   * @returns UserDTO
   */
  public static formatUser(user: User): UserDTO {
    const rawPermissions = Array.isArray(user['https://app.flowfeed.com/permissions'])
      ? user['https://app.flowfeed.com/permissions']
      : []
    // filter out any permissions that are not in the AppPermissions enum
    const permissions = rawPermissions.filter((permission) => Object.values(AppPermissions).includes(permission))

    const rawRegions = Array.isArray(user['https://app.flowfeed.com/regions'])
      ? user['https://app.flowfeed.com/regions']
      : []
    // filter out any regions that are not in the AppRegions enum
    const regions = rawRegions.filter((region) => Object.values(AppRegions).includes(region))

    const rawTrailSystems = Array.isArray(user['https://app.flowfeed.com/trail-systems'])
      ? user['https://app.flowfeed.com/trail-systems']
      : []
    const trailSystems = rawTrailSystems
      .map((trailSystem) => Number(trailSystem))
      .filter((trailSystem) => !isNaN(trailSystem))

    return {
      id: user.sub || '',
      isSocial: !!user['https://app.flowfeed.com/idp']?.find((idp: { isSocial: boolean }) => idp.isSocial),
      name: user.name || '',
      email: user.email || '',
      picture: user.picture || '',
      permissions,
      regions,
      trailSystems,
    }
  }
}

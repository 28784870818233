import { useEffect, useMemo, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import dayjs, { Dayjs } from 'dayjs'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { SnackbarContext } from '../../../providers/SnackbarContext'

import { WeatherRecord } from '../../../models/WeatherRecord.model'
import { WeatherRecordAddEvent } from '../../../events/WeatherRecordAddEvent'
import { WeatherRecordUpdateEvent } from '../../../events/WeatherRecordUpdateEvent'
import { AppPermissions } from '../../../models/AppPermissions.model'
import { WeatherGroup } from '../../../models/WeatherGroup.model'
import { WeatherRecordTypes } from '../../../models/WeatherRecordTypes.model'

import UserService from '../../../services/UserService/UserService'
import { WeatherGroupService } from '../../../services/WeatherGroupService/WeatherGroupService'
import { WeatherRecordService } from '../../../services/WeatherRecordService/WeatherRecordService'

import { Stack, FormControl, InputLabel, Button, Select, MenuItem, Typography, Box } from '@mui/material'
import Loading from '../../../components/Loading/Loading.component'
import DetailInput from '../../../components/DetailInput/DetailInput.component'
import DateTimePicker from '../../../components/DateTimePicker/DateTimePicker.component'
import { weatherConditions } from '../../../models/WeatherConditions.model'
import { AppStateContext } from '../../../providers/AppStateContext'
import { useAppNavigate } from '../../../utils/url'

export type WeatherRecordFormProps = {
  weatherRecord?: WeatherRecord
  weatherGroup?: WeatherGroup
  onAdd?: (weatherRecord: WeatherRecord) => void
  onEdit?: (weatherRecord: WeatherRecord) => void
  onCancel: () => void
  // used to remotely trigger the save action
  saveTrigger?: number
  disableRedirect?: boolean
}
const WeatherRecordForm = (props: WeatherRecordFormProps) => {
  const { weatherRecord, weatherGroup, onCancel, onAdd, onEdit, saveTrigger, disableRedirect = false } = props

  const navigate = useAppNavigate()

  const { user } = useAuth0()
  const hasViewWeatherGroupPermissions = UserService.hasPermissions(user, [AppPermissions.viewWeatherGroup])

  const [tempWeatherRecord, setTempWeatherRecord] = useState<
    Partial<WeatherRecord> & {
      weatherGroupId?: number
    }
  >(
    {
      ...weatherRecord,
      weatherGroupId: weatherGroup ? weatherGroup.id : weatherRecord?.weatherGroup?.id,
    } || {},
  )
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const queryClient = useQueryClient()

  const weatherGroupService = useMemo(() => new WeatherGroupService(), []) // memo to prevent re-creating service on every render
  const { isLoading: isLoadingWeatherGroups, data: weatherGroupsResponse } = useQuery(
    ['WeatherGroupService.search', { pageSize: 0 }, AppStateContext.getRegion()],
    () => weatherGroupService.search({ pageSize: 0 }),
    {
      enabled: hasViewWeatherGroupPermissions,
      onError: (err) => {
        SnackbarContext.show(`Failed to fetch weather groups for the form: ${err}`, 'error')
        console.error(err)
      },
    },
  )

  useEffect(() => {
    setIsLoading(true)
    if (!hasViewWeatherGroupPermissions) {
      setIsLoading(false)
    } else if (!isLoadingWeatherGroups) {
      setIsLoading(false)
    }
  }, [weatherRecord, weatherGroup, isLoadingWeatherGroups, hasViewWeatherGroupPermissions])

  const weatherRecordService = useMemo(() => new WeatherRecordService(), []) // memo to prevent re-creating service on every render

  const addWeatherRecord = useMutation(
    (mutationParams: { weatherRecord: WeatherRecordAddEvent }) => {
      setIsSubmitting(true)
      return weatherRecordService.add(mutationParams.weatherRecord)
    },
    {
      onSuccess: (result) => {
        SnackbarContext.show('Trail System added successfully!')

        if (!disableRedirect) {
          // redirect to the new weather record
          navigate(`/weather-records/${result.id}`)
          // remove the search query to prevent auto refresh of the search results in case we are on that page and redirecting
          queryClient.removeQueries(['WeatherRecordService.search'])
        }
        // invalidate all other queries to ensure any queries that are using the modified record are updated
        queryClient.invalidateQueries()
        setIsSubmitting(false)

        if (typeof onAdd === 'function') {
          onAdd(result)
        }
      },
      onError: (err) => {
        SnackbarContext.show(`Trail System failed to add: ${err}`, 'error')
        console.error(err)
        setIsSubmitting(false)
      },
    },
  )

  const updateWeatherRecord = useMutation(
    (mutationParams: { id: number; changedProperties: WeatherRecordUpdateEvent }) => {
      setIsSubmitting(true)
      return weatherRecordService.update(mutationParams.id, mutationParams.changedProperties)
    },
    {
      onSuccess: (result) => {
        SnackbarContext.show('Trail System updated successfully!')

        // invalidate all queries to ensure any queries that are using the modified record are updated
        queryClient.invalidateQueries()
        setIsSubmitting(false)

        if (typeof onEdit === 'function') {
          onEdit(result)
        }
      },
      onError: (err) => {
        SnackbarContext.show(`Trail System failed to update: ${err}`, 'error')
        console.error(err)
        setIsSubmitting(false)
      },
    },
  )

  const handleSubmit = () => {
    if (!tempWeatherRecord.type) {
      SnackbarContext.show(`Weather Record type is required`, 'error')
      return
    }
    if (!tempWeatherRecord.timestamp) {
      SnackbarContext.show(`Weather Record timestamp is required`, 'error')
      return
    }
    if (!tempWeatherRecord.temperature === null || tempWeatherRecord.temperature === undefined) {
      SnackbarContext.show(`Weather Record temperature is required`, 'error')
      return
    }
    if (tempWeatherRecord.precipLast1hr === null || tempWeatherRecord.precipLast1hr === undefined) {
      SnackbarContext.show(`Weather Record precipLast1Hr is required`, 'error')
      return
    }
    if (!weatherGroup && !tempWeatherRecord.weatherGroupId) {
      SnackbarContext.show(`Weather Record weather group is required`, 'error')
      return
    }

    if (!weatherRecord) {
      // format the data to match the API
      const formattedWeatherRecord: WeatherRecordAddEvent = {
        weatherGroupId: Number(tempWeatherRecord.weatherGroupId),
        type: tempWeatherRecord.type,
        timestamp: dayjs.isDayjs(tempWeatherRecord.timestamp)
          ? tempWeatherRecord.timestamp.toDate()
          : tempWeatherRecord.timestamp || undefined,
        temperature: tempWeatherRecord.temperature,
        tempMin: tempWeatherRecord.tempMin || undefined,
        tempMax: tempWeatherRecord.tempMax || undefined,
        feelsLike: tempWeatherRecord.feelsLike || undefined,
        pressure: tempWeatherRecord.pressure || undefined,
        relativeHumidity: tempWeatherRecord.relativeHumidity || undefined,
        precipLast1hr: tempWeatherRecord.precipLast1hr,
        snowLast1hr: tempWeatherRecord.snowLast1hr || undefined,
        windSpeed: tempWeatherRecord.windSpeed || undefined,
        windDirection: tempWeatherRecord.windDirection || undefined,
        uv: tempWeatherRecord.uv || undefined,
        brightness: tempWeatherRecord.brightness || undefined,
        sunrise: dayjs.isDayjs(tempWeatherRecord.sunrise)
          ? tempWeatherRecord.sunrise.toDate()
          : tempWeatherRecord.sunrise || undefined,
        sunset: dayjs.isDayjs(tempWeatherRecord.sunset)
          ? tempWeatherRecord.sunset.toDate()
          : tempWeatherRecord.sunset || undefined,
        conditionId: tempWeatherRecord.conditionId || null,
        // TODO: temporary during migration to new db, remove later
        wordpressId: tempWeatherRecord.wordpressId || undefined,
      }

      // adding a new weatherRecord
      addWeatherRecord.mutate({ weatherRecord: formattedWeatherRecord })
    } else {
      // identify the properties that have changed
      const didChange = (field: keyof WeatherRecord) => {
        return tempWeatherRecord[field] !== undefined && tempWeatherRecord[field] !== weatherRecord[field]
      }

      const changedProperties: WeatherRecordUpdateEvent = {
        type: didChange('type') ? tempWeatherRecord.type : undefined,
        timestamp: didChange('timestamp')
          ? dayjs.isDayjs(tempWeatherRecord.timestamp)
            ? tempWeatherRecord.timestamp.toDate()
            : tempWeatherRecord.timestamp || undefined
          : undefined,
        temperature: didChange('temperature') ? tempWeatherRecord.temperature : undefined,
        tempMin: didChange('tempMin') ? tempWeatherRecord.tempMin : undefined,
        tempMax: didChange('tempMax') ? tempWeatherRecord.tempMax : undefined,
        feelsLike: didChange('feelsLike') ? tempWeatherRecord.feelsLike : undefined,
        pressure: didChange('pressure') ? tempWeatherRecord.pressure : undefined,
        relativeHumidity: didChange('relativeHumidity') ? tempWeatherRecord.relativeHumidity : undefined,
        precipLast1hr: didChange('precipLast1hr') ? tempWeatherRecord.precipLast1hr : undefined,
        snowLast1hr: didChange('snowLast1hr') ? tempWeatherRecord.snowLast1hr : undefined,
        windSpeed: didChange('windSpeed') ? tempWeatherRecord.windSpeed : undefined,
        windDirection: didChange('windDirection') ? tempWeatherRecord.windDirection : undefined,
        uv: didChange('uv') ? tempWeatherRecord.uv : undefined,
        brightness: didChange('brightness') ? tempWeatherRecord.brightness : undefined,
        sunrise: didChange('sunrise')
          ? dayjs.isDayjs(tempWeatherRecord.sunrise)
            ? tempWeatherRecord.sunrise.toDate()
            : tempWeatherRecord.sunrise || undefined
          : undefined,
        sunset: didChange('sunset')
          ? dayjs.isDayjs(tempWeatherRecord.sunset)
            ? tempWeatherRecord.sunset.toDate()
            : tempWeatherRecord.sunset || undefined
          : undefined,
        conditionId: didChange('conditionId') ? tempWeatherRecord.conditionId || null : undefined,
      }

      if (!Object.keys(changedProperties).filter((key) => key !== 'id').length) {
        // nothing changed so just close the dialog
        onCancel()
      } else {
        // edit an existing weatherRecord
        updateWeatherRecord.mutate({ id: weatherRecord.id, changedProperties })
      }
    }
  }

  useEffect(() => {
    if (saveTrigger) {
      // if outside consumer triggered save, then submit
      handleSubmit()
    }
  }, [saveTrigger])

  const handleCancel = () => {
    if (typeof onCancel === 'function') {
      onCancel()
    }
  }

  const keyDownSubmit = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSubmit()
    }
  }

  const setDateValue = (propName: keyof typeof tempWeatherRecord, newValue: string | Date | Dayjs | null) => {
    setTempWeatherRecord({
      ...tempWeatherRecord,
      [propName]: dayjs.isDayjs(newValue) ? newValue : typeof newValue === 'string' ? dayjs(newValue) : null,
    })
  }

  return (
    <>
      {(isLoading || isSubmitting) && <Loading sx={{ py: 20 }} />}
      {!isLoading && !isSubmitting && (
        <>
          <Stack spacing={2} sx={{ my: 2 }}>
            {(weatherRecord || weatherGroup) && (
              <Box>
                <Typography variant="h6" sx={{ mb: 1 }}>
                  Weather Group
                </Typography>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  {weatherGroup ? weatherGroup.name : weatherRecord?.weatherGroup?.name}
                </Typography>
              </Box>
            )}
            {!weatherRecord && hasViewWeatherGroupPermissions && weatherGroupsResponse?.data && !weatherGroup && (
              <>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="weather-record-weather-group-select-label">Weather Group</InputLabel>
                  <Select
                    labelId="weather-record-weather-group-select-label"
                    id="weather-record-weather-group-select"
                    value={tempWeatherRecord.weatherGroupId || ''}
                    label="Weather Group"
                    onChange={(e) => {
                      setTempWeatherRecord((prev) => ({ ...prev, weatherGroupId: Number(e.target.value) }))
                    }}
                  >
                    <MenuItem value="">-Select-</MenuItem>
                    {weatherGroupsResponse.data.map((eachWeatherGroup) => {
                      return (
                        <MenuItem key={eachWeatherGroup.id} value={eachWeatherGroup.id}>
                          {eachWeatherGroup.name}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </>
            )}

            <FormControl variant="outlined" fullWidth>
              <InputLabel id="weather-record-type-select-label">Type</InputLabel>
              <Select
                labelId="weather-record-type-select-label"
                id="weather-record-type-select"
                value={tempWeatherRecord.type || ''}
                onChange={(e) => {
                  setTempWeatherRecord({
                    ...tempWeatherRecord,
                    type: e.target.value as WeatherRecordTypes,
                  })
                }}
                label="Type"
              >
                <MenuItem value={WeatherRecordTypes.Algo}>Algorithm</MenuItem>
                <MenuItem value={WeatherRecordTypes.Display}>Display</MenuItem>
              </Select>
            </FormControl>

            <FormControl variant="outlined" fullWidth>
              <DateTimePicker
                label="Timestamp"
                value={tempWeatherRecord.timestamp}
                onChange={(newValue) => {
                  setDateValue('timestamp', newValue)
                }}
              />
            </FormControl>

            <DetailInput<WeatherRecord>
              label="Temperature"
              field="temperature"
              type="number"
              tempDetail={tempWeatherRecord}
              setTempDetail={setTempWeatherRecord}
              keyDownSubmit={keyDownSubmit}
            />

            <DetailInput<WeatherRecord>
              label="Temperature Minimum"
              field="tempMin"
              type="number"
              tempDetail={tempWeatherRecord}
              setTempDetail={setTempWeatherRecord}
              keyDownSubmit={keyDownSubmit}
            />

            <DetailInput<WeatherRecord>
              label="Temperature Maximum"
              field="tempMax"
              type="number"
              tempDetail={tempWeatherRecord}
              setTempDetail={setTempWeatherRecord}
              keyDownSubmit={keyDownSubmit}
            />

            <DetailInput<WeatherRecord>
              label="Feels Like"
              field="feelsLike"
              type="number"
              tempDetail={tempWeatherRecord}
              setTempDetail={setTempWeatherRecord}
              keyDownSubmit={keyDownSubmit}
            />

            <DetailInput<WeatherRecord>
              label="Pressure"
              field="pressure"
              type="number"
              tempDetail={tempWeatherRecord}
              setTempDetail={setTempWeatherRecord}
              keyDownSubmit={keyDownSubmit}
            />

            <DetailInput<WeatherRecord>
              label="Relative Humidity"
              field="relativeHumidity"
              type="number"
              tempDetail={tempWeatherRecord}
              setTempDetail={setTempWeatherRecord}
              keyDownSubmit={keyDownSubmit}
            />

            <DetailInput<WeatherRecord>
              label="Precipitation Last 1 Hour"
              field="precipLast1hr"
              type="number"
              tempDetail={tempWeatherRecord}
              setTempDetail={setTempWeatherRecord}
              keyDownSubmit={keyDownSubmit}
            />

            <DetailInput<WeatherRecord>
              label="Snow Last 1 Hour"
              field="snowLast1hr"
              type="number"
              tempDetail={tempWeatherRecord}
              setTempDetail={setTempWeatherRecord}
              keyDownSubmit={keyDownSubmit}
            />

            <DetailInput<WeatherRecord>
              label="Wind Speed"
              field="windSpeed"
              type="number"
              tempDetail={tempWeatherRecord}
              setTempDetail={setTempWeatherRecord}
              keyDownSubmit={keyDownSubmit}
            />

            <DetailInput<WeatherRecord>
              label="Wind Direction"
              field="windDirection"
              type="number"
              tempDetail={tempWeatherRecord}
              setTempDetail={setTempWeatherRecord}
              keyDownSubmit={keyDownSubmit}
            />

            <DetailInput<WeatherRecord>
              label="UV"
              field="uv"
              type="number"
              tempDetail={tempWeatherRecord}
              setTempDetail={setTempWeatherRecord}
              keyDownSubmit={keyDownSubmit}
            />

            <DetailInput<WeatherRecord>
              label="Brightness"
              field="brightness"
              type="number"
              tempDetail={tempWeatherRecord}
              setTempDetail={setTempWeatherRecord}
              keyDownSubmit={keyDownSubmit}
            />

            <FormControl variant="outlined" fullWidth>
              <DateTimePicker
                label="Sunrise"
                value={tempWeatherRecord.sunrise}
                onChange={(newValue) => {
                  setDateValue('sunrise', newValue)
                }}
              />
            </FormControl>

            <FormControl variant="outlined" fullWidth>
              <DateTimePicker
                label="Sunset"
                value={tempWeatherRecord.sunset}
                onChange={(newValue) => {
                  setDateValue('sunset', newValue)
                }}
              />
            </FormControl>

            <FormControl variant="outlined" fullWidth>
              <InputLabel id="weather-record-weather-condition-select-label">Weather Condition</InputLabel>
              <Select
                labelId="weather-record-weather-condition-select-label"
                id="weather-record-weather-condition-select"
                value={tempWeatherRecord.conditionId || ''}
                label="Weather Condition"
                onChange={(e) => {
                  setTempWeatherRecord((prev) => ({
                    ...prev,
                    conditionId: e.target.value ? Number(e.target.value) : undefined,
                  }))
                }}
              >
                <MenuItem value="">-Select-</MenuItem>
                {weatherConditions.map((condition) => {
                  return (
                    <MenuItem key={condition.id} value={condition.id}>
                      {condition.name}: {condition.description}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Stack>
          <Stack direction="row" justifyContent="end" alignItems="center" spacing={2}>
            <Button variant="outlined" onClick={handleCancel}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSubmit}>
              Save
            </Button>
          </Stack>
        </>
      )}
    </>
  )
}
export default WeatherRecordForm

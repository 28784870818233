import { useEffect, useMemo, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { SnackbarContext } from '../../../providers/SnackbarContext'

import { TrailFinderQuestion } from '../../../models/TrailFinderQuestion.model'
import { TrailFinderQuestionAddEvent } from '../../../events/TrailFinderQuestionAddEvent'
import { TrailFinderQuestionUpdateEvent } from '../../../events/TrailFinderQuestionUpdateEvent'

import { TrailFinderQuestionService } from '../../../services/TrailFinderQuestionService/TrailFinderQuestionService'

import { Stack, FormControl, InputLabel, Button, Select, MenuItem } from '@mui/material'
import Loading from '../../../components/Loading/Loading.component'
import DetailInput from '../../../components/DetailInput/DetailInput.component'
import { useAppNavigate } from '../../../utils/url'

export type TrailFinderQuestionFormProps = {
  trailFinderQuestion?: TrailFinderQuestion
  onAdd?: (trailFinderQuestion: TrailFinderQuestion) => void
  onEdit?: (trailFinderQuestion: TrailFinderQuestion) => void
  onCancel: () => void
  // used to remotely trigger the save action
  saveTrigger?: number
  disableRedirect?: boolean
}
const TrailFinderQuestionForm = (props: TrailFinderQuestionFormProps) => {
  const { trailFinderQuestion, onCancel, onAdd, onEdit, saveTrigger, disableRedirect = false } = props

  const navigate = useAppNavigate()

  const [tempTrailFinderQuestion, setTempTrailFinderQuestion] = useState<Partial<TrailFinderQuestion>>(
    trailFinderQuestion || {},
  )
  const [isSubmitting, setIsSubmitting] = useState(false)

  const trailFinderQuestionService = useMemo(() => new TrailFinderQuestionService(), []) // memo to prevent re-creating service on every render

  const queryClient = useQueryClient()

  const addTrailFinderQuestion = useMutation(
    (mutationParams: { trailFinderQuestion: TrailFinderQuestionAddEvent }) => {
      setIsSubmitting(true)
      return trailFinderQuestionService.add(mutationParams.trailFinderQuestion)
    },
    {
      onSuccess: (result) => {
        SnackbarContext.show('Trail Finder Question added successfully!')

        if (!disableRedirect) {
          // redirect to the new trailFinderQuestion
          navigate(`/trail-finder-questions/${result.id}`)
          // remove the search query to prevent auto refresh of the search results in case we are on that page and redirecting
          queryClient.removeQueries(['TrailFinderQuestionService.search'])
        }
        // invalidate all other queries to ensure any queries that are using the modified record are updated
        queryClient.invalidateQueries()
        setIsSubmitting(false)

        if (typeof onAdd === 'function') {
          onAdd(result)
        }
      },
      onError: (err) => {
        SnackbarContext.show(`Trail Finder Question failed to add: ${err}`, 'error')
        console.error(err)
        setIsSubmitting(false)
      },
    },
  )

  const updateTrailFinderQuestion = useMutation(
    (mutationParams: { id: number; changedProperties: TrailFinderQuestionUpdateEvent }) => {
      setIsSubmitting(true)
      return trailFinderQuestionService.update(mutationParams.id, mutationParams.changedProperties)
    },
    {
      onSuccess: (result) => {
        SnackbarContext.show('Trail Finder Question updated successfully!')

        // invalidate all queries to ensure any queries that are using the modified record are updated
        queryClient.invalidateQueries()
        setIsSubmitting(false)

        if (typeof onEdit === 'function') {
          onEdit(result)
        }
      },
      onError: (err) => {
        SnackbarContext.show(`Trail Finder Question failed to update: ${err}`, 'error')
        console.error(err)
        setIsSubmitting(false)
      },
    },
  )

  const handleSubmit = () => {
    if (!tempTrailFinderQuestion.question) {
      SnackbarContext.show(`Trail Finder Question question text is required`, 'error')
      return
    }

    setIsSubmitting(true)

    if (!trailFinderQuestion) {
      // format the data to match the API
      const formattedTrailFinderQuestion: TrailFinderQuestionAddEvent = {
        question: tempTrailFinderQuestion.question,
        sortWeight: tempTrailFinderQuestion.sortWeight,
        active: tempTrailFinderQuestion.active,
      }

      // adding a new record
      addTrailFinderQuestion.mutate({ trailFinderQuestion: formattedTrailFinderQuestion })
    } else {
      // identify the properties that have changed
      const didChange = (field: keyof TrailFinderQuestion) => {
        return (
          tempTrailFinderQuestion[field] !== undefined && tempTrailFinderQuestion[field] !== trailFinderQuestion[field]
        )
      }

      const changedProperties: TrailFinderQuestionUpdateEvent = {
        id: trailFinderQuestion.id,
        question: didChange('question') ? tempTrailFinderQuestion.question : undefined,
        sortWeight: didChange('sortWeight') ? tempTrailFinderQuestion.sortWeight : undefined,
        active: didChange('active') ? tempTrailFinderQuestion.active : undefined,
      }

      // edit an existing record
      if (!Object.keys(changedProperties).filter((key) => key !== 'id').length) {
        // nothing changed so just close the dialog
        onCancel()
      } else {
        updateTrailFinderQuestion.mutate({ id: trailFinderQuestion.id, changedProperties })
      }
    }
  }

  useEffect(() => {
    if (saveTrigger) {
      // if outside consumer triggered save, then submit
      handleSubmit()
    }
  }, [saveTrigger])

  const handleCancel = () => {
    if (typeof onCancel === 'function') {
      onCancel()
    }
  }

  const keyDownSubmit = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSubmit()
    }
  }

  return (
    <>
      {isSubmitting && <Loading sx={{ py: 20 }} />}
      {!isSubmitting && (
        <>
          <Stack spacing={2} sx={{ my: 2 }}>
            <DetailInput<TrailFinderQuestion>
              label="Question"
              field="question"
              tempDetail={tempTrailFinderQuestion}
              setTempDetail={setTempTrailFinderQuestion}
              keyDownSubmit={keyDownSubmit}
            />
            <DetailInput<TrailFinderQuestion>
              label="Sort Weight"
              field="sortWeight"
              type="number"
              tempDetail={tempTrailFinderQuestion}
              setTempDetail={setTempTrailFinderQuestion}
              keyDownSubmit={keyDownSubmit}
            />
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="trail-finder-question-active-select-label">Active</InputLabel>
              <Select
                labelId="trail-finder-question-active-select-label"
                id="trail-finder-question-active-select"
                value={tempTrailFinderQuestion.active === false ? 'false' : 'true'}
                label="Active"
                onChange={(e) => {
                  setTempTrailFinderQuestion((prev) => ({ ...prev, active: e.target.value === 'false' ? false : true }))
                }}
              >
                <MenuItem value="true">True</MenuItem>
                <MenuItem value="false">False</MenuItem>
              </Select>
            </FormControl>
          </Stack>
          <Stack direction="row" justifyContent="end" alignItems="center" spacing={2}>
            <Button variant="outlined" onClick={handleCancel}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSubmit}>
              Save
            </Button>
          </Stack>
        </>
      )}
    </>
  )
}
export default TrailFinderQuestionForm

import { useEffect, useMemo, useRef, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import * as d3 from 'd3'
import { useAuth0 } from '@auth0/auth0-react'
import pick from 'lodash/pick'

import { AppLayoutContext } from '../../../providers/AppLayout'
import { useUrlStateParams } from '../../../utils/url'
import { SnackbarContext } from '../../../providers/SnackbarContext'

import { AppPermissions } from '../../../models/AppPermissions.model'
import { TrailSystem, trailSystemFields } from '../../../models/TrailSystem.model'
import { TrailSystemRelations } from '../../../models/TrailSystemRelations.model'

import UserService from '../../../services/UserService/UserService'
import { TrailSystemService } from '../../../services/TrailSystemService/TrailSystemService'

import {
  Box,
  FormControl,
  OutlinedInput,
  InputLabel,
  MenuItem,
  Stack,
  Typography,
  Button,
  InputAdornment,
  IconButton,
  Paper,
  Menu,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import CloseIcon from '@mui/icons-material/Close'
import AddIcon from '@mui/icons-material/Add'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import DataTable from '../../../components/DataTable/DataTable.component'
import Loading from '../../../components/Loading/Loading.component'
import ImportExportDialog, {
  ImportExportDialogProps,
} from '../../../components/ImportExportDialog/ImportExportDialog.component'
import Link from '@mui/material/Link'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import TrailSystemForm from '../shared/TrailSystemForm.component'
import FormDialog from '../../../components/FormDialog/FormDialog.component'
import { AppStateContext } from '../../../providers/AppStateContext'

type TrailSystemSearchProps = {
  nested?: boolean
}
const TrailSystemSearch = (props: TrailSystemSearchProps): JSX.Element => {
  const { nested } = props
  const [isSaving, setIsSaving] = useState(false)

  const { user } = useAuth0()
  const formattedUser = useMemo(() => (user ? UserService.formatUser(user) : undefined), [user])
  const hasWritePermissions = UserService.hasPermissions(user, [AppPermissions.editTrailSystem])
  const hasTrailPermissions = UserService.hasPermissions(user, [AppPermissions.viewTrail])

  const [searchError, setSearchError] = useState('')
  const [rowActionsAnchorEl, setRowActionsAnchorEl] = useState<null | HTMLElement>(null)
  const isActionsMenuOpen = Boolean(rowActionsAnchorEl)
  const [selectedRow, setSelectedRow] = useState<TrailSystem | undefined>()
  const [isAdding, setIsAdding] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false)

  const [name, setName] = useUrlStateParams(
    '',
    'name',
    (value) => encodeURIComponent(value.toString()),
    (value) => (value ? decodeURIComponent(value) : ''),
  )
  const nameInputRef = useRef<HTMLInputElement>()
  const [orderBy, setOrderBy] = useState<keyof TrailSystem | undefined>('name')
  const [orderDirection, setOrderDirection] = useState<'asc' | 'desc' | undefined>('asc')
  const [page, setPage] = useUrlStateParams(
    1,
    'page',
    (value) => value.toString(),
    (value) => (!isNaN(Number(value)) ? Number(value) : 1),
  )
  const [pageSize, setPageSize] = useUrlStateParams(
    12,
    'page-size',
    (value) => value.toString(),
    (value) => (!isNaN(Number(value)) ? Number(value) : 12),
  )

  const [isImportExportOpen, setIsImportExportOpen] = useState(false)

  const trailSystemService = useMemo(() => new TrailSystemService(), []) // memo to prevent re-creating service on every render

  useEffect(() => {
    if (!nested) {
      AppLayoutContext.setPageName('Trail Systems')
    }
  }, [])

  const queryClient = useQueryClient()
  const { isLoading, data: trailSystemsResponse } = useQuery(
    [
      'TrailSystemService.search',
      {
        page,
        pageSize,
        name,
        orderBy,
        orderDirection,
        relations: [TrailSystemRelations.trails],
      },
      AppStateContext.getRegion(),
    ],
    () =>
      trailSystemService.search({
        page,
        pageSize,
        name,
        orderBy,
        orderDirection,
        relations: [TrailSystemRelations.trails],
      }),
    {
      onError: (err) => {
        setSearchError(typeof err === 'string' ? err : 'An error occurred while searching for trail systems.')
        console.error(err)
      },
    },
  )

  useEffect(() => {
    if (
      trailSystemsResponse &&
      page > 1 &&
      (!trailSystemsResponse.total || (page - 1) * pageSize >= trailSystemsResponse.total)
    ) {
      // page out of range was requested so reset to page 1
      setPage(1)
    }
  }, [trailSystemsResponse])

  const handleRowActionsClick = (event: React.MouseEvent<HTMLButtonElement>, trailSystem: TrailSystem) => {
    setRowActionsAnchorEl(event.currentTarget)
    setSelectedRow(trailSystem)
  }
  const handleRowActionsClose = () => {
    setRowActionsAnchorEl(null)
    setSelectedRow(undefined)
  }

  const columns = useMemo(() => {
    const columns: GridColDef<TrailSystem>[] = [
      {
        field: 'actions',
        headerName: '',
        width: 60,
        sortable: false,
        renderCell: (params) => (
          <>
            <IconButton
              id={`row-actions-button-${params.row.id}`}
              color="primary"
              aria-label="row actions"
              aria-controls={selectedRow?.id === params.row.id ? 'row-actions-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={selectedRow?.id === params.row.id ? 'true' : undefined}
              onClick={(event) => handleRowActionsClick(event, params.row)}
            >
              <MoreVertIcon />
            </IconButton>
          </>
        ),
      },
      {
        field: 'id',
        headerName: 'ID',
        width: 100,
        renderCell: (params) => (
          <Link color="primary" aria-label="trail system detail" href={`/trail-systems/${params.row.id}`}>
            {params.row.id}
          </Link>
        ),
      },
      {
        field: 'name',
        headerName: 'Name',
        width: 400,
        renderCell: (params) => (
          <Link color="primary" aria-label="trail system detail" href={`/trail-systems/${params.row.id}`}>
            {params.row.name}
          </Link>
        ),
      },
      {
        field: 'city',
        headerName: 'City',
        width: 200,
      },
      {
        field: 'trails',
        headerName: 'Trails',
        sortable: false, // cant sort by relational fields
        width: 200,
        renderCell: (params) =>
          params.row.trails?.length ? (
            hasTrailPermissions ? (
              <Link
                color="primary"
                aria-label="trail system trails"
                href={`/trails?trail-system=${params.row.id}`}
                target="_blank"
              >
                <Stack direction="row" alignItems="center">
                  <Box component="span" sx={{ mr: 1 }}>
                    {params.row.trails?.length} Trails
                  </Box>
                  <OpenInNewIcon fontSize="inherit" />
                </Stack>
              </Link>
            ) : (
              `${params.row.trails?.length} Trails`
            )
          ) : (
            '0 Trails'
          ),
      },
    ]
    return columns
  }, [hasTrailPermissions])

  const handleSubmit = () => {
    setPage(1)
    setName(nameInputRef?.current?.value || '')
  }

  const handleAddClick = () => {
    setIsAdding(true)
  }

  const handleAddCancel = () => {
    setIsAdding(false)
  }

  const handleAddSubmit = () => {
    setIsAdding(false)
  }

  const handleEditClick = () => {
    setIsEditing(true)
  }

  const handleEditCancel = () => {
    setRowActionsAnchorEl(null)
    setSelectedRow(undefined)
    setIsEditing(false)
  }

  const handleEditSubmit = () => {
    setRowActionsAnchorEl(null)
    setSelectedRow(undefined)
    setIsEditing(false)
  }

  const handleDeleteClick = () => {
    // show a confirmation dialog before deleting
    setIsDeleteConfirmationOpen(true)
  }

  const handleDeleteConfirmationClose = () => {
    setIsDeleteConfirmationOpen(false)
    handleRowActionsClose()
  }

  const deleteTrailSystem = useMutation(
    (mutationParams: { id: number }) => {
      setIsSaving(true)
      return trailSystemService.delete(mutationParams.id)
    },
    {
      onSuccess: () => {
        SnackbarContext.show('Trail System deleted successfully!')
        setRowActionsAnchorEl(null)
        setSelectedRow(undefined)
        // invalidate all queries to ensure any queries that are using the modified record are updated
        queryClient.invalidateQueries()
        setIsSaving(false)
      },
      onError: (err) => {
        SnackbarContext.show(`Trail System failed to delete: ${err}`, 'error')
        console.error(err)
        setIsSaving(false)
      },
    },
  )

  const handleDelete = () => {
    if (selectedRow?.id) {
      setIsDeleteConfirmationOpen(false)
      deleteTrailSystem.mutate({ id: selectedRow.id })
    }
  }

  const openImportExport = () => {
    setIsImportExportOpen(true)
  }

  const handleImportExportClose = () => {
    setIsImportExportOpen(false)
  }

  const handleImport: ImportExportDialogProps['onImport'] = (data: Partial<TrailSystem>[], options) => {
    setIsImportExportOpen(false)
    if (!data) {
      SnackbarContext.show('Failed to read/parse file', 'error')
      console.error('Failed to read/parse file')
    } else {
      setIsSaving(true)
      trailSystemService
        .import(data, options)
        .then(() => {
          SnackbarContext.show('Trail Systems imported successfully!')

          // fetch new data
          setSearchError('')
          // invalidate all queries to ensure any queries that are using the modified record are updated
          queryClient.invalidateQueries()
          setIsSaving(false)
        })
        .catch((err) => {
          SnackbarContext.show(`Trail System failed to import: ${err}`, 'error')
          console.error(err)
          setIsSaving(false)
        })
    }
  }

  const handleExport = () => {
    // do a request to the API to get the data to export with no pagination
    setIsSaving(true)
    setIsImportExportOpen(false)
    trailSystemService
      .search({ pageSize: 0, name, orderBy, orderDirection, relations: [] })
      .then((results) => {
        const exportData = results.data.map((trailSystem) => {
          const trailSystemToExport: Record<string, unknown> = { ...trailSystem }
          delete trailSystemToExport.trails
          // set a prop for weatherGroup's or displayWeatherGroup's id and name and remove the weatherGroup object props
          trailSystemToExport.weatherGroupId = trailSystem.weatherGroup?.id
          trailSystemToExport.weatherGroupName = trailSystem.weatherGroup?.name
          trailSystemToExport.displayWeatherGroupId = trailSystem.displayWeatherGroup?.id
          trailSystemToExport.displayWeatherGroupName = trailSystem.displayWeatherGroup?.name
          delete trailSystemToExport.weatherGroup
          delete trailSystemToExport.displayWeatherGroup

          trailSystemToExport.updatedAt =
            trailSystemToExport.updatedAt instanceof Date ? trailSystemToExport.updatedAt.toISOString() : ''
          trailSystemToExport.createdAt =
            trailSystemToExport.createdAt instanceof Date ? trailSystemToExport.createdAt.toISOString() : ''

          // return the object with the props in the order we want them to appear in the csv
          const propsToExport: string[] = [...trailSystemFields]
          // add system and trail props after id
          propsToExport.splice(
            propsToExport.indexOf('region') + 1,
            0,
            'weatherGroupId',
            'weatherGroupName',
            'displayWeatherGroupId',
            'displayWeatherGroupName',
          )
          return pick(trailSystemToExport, propsToExport)
        })
        const csvData = d3.csvFormat(exportData)
        // initiate a download of the csv file
        const blob = new Blob([csvData], { type: 'text/csv' })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')

        link.setAttribute('href', url)
        link.setAttribute('download', 'trail-systems.csv')
        link.click()
        setIsSaving(false)
      })
      .catch((err) => {
        SnackbarContext.show(`Failed to fetch trail systems for export: ${err}`, 'error')
        console.error(err)
      })
  }

  return (
    <>
      {selectedRow && (
        <FormDialog<TrailSystem>
          open={isEditing}
          entity={selectedRow}
          entityLabel="Trail System"
          onClose={handleEditCancel}
        >
          <TrailSystemForm trailSystem={selectedRow} onEdit={handleEditSubmit} onCancel={handleEditCancel} />
        </FormDialog>
      )}
      <FormDialog<TrailSystem> open={isAdding} entityLabel="Trail System" onClose={handleAddCancel}>
        <TrailSystemForm onAdd={handleAddSubmit} onCancel={handleAddCancel} />
      </FormDialog>
      <Dialog fullWidth maxWidth="xs" open={isDeleteConfirmationOpen} onClose={handleDeleteConfirmationClose}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent dividers>
          Are you sure you want to delete trail system id {selectedRow?.id}: {selectedRow?.name}?
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleDeleteConfirmationClose}>
            Cancel
          </Button>
          <Button onClick={handleDelete}>Confirm</Button>
        </DialogActions>
      </Dialog>
      <ImportExportDialog
        open={isImportExportOpen}
        disableImport={!!formattedUser?.trailSystems.length || !hasWritePermissions}
        exampleImportFileUrl="/example-import-files/trail-systems.csv"
        importFields={[
          ...trailSystemFields.filter(
            (field) => !['weatherGroup', 'displayWeatherGroup', 'updatedAt', 'createdAt'].includes(field),
          ),
          'weatherGroupId',
          'displayWeatherGroupId',
        ]}
        onImport={handleImport}
        onExport={handleExport}
        onClose={handleImportExportClose}
      />
      <Paper sx={{ p: 2 }}>
        {(isLoading || isSaving) && !searchError && <Loading sx={{ py: 20 }} />}
        {searchError && (
          <Stack direction="row" justifyContent="center" alignItems="center" sx={{ py: 20 }}>
            <Typography variant="error">{searchError}</Typography>
          </Stack>
        )}
        {!isLoading && !isSaving && !searchError && (
          <>
            <Box sx={{ pt: 1, mb: 1 }}>
              <Stack direction={{ zero: 'column', md: 'row' }} spacing={1}>
                <FormControl variant="outlined" sx={{ width: '100%' }}>
                  <InputLabel>Name</InputLabel>
                  <OutlinedInput
                    id="search-name"
                    label="Name"
                    defaultValue={name || ''}
                    inputRef={nameInputRef}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleSubmit()
                      }
                    }}
                    endAdornment={
                      nameInputRef?.current?.value ? (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="clear search name"
                            onClick={() => {
                              if (nameInputRef?.current?.value) {
                                nameInputRef.current.value = ''
                              }
                              setPage(1)
                              setName('')
                            }}
                            edge="end"
                          >
                            <CloseIcon />
                          </IconButton>
                        </InputAdornment>
                      ) : undefined
                    }
                  />
                </FormControl>

                <Button
                  variant="outlined"
                  onClick={handleSubmit}
                  sx={(theme) => ({ [theme.breakpoints.up('md')]: { width: 150 }, maxWidth: '100%' })}
                >
                  Search
                </Button>
              </Stack>
              <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1} sx={{ mt: 1 }}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  {formattedUser && !formattedUser.trailSystems.length && hasWritePermissions && (
                    <Button
                      variant="outlined"
                      startIcon={<AddIcon />}
                      color="primary"
                      aria-label="add new"
                      onClick={handleAddClick}
                    >
                      Add
                    </Button>
                  )}
                  <Button
                    variant="outlined"
                    startIcon={<ImportExportIcon />}
                    color="primary"
                    aria-label="show import export"
                    onClick={() => {
                      openImportExport()
                    }}
                  >
                    {formattedUser && !formattedUser.trailSystems.length ? 'Import / ' : ''}Export
                  </Button>
                </Stack>
                <Button
                  variant="text"
                  size="small"
                  onClick={() => {
                    if (nameInputRef?.current?.value) {
                      nameInputRef.current.value = ''
                    }
                    setPage(1)
                    setName('')
                  }}
                >
                  Clear
                </Button>
              </Stack>
            </Box>

            {trailSystemsResponse?.data && trailSystemsResponse.data.length > 0 ? (
              <>
                <Menu
                  id="row-actions-menu"
                  anchorEl={rowActionsAnchorEl}
                  open={isActionsMenuOpen}
                  onClose={handleRowActionsClose}
                  MenuListProps={{
                    'aria-labelledby': `row-actions-button${selectedRow?.id}`,
                  }}
                >
                  <MenuItem href={`/trail-systems/${selectedRow?.id}`} component={Link}>
                    View
                  </MenuItem>
                  {hasWritePermissions && <MenuItem onClick={handleEditClick}>Edit</MenuItem>}
                  {formattedUser && !formattedUser.trailSystems.length && hasWritePermissions && (
                    <MenuItem onClick={handleDeleteClick}>Delete</MenuItem>
                  )}
                </Menu>
                <DataTable
                  columns={columns}
                  data={trailSystemsResponse.data}
                  total={trailSystemsResponse.total}
                  paginationMode="server"
                  page={page}
                  pageSize={pageSize}
                  onPageChange={(newPage) => {
                    setPage(newPage)
                  }}
                  onPageSizeChange={(newPageSize) => {
                    setPageSize(newPageSize)
                  }}
                  orderBy={orderBy}
                  orderDirection={orderDirection}
                  onOrderChange={(newOrder) => {
                    setOrderBy(newOrder.field ? (newOrder.field as keyof TrailSystem) : undefined)
                    setOrderDirection(newOrder.sort)
                  }}
                />
              </>
            ) : (
              <Box sx={{ p: 2 }}>
                <Typography>No results found</Typography>
              </Box>
            )}
          </>
        )}
      </Paper>
    </>
  )
}
export default TrailSystemSearch

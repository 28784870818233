import { useLocation } from 'react-router-dom'

import { Box, Paper, Typography } from '@mui/material'

interface NotFoundState {
  originalUrl?: string
}

const NotFound = (): JSX.Element => {
  const location = useLocation()
  const { originalUrl } = (location.state || {}) as NotFoundState

  return (
    <Paper sx={{ p: 2 }}>
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="h1" component="h1">
          404
        </Typography>
        <Typography variant="h3" component="h2">
          Page not found
        </Typography>
        {originalUrl && (
          <>
            <Typography variant="h5" component="p" sx={{ mt: 3 }}>
              The requested URL
            </Typography>
            <Typography
              variant="h5"
              component="p"
              sx={{
                color: (theme) => theme.palette.error.main,
              }}
            >
              <code>{originalUrl}</code>
            </Typography>
            <Typography variant="h5" component="p">
              was not found.
            </Typography>
          </>
        )}
      </Box>
    </Paper>
  )
}

export default NotFound

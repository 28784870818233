import { formatToNumberTypeProps } from '../../utils/formatting'

import { PaginatedResponse } from '../../models/PaginatedResponse.model'
import { TrailFinderAnswer } from '../../models/TrailFinderAnswer.model'
import { TrailFinderAnswerDTO } from '../../models/TrailFinderAnswerDTO.model'
import { TrailFinderAnswerAddEvent } from '../../events/TrailFinderAnswerAddEvent'
import { TrailFinderAnswerUpdateEvent } from '../../events/TrailFinderAnswerUpdateEvent'
import { TrailFinderAnswerSearchEvent } from '../../events/TrailFinderAnswerSearchEvent'
import { TrailFinderAnswerRelations } from '../../models/TrailFinderAnswerRelations.model'

import FlowfeedApiQueryService, {
  FlowfeedApiQueryServiceGetEvent,
} from '../FlowfeedApiQueryService/FlowfeedApiQueryService'
import { TrailFinderQuestionService } from '../TrailFinderQuestionService/TrailFinderQuestionService'

export class TrailFinderAnswerService {
  /**
   * @description Search for a single trailFinderAnswer by id
   *
   * @param id
   * @param relations
   *
   * @returns promise for a TrailFinderAnswer
   */
  public async get({
    id,
    relations,
  }: {
    id?: number
    relations?: TrailFinderAnswerRelations[]
  }): Promise<TrailFinderAnswer> {
    const params = {
      id,
      relations:
        Array.isArray(relations) && !relations.length
          ? 'false' // false is treated as an empty array by the API since an empty string would be treated as default relations normally
          : relations?.join(',') || undefined,
    }
    const flowfeedApiQueryRequest: FlowfeedApiQueryServiceGetEvent = {
      endpoint: `trail-finder-answer/get`,
      params,
    }

    const flowfeedApiQueryService = new FlowfeedApiQueryService()
    try {
      const trailFinderAnswerRaw = await flowfeedApiQueryService.get<TrailFinderAnswerDTO>(flowfeedApiQueryRequest)
      return TrailFinderAnswerService.formatTrailFinderAnswer(trailFinderAnswerRaw)
    } catch (error) {
      console.error(error)
      throw 'Sorry, there was a problem with your request.'
    }
  }

  public async search(event: TrailFinderAnswerSearchEvent): Promise<PaginatedResponse<TrailFinderAnswer>> {
    const params = {
      ...event,
      answer: event.answer || undefined,
      orderDirection: event.orderDirection?.toUpperCase(),
      relations:
        Array.isArray(event.relations) && !event.relations.length
          ? 'false' // false is treated as an empty array by the API since an empty string would be treated as default relations normally
          : event.relations?.join(',') || undefined,
    }

    const flowfeedApiQueryRequest: FlowfeedApiQueryServiceGetEvent = {
      endpoint: 'trail-finder-answer',
      params,
    }

    const trailFinderAnswers: {
      data: TrailFinderAnswer[]
      total: number
    } = {
      data: [],
      total: 0,
    }
    try {
      const flowfeedApiQueryService = new FlowfeedApiQueryService()
      const rawTrailFinderAnswers = await flowfeedApiQueryService.get<PaginatedResponse<TrailFinderAnswerDTO>>(
        flowfeedApiQueryRequest,
      )
      // wrap to avoid errors if the request is cancelled
      if (rawTrailFinderAnswers?.data) {
        trailFinderAnswers.data = rawTrailFinderAnswers.data.map((trailFinderAnswer) =>
          TrailFinderAnswerService.formatTrailFinderAnswer(trailFinderAnswer),
        )
        trailFinderAnswers.total = rawTrailFinderAnswers.total
      }
    } catch (error) {
      console.error(error)
      throw 'Sorry, there was a problem with your request.'
    }

    return trailFinderAnswers
  }

  public async add(trailFinderAnswer: TrailFinderAnswerAddEvent): Promise<TrailFinderAnswer> {
    const flowfeedApiQueryRequest = {
      endpoint: 'trail-finder-answer',
      data: trailFinderAnswer,
    }

    const flowfeedApiQueryService = new FlowfeedApiQueryService()
    try {
      const trailFinderAnswerRaw = await flowfeedApiQueryService.post<TrailFinderAnswerDTO>(flowfeedApiQueryRequest)
      return TrailFinderAnswerService.formatTrailFinderAnswer(trailFinderAnswerRaw)
    } catch (error) {
      console.error(error)
      if (error instanceof Error) {
        throw error.message
      } else if ((error as { data: { message: string } })?.data?.message) {
        throw (error as { data: { message: string } })?.data?.message
      }
      throw error
    }
  }

  public async update(id: number, trailFinderAnswer: TrailFinderAnswerUpdateEvent): Promise<TrailFinderAnswer> {
    const flowfeedApiQueryRequest = {
      endpoint: `trail-finder-answer/${id}`,
      data: trailFinderAnswer,
    }
    try {
      const flowfeedApiQueryService = new FlowfeedApiQueryService()
      const trailFinderAnswerRaw = await flowfeedApiQueryService.patch<TrailFinderAnswerDTO>(flowfeedApiQueryRequest)
      return TrailFinderAnswerService.formatTrailFinderAnswer(trailFinderAnswerRaw)
    } catch (error) {
      console.error(error)
      if (error instanceof Error) {
        throw error.message
      } else if ((error as { data: { message: string } })?.data?.message) {
        throw (error as { data: { message: string } })?.data?.message
      }
      throw error
    }
  }

  public async delete(id: number): Promise<void> {
    const flowfeedApiQueryRequest = {
      endpoint: `trail-finder-answer/${id}`,
    }

    try {
      const flowfeedApiQueryService = new FlowfeedApiQueryService()
      await flowfeedApiQueryService.delete(flowfeedApiQueryRequest)
    } catch (error) {
      console.error(error)
      if (error instanceof Error) {
        throw error.message
      } else if ((error as { data: { message: string } })?.data?.message) {
        throw (error as { data: { message: string } })?.data?.message
      }
      throw error
    }
  }

  public async import(
    trailFinderAnswers: (Partial<TrailFinderAnswer> & { questionId?: number })[],
    options?: {
      matchingField?: string | undefined
      deleteUnmatchedExisting?: boolean
    },
  ): Promise<void> {
    const data = trailFinderAnswers.map((trailFinderAnswer) => {
      const numberTypeProps = ['id', 'sortWeight', 'questionId']
      const formattedSponsor = formatToNumberTypeProps(trailFinderAnswer, numberTypeProps)
      return formattedSponsor
    })
    const flowfeedApiQueryRequest = {
      endpoint: `trail-finder-answer/sync`,
      data: {
        data,
        matchingProp: options?.matchingField,
        deleteMissing: options?.deleteUnmatchedExisting,
      },
    }

    try {
      const flowfeedApiQueryService = new FlowfeedApiQueryService()
      await flowfeedApiQueryService.post(flowfeedApiQueryRequest)
    } catch (error) {
      console.error(error)
      if (error instanceof Error) {
        throw error.message
      } else if ((error as { data: { message: string } })?.data?.message) {
        throw (error as { data: { message: string } })?.data?.message
      }
      throw error
    }
  }

  public static formatTrailFinderAnswer(trailFinderAnswer: TrailFinderAnswerDTO): TrailFinderAnswer {
    return {
      id: trailFinderAnswer.id,
      region: trailFinderAnswer.region,
      answer: trailFinderAnswer.answer,
      affectedFilters: trailFinderAnswer.affectedFilters,
      question: trailFinderAnswer.question
        ? TrailFinderQuestionService.formatTrailFinderQuestion(trailFinderAnswer.question)
        : undefined,
      sortWeight: trailFinderAnswer.sortWeight,
      active: trailFinderAnswer.active,
      updatedAt: new Date(trailFinderAnswer.updatedAt),
      createdAt: new Date(trailFinderAnswer.createdAt),
    }
  }
}

import { useState } from 'react'

import { Trail } from '../../models/Trail.model'
import { weatherResistanceBreakpoints } from '../../models/WeatherResistanceBreakpoints.model'

import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Stack,
} from '@mui/material'
import Typography from '@mui/material/Typography'
import { TrailStatusService } from '../../services/TrailStatusService/TrailStatusService'
import { SnackbarContext } from '../../providers/SnackbarContext'
import Loading from '../Loading/Loading.component'

type AlgoVisualizerProps = {
  trail: Trail
}
const AlgoVisualizer = (props: AlgoVisualizerProps) => {
  const { trail } = props

  const [isLoading, setIsLoading] = useState(false)

  const [weatherResistance, setWeatherResistance] = useState(trail.weatherResistance || 5)
  const [saturationModifier, setSaturationModifier] = useState(trail.saturationModifier || 100)
  // TODO: init these to the actual values from the trail's current status
  const [precipDecayed, setPrecipDecayed] = useState(0)
  const [precipLastSevenDays, setPrecipLastSevenDays] = useState(0)

  const [trailStatus, setTrailStatus] = useState<1 | 2 | 3>(3)

  const handleSubmit = async () => {
    try {
      setIsLoading(true)
      const newTrailStatus = await TrailStatusService.statusFromWeatherResistance({
        weatherResistance,
        precipDecayed,
        precipLastSevenDays,
        saturationModifier,
      })
      setTrailStatus(newTrailStatus)
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
      SnackbarContext.show('Failed to fetch trail status', 'error')
    }
  }

  const keyDownSubmit = async (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      await handleSubmit()
    }
  }

  return (
    <Paper sx={{ py: 4, px: 2 }}>
      {isLoading && <Loading sx={{ height: 325 }} />}
      {!isLoading && (
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <Stack spacing={2} flex={6}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="algo-visualizer-weather-resistance-select-label">Weather Resistance</InputLabel>
              <Select
                labelId="algo-visualizer-weather-resistance-select-label"
                id="algo-visualizer-weather-resistance-select"
                label="Weather Resistance"
                value={weatherResistance}
                onChange={(event) => setWeatherResistance(Number(event.target.value) as typeof weatherResistance)}
              >
                {weatherResistanceBreakpoints.map((eachBreakpoint, index) => (
                  <MenuItem key={`${eachBreakpoint[0]}_${eachBreakpoint[1]}_${index}`} value={index + 1}>
                    {index + 1} - (
                    {index < 10
                      ? ` ${eachBreakpoint[0]} mm to ${eachBreakpoint[1]} mm `
                      : {
                          10: ' 50 mm Fair but never Rutty ',
                          11: ' Always Firm ',
                        }[index]}
                    )
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl variant="outlined" fullWidth>
              <InputLabel id="algo-visualizer-decayed-precipitation-label">Decayed Precipitation</InputLabel>
              <OutlinedInput
                id="algo-visualizer-decayed-precipitation"
                label="Decayed Precipitation"
                value={precipDecayed}
                type="number"
                onChange={(e) => {
                  setPrecipDecayed(Number(e.target.value))
                }}
                onKeyDown={keyDownSubmit}
              />
            </FormControl>

            <FormControl variant="outlined" fullWidth>
              <InputLabel id="algo-visualizer-seven-day-precipitation-label">7 Day Precipitation</InputLabel>
              <OutlinedInput
                id="algo-visualizer-seven-day-precipitation"
                label="7 Day Precipitation"
                value={precipLastSevenDays}
                type="number"
                onChange={(e) => {
                  setPrecipLastSevenDays(Number(e.target.value))
                }}
                onKeyDown={keyDownSubmit}
              />
            </FormControl>

            <FormControl variant="outlined" fullWidth>
              <InputLabel id="algo-visualizer-saturation-modifier-label">Saturation Modifier %</InputLabel>
              <OutlinedInput
                id="algo-visualizer-saturation-modifier"
                label="Saturation Modifier %"
                value={saturationModifier !== undefined ? saturationModifier * 100 : 100}
                type="number"
                onChange={(e) => {
                  setSaturationModifier(Number(e.target.value) / 100)
                }}
                onKeyDown={keyDownSubmit}
              />
            </FormControl>

            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Run Algorithm
            </Button>
          </Stack>

          <Stack direction="row" justifyContent="center" alignItems="start" flex={6}>
            <Box sx={{ display: 'inline-block', borderRadius: 2, background: '#404040', p: 1, minWidth: 200 }}>
              <Typography variant="h6" component="div" sx={{ color: 'white' }}>
                {trail.name}
              </Typography>
              <Typography variant="body1" component="div" sx={{ color: 'white' }}>
                {trail.distance} miles
              </Typography>
              <Stack direction="row" justifyContent="center" spacing={2} sx={{ my: 2 }}>
                <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                  <CircularProgress
                    variant="determinate"
                    value={100}
                    size={110}
                    color={
                      {
                        1: 'error',
                        2: 'warning',
                        3: 'success',
                      }[trailStatus] as 'error' | 'warning' | 'success'
                    }
                  />
                  <Box
                    sx={{
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      position: 'absolute',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography variant="h6" component="div">
                      {
                        {
                          1: 'Rutty',
                          2: 'Fair',
                          3: 'Firm',
                        }[trailStatus]
                      }
                    </Typography>
                  </Box>
                </Box>
              </Stack>
            </Box>
          </Stack>
        </Stack>
      )}
    </Paper>
  )
}
export default AlgoVisualizer

import { useEffect, useState } from 'react'

import {
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
} from '@mui/material'
import UndoIcon from '@mui/icons-material/Undo'

type DetailSelectWithCreateNewProps<T> = {
  label: string
  field: keyof T
  options: string[]
  required?: boolean
  helperText?: string
  tempDetail: Partial<T>
  setTempDetail: React.Dispatch<React.SetStateAction<Partial<T>>>
  keyDownSubmit: (e: React.KeyboardEvent) => void
}
function DetailSelectWithCreateNew<T>(props: DetailSelectWithCreateNewProps<T>) {
  const { label, field, required = false, helperText, options = [], tempDetail, setTempDetail, keyDownSubmit } = props

  const [tempSelectOption, setTempSelectOption] = useState<string | undefined>(tempDetail[field] as string)
  const [isCreatingNew, setIsCreatingNew] = useState<boolean>(false)

  useEffect(() => {
    if (tempSelectOption === 'detailSelectCreateNew') {
      // show the create new input
      setIsCreatingNew(true)
      setTempDetail((prev) => ({ ...prev, [field]: '' }))
    } else {
      setIsCreatingNew(false)
      setTempDetail((prev) => ({ ...prev, [field]: tempSelectOption }))
    }
  }, [tempSelectOption])

  return (
    <Stack>
      {!isCreatingNew ? (
        <FormControl variant="outlined" fullWidth>
          <InputLabel id={`detail-select-label-${String(field)}`}>{label}</InputLabel>
          <Select
            labelId={`detail-select-label-${String(field)}`}
            id={`detail-select-${String(field)}`}
            value={tempDetail[field] || ''}
            label={label}
            onChange={(e) => {
              setTempSelectOption(e.target.value as string)
            }}
          >
            {!required && <MenuItem value="">-Select-</MenuItem>}
            {options.map((eachOption, index) => {
              return (
                <MenuItem key={`${eachOption}_${index}`} value={eachOption}>
                  {eachOption}
                </MenuItem>
              )
            })}
            <Divider />
            <MenuItem value="detailSelectCreateNew">Create New</MenuItem>
          </Select>
        </FormControl>
      ) : (
        <Stack direction="row" alignItems="center" spacing={1}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>New {label}</InputLabel>
            <OutlinedInput
              id={`detail-create-new-input-${String(field)}`}
              label={`New ${label}`}
              value={tempDetail[field] || ''}
              type="text"
              required={required}
              autoFocus
              onChange={(e) => {
                setTempDetail((prev) => ({ ...prev, [field]: e.target.value }))
              }}
              onKeyDown={keyDownSubmit}
            />
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
          </FormControl>
          <IconButton
            onClick={() => {
              setTempDetail((prev) => ({ ...prev, [field]: required ? options[0] : '' }))
              setTempSelectOption(required ? options[0] : '')
              setIsCreatingNew(false)
            }}
          >
            <UndoIcon />
          </IconButton>
        </Stack>
      )}
    </Stack>
  )
}
export default DetailSelectWithCreateNew

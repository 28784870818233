import { PaginatedResponse } from '../../models/PaginatedResponse.model'
import { TrailFeature } from '../../models/TrailFeature.model'
import { TrailFeatureDTO } from '../../models/TrailFeatureDTO.model'
import { TrailFeatureAddEvent } from '../../events/TrailFeatureAddEvent'
import { TrailFeatureUpdateEvent } from '../../events/TrailFeatureUpdateEvent'
import { TrailFeatureSearchEvent } from '../../events/TrailFeatureSearchEvent'
import { TrailFeatureRelations } from '../../models/TrailFeatureRelations.model'

import FlowfeedApiQueryService, {
  FlowfeedApiQueryServiceGetEvent,
} from '../FlowfeedApiQueryService/FlowfeedApiQueryService'
import { TrailService } from '../TrailService/TrailService'
import { formatToNumberTypeProps } from '../../utils/formatting'

export class TrailFeatureService {
  /**
   * @description Search for a single trailFeature by id
   *
   * @param id
   * @param relations
   *
   * @returns promise for a TrailFeature
   */
  public async get({ id, relations }: { id?: number; relations?: TrailFeatureRelations[] }): Promise<TrailFeature> {
    const params = {
      id,
      relations:
        Array.isArray(relations) && !relations.length
          ? 'false' // false is treated as an empty array by the API since an empty string would be treated as default relations normally
          : relations?.join(',') || undefined,
    }
    const flowfeedApiQueryRequest: FlowfeedApiQueryServiceGetEvent = {
      endpoint: `trail-feature/get`,
      params,
    }

    const flowfeedApiQueryService = new FlowfeedApiQueryService()
    try {
      const trailFeatureRaw = await flowfeedApiQueryService.get<TrailFeatureDTO>(flowfeedApiQueryRequest)
      return TrailFeatureService.formatTrailFeature(trailFeatureRaw)
    } catch (error) {
      console.error(error)
      throw 'Sorry, there was a problem with your request.'
    }
  }

  public async search(event: TrailFeatureSearchEvent): Promise<PaginatedResponse<TrailFeature>> {
    const params = {
      ...event,
      name: event.name || undefined,
      orderDirection: event.orderDirection?.toUpperCase(),
      relations:
        Array.isArray(event.relations) && !event.relations.length
          ? 'false' // false is treated as an empty array by the API since an empty string would be treated as default relations normally
          : event.relations?.join(',') || undefined,
    }

    const flowfeedApiQueryRequest: FlowfeedApiQueryServiceGetEvent = {
      endpoint: 'trail-feature',
      params,
    }

    const trailFeatures: {
      data: TrailFeature[]
      total: number
    } = {
      data: [],
      total: 0,
    }
    try {
      const flowfeedApiQueryService = new FlowfeedApiQueryService()
      const rawTrailFeatures = await flowfeedApiQueryService.get<PaginatedResponse<TrailFeatureDTO>>(
        flowfeedApiQueryRequest,
      )
      // wrap to avoid errors if the request is cancelled
      if (rawTrailFeatures?.data) {
        trailFeatures.data = rawTrailFeatures.data.map((trailFeature) =>
          TrailFeatureService.formatTrailFeature(trailFeature),
        )
        trailFeatures.total = rawTrailFeatures.total
      }
    } catch (error) {
      console.error(error)
      throw 'Sorry, there was a problem with your request.'
    }

    return trailFeatures
  }

  public async add(trailFeature: TrailFeatureAddEvent): Promise<TrailFeature> {
    const flowfeedApiQueryRequest = {
      endpoint: 'trail-feature',
      data: trailFeature,
    }

    const flowfeedApiQueryService = new FlowfeedApiQueryService()
    try {
      const trailFeatureRaw = await flowfeedApiQueryService.post<TrailFeatureDTO>(flowfeedApiQueryRequest)
      return TrailFeatureService.formatTrailFeature(trailFeatureRaw)
    } catch (error) {
      console.error(error)
      if (error instanceof Error) {
        throw error.message
      } else if ((error as { data: { message: string } })?.data?.message) {
        throw (error as { data: { message: string } })?.data?.message
      }
      throw error
    }
  }

  public async update(id: number, trailFeature: TrailFeatureUpdateEvent): Promise<TrailFeature> {
    const flowfeedApiQueryRequest = {
      endpoint: `trail-feature/${id}`,
      data: trailFeature,
    }
    try {
      const flowfeedApiQueryService = new FlowfeedApiQueryService()
      const trailFeatureRaw = await flowfeedApiQueryService.patch<TrailFeatureDTO>(flowfeedApiQueryRequest)
      return TrailFeatureService.formatTrailFeature(trailFeatureRaw)
    } catch (error) {
      console.error(error)
      if (error instanceof Error) {
        throw error.message
      } else if ((error as { data: { message: string } })?.data?.message) {
        throw (error as { data: { message: string } })?.data?.message
      }
      throw error
    }
  }

  public async delete(id: number): Promise<void> {
    const flowfeedApiQueryRequest = {
      endpoint: `trail-feature/${id}`,
    }

    try {
      const flowfeedApiQueryService = new FlowfeedApiQueryService()
      await flowfeedApiQueryService.delete(flowfeedApiQueryRequest)
    } catch (error) {
      console.error(error)
      if (error instanceof Error) {
        throw error.message
      } else if ((error as { data: { message: string } })?.data?.message) {
        throw (error as { data: { message: string } })?.data?.message
      }
      throw error
    }
  }

  public async import(
    trailFeatures: Partial<TrailFeature>[],
    options?: {
      matchingField?: string | undefined
      deleteUnmatchedExisting?: boolean
    },
  ): Promise<void> {
    const data = trailFeatures.map((trailFeature) => {
      const numberTypeProps = ['id']
      const formattedSponsor = formatToNumberTypeProps(trailFeature, numberTypeProps)
      return formattedSponsor
    })
    const flowfeedApiQueryRequest = {
      endpoint: `trail-feature/sync`,
      data: {
        data,
        matchingProp: options?.matchingField,
        deleteMissing: options?.deleteUnmatchedExisting,
      },
    }

    try {
      const flowfeedApiQueryService = new FlowfeedApiQueryService()
      await flowfeedApiQueryService.post(flowfeedApiQueryRequest)
    } catch (error) {
      console.error(error)
      if (error instanceof Error) {
        throw error.message
      } else if ((error as { data: { message: string } })?.data?.message) {
        throw (error as { data: { message: string } })?.data?.message
      }
      throw error
    }
  }

  public static formatTrailFeature(trailFeature: TrailFeatureDTO): TrailFeature {
    return {
      id: trailFeature.id,
      name: trailFeature.name,
      region: trailFeature.region,
      active: trailFeature.active,
      trails: Array.isArray(trailFeature.trails) ? trailFeature.trails.map(TrailService.formatTrail) : undefined,
      updatedAt: new Date(trailFeature.updatedAt),
      createdAt: new Date(trailFeature.createdAt),
    }
  }
}

export const weatherResistanceBreakpoints = [
  [0.5, 5], //1
  [2, 8], //2
  [5, 15], //3
  [10, 20], //4
  [12, 25], //5
  [15, 50], //6
  [20, 75], //7
  [25, 125], //8
  [35, 150], //9
  [50, 200], //10
  [50, Infinity], //11
  [Infinity, Infinity], //12
]

import { formatToNumberTypeProps } from '../../utils/formatting'

import { PaginatedResponse } from '../../models/PaginatedResponse.model'
import { TrailFinderQuestion } from '../../models/TrailFinderQuestion.model'
import { TrailFinderQuestionDTO } from '../../models/TrailFinderQuestionDTO.model'
import { TrailFinderQuestionAddEvent } from '../../events/TrailFinderQuestionAddEvent'
import { TrailFinderQuestionUpdateEvent } from '../../events/TrailFinderQuestionUpdateEvent'
import { TrailFinderQuestionSearchEvent } from '../../events/TrailFinderQuestionSearchEvent'
import { TrailFinderQuestionRelations } from '../../models/TrailFinderQuestionRelations.model'

import FlowfeedApiQueryService, {
  FlowfeedApiQueryServiceGetEvent,
} from '../FlowfeedApiQueryService/FlowfeedApiQueryService'
import { TrailFinderAnswerService } from '../TrailFinderAnswerService/TrailFinderAnswerService'

export class TrailFinderQuestionService {
  /**
   * @description Search for a single trailFinderQuestion by id
   *
   * @param id
   * @param relations
   *
   * @returns promise for a TrailFinderQuestion
   */
  public async get({
    id,
    relations,
  }: {
    id?: number
    relations?: TrailFinderQuestionRelations[]
  }): Promise<TrailFinderQuestion> {
    const params = {
      id,
      relations:
        Array.isArray(relations) && !relations.length
          ? 'false' // false is treated as an empty array by the API since an empty string would be treated as default relations normally
          : relations?.join(',') || undefined,
    }
    const flowfeedApiQueryRequest: FlowfeedApiQueryServiceGetEvent = {
      endpoint: `trail-finder-question/get`,
      params,
    }

    const flowfeedApiQueryService = new FlowfeedApiQueryService()
    try {
      const trailFinderQuestionRaw = await flowfeedApiQueryService.get<TrailFinderQuestionDTO>(flowfeedApiQueryRequest)
      return TrailFinderQuestionService.formatTrailFinderQuestion(trailFinderQuestionRaw)
    } catch (error) {
      console.error(error)
      throw 'Sorry, there was a problem with your request.'
    }
  }

  public async search(event: TrailFinderQuestionSearchEvent): Promise<PaginatedResponse<TrailFinderQuestion>> {
    const params = {
      ...event,
      question: event.question || undefined,
      orderDirection: event.orderDirection?.toUpperCase(),
      relations:
        Array.isArray(event.relations) && !event.relations.length
          ? 'false' // false is treated as an empty array by the API since an empty string would be treated as default relations normally
          : event.relations?.join(',') || undefined,
    }

    const flowfeedApiQueryRequest: FlowfeedApiQueryServiceGetEvent = {
      endpoint: 'trail-finder-question',
      params,
    }

    const trailFinderQuestions: {
      data: TrailFinderQuestion[]
      total: number
    } = {
      data: [],
      total: 0,
    }
    try {
      const flowfeedApiQueryService = new FlowfeedApiQueryService()
      const rawTrailFinderQuestions = await flowfeedApiQueryService.get<PaginatedResponse<TrailFinderQuestionDTO>>(
        flowfeedApiQueryRequest,
      )
      // wrap to avoid errors if the request is cancelled
      if (rawTrailFinderQuestions?.data) {
        trailFinderQuestions.data = rawTrailFinderQuestions.data.map((trailFinderQuestion) =>
          TrailFinderQuestionService.formatTrailFinderQuestion(trailFinderQuestion),
        )
        trailFinderQuestions.total = rawTrailFinderQuestions.total
      }
    } catch (error) {
      console.error(error)
      throw 'Sorry, there was a problem with your request.'
    }

    return trailFinderQuestions
  }

  public async add(trailFinderQuestion: TrailFinderQuestionAddEvent): Promise<TrailFinderQuestion> {
    const flowfeedApiQueryRequest = {
      endpoint: 'trail-finder-question',
      data: trailFinderQuestion,
    }

    const flowfeedApiQueryService = new FlowfeedApiQueryService()
    try {
      const trailFinderQuestionRaw = await flowfeedApiQueryService.post<TrailFinderQuestionDTO>(flowfeedApiQueryRequest)
      return TrailFinderQuestionService.formatTrailFinderQuestion(trailFinderQuestionRaw)
    } catch (error) {
      console.error(error)
      if (error instanceof Error) {
        throw error.message
      } else if ((error as { data: { message: string } })?.data?.message) {
        throw (error as { data: { message: string } })?.data?.message
      }
      throw error
    }
  }

  public async update(id: number, trailFinderQuestion: TrailFinderQuestionUpdateEvent): Promise<TrailFinderQuestion> {
    const flowfeedApiQueryRequest = {
      endpoint: `trail-finder-question/${id}`,
      data: trailFinderQuestion,
    }
    try {
      const flowfeedApiQueryService = new FlowfeedApiQueryService()
      const trailFinderQuestionRaw = await flowfeedApiQueryService.patch<TrailFinderQuestionDTO>(
        flowfeedApiQueryRequest,
      )
      return TrailFinderQuestionService.formatTrailFinderQuestion(trailFinderQuestionRaw)
    } catch (error) {
      console.error(error)
      if (error instanceof Error) {
        throw error.message
      } else if ((error as { data: { message: string } })?.data?.message) {
        throw (error as { data: { message: string } })?.data?.message
      }
      throw error
    }
  }

  public async delete(id: number): Promise<void> {
    const flowfeedApiQueryRequest = {
      endpoint: `trail-finder-question/${id}`,
    }

    try {
      const flowfeedApiQueryService = new FlowfeedApiQueryService()
      await flowfeedApiQueryService.delete(flowfeedApiQueryRequest)
    } catch (error) {
      console.error(error)
      if (error instanceof Error) {
        throw error.message
      } else if ((error as { data: { message: string } })?.data?.message) {
        throw (error as { data: { message: string } })?.data?.message
      }
      throw error
    }
  }

  public async import(
    trailFinderQuestions: Partial<TrailFinderQuestion>[],
    options?: {
      matchingField?: string | undefined
      deleteUnmatchedExisting?: boolean
    },
  ): Promise<void> {
    const data = trailFinderQuestions.map((trailFinderQuestion) => {
      const numberTypeProps = ['id', 'sortWeight']
      const formattedSponsor = formatToNumberTypeProps(trailFinderQuestion, numberTypeProps)
      return formattedSponsor
    })
    const flowfeedApiQueryRequest = {
      endpoint: `trail-finder-question/sync`,
      data: {
        data,
        matchingProp: options?.matchingField,
        deleteMissing: options?.deleteUnmatchedExisting,
      },
    }

    try {
      const flowfeedApiQueryService = new FlowfeedApiQueryService()
      await flowfeedApiQueryService.post(flowfeedApiQueryRequest)
    } catch (error) {
      console.error(error)
      if (error instanceof Error) {
        throw error.message
      } else if ((error as { data: { message: string } })?.data?.message) {
        throw (error as { data: { message: string } })?.data?.message
      }
      throw error
    }
  }

  public static formatTrailFinderQuestion(trailFinderQuestion: TrailFinderQuestionDTO): TrailFinderQuestion {
    return {
      id: trailFinderQuestion.id,
      region: trailFinderQuestion.region,
      question: trailFinderQuestion.question,
      sortWeight: trailFinderQuestion.sortWeight,
      active: trailFinderQuestion.active,
      answers: Array.isArray(trailFinderQuestion.answers)
        ? trailFinderQuestion.answers.map(TrailFinderAnswerService.formatTrailFinderAnswer)
        : undefined,
      updatedAt: new Date(trailFinderQuestion.updatedAt),
      createdAt: new Date(trailFinderQuestion.createdAt),
    }
  }
}

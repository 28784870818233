import { AffectedFilters } from './AffectedFilters.model'
import { AppRegions } from './AppRegions.model'
import { TrailFinderQuestion } from './TrailFinderQuestion.model'

export type TrailFinderAnswer = {
  id: number
  region: AppRegions
  answer: string
  affectedFilters: AffectedFilters | null
  question?: TrailFinderQuestion
  sortWeight: number
  active: boolean
  updatedAt: Date
  createdAt: Date
}

// we offer this to enable the enumerate the TrailFinderAnswer type's fields in js/ts files
export const trailFinderAnswerFields: (keyof TrailFinderAnswer)[] = [
  'id',
  'region',
  'answer',
  'affectedFilters',
  'question',
  'sortWeight',
  'active',
  'updatedAt',
  'createdAt',
]

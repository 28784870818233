import { useEffect, useMemo, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { SnackbarContext } from '../../../providers/SnackbarContext'

import { TrailFeature } from '../../../models/TrailFeature.model'
import { Trail } from '../../../models/Trail.model'
import { TrailFeatureAddEvent } from '../../../events/TrailFeatureAddEvent'
import { TrailFeatureUpdateEvent } from '../../../events/TrailFeatureUpdateEvent'

import { TrailFeatureService } from '../../../services/TrailFeatureService/TrailFeatureService'

import { Stack, FormControl, InputLabel, Button, Select, MenuItem, Divider, Typography } from '@mui/material'
import Loading from '../../../components/Loading/Loading.component'
import DetailInput from '../../../components/DetailInput/DetailInput.component'
import { useAppNavigate } from '../../../utils/url'

export type TrailFeatureFormProps = {
  trailFeature?: TrailFeature
  trail?: Trail
  onAdd?: (trailFeature: TrailFeature) => void
  onEdit?: (trailFeature: TrailFeature) => void
  onCancel: () => void
  // used to remotely trigger the save action
  saveTrigger?: number
  disableRedirect?: boolean
}
const TrailFeatureForm = (props: TrailFeatureFormProps) => {
  const { trailFeature, trail, onCancel, onAdd, onEdit, saveTrigger, disableRedirect = false } = props

  const navigate = useAppNavigate()

  const [tempTrailFeature, setTempTrailFeature] = useState<Partial<TrailFeature>>(trailFeature || {})
  const [isSubmitting, setIsSubmitting] = useState(false)

  const trailFeatureService = useMemo(() => new TrailFeatureService(), []) // memo to prevent re-creating service on every render

  const queryClient = useQueryClient()

  const addTrailFeature = useMutation(
    (mutationParams: { trailFeature: TrailFeatureAddEvent }) => {
      setIsSubmitting(true)
      return trailFeatureService.add(mutationParams.trailFeature)
    },
    {
      onSuccess: (trailFeature) => {
        SnackbarContext.show('Trail Feature added successfully!')

        if (!disableRedirect) {
          // redirect to the new trail system
          navigate(`/trail-features/${trailFeature.id}`)
          // remove the search query to prevent auto refresh of the search results in case we are on that page and redirecting
          queryClient.removeQueries(['TrailFeatureService.search'])
        }
        // invalidate all other queries to ensure any queries that are using the modified record are updated
        queryClient.invalidateQueries()
        setIsSubmitting(false)

        if (typeof onAdd === 'function') {
          onAdd(trailFeature)
        }
      },
      onError: (err) => {
        SnackbarContext.show(`Trail Feature failed to add: ${err}`, 'error')
        console.error(err)
        setIsSubmitting(false)
      },
    },
  )

  const updateTrailFeature = useMutation(
    (mutationParams: { id: number; changedProperties: TrailFeatureUpdateEvent }) => {
      setIsSubmitting(true)
      return trailFeatureService.update(mutationParams.id, mutationParams.changedProperties)
    },
    {
      onSuccess: (result) => {
        SnackbarContext.show('Trail Feature updated successfully!')

        // invalidate all queries to ensure any queries that are using the modified record are updated
        queryClient.invalidateQueries()
        setIsSubmitting(false)

        if (typeof onEdit === 'function') {
          onEdit(result)
        }
      },
      onError: (err) => {
        SnackbarContext.show(`Trail Feature failed to update: ${err}`, 'error')
        console.error(err)
        setIsSubmitting(false)
      },
    },
  )

  const handleSubmit = () => {
    if (!tempTrailFeature.name) {
      SnackbarContext.show(`Trail Feature name is required`, 'error')
      return
    }

    if (!trailFeature) {
      // format the data to match the API
      const formattedTrailFeature: TrailFeatureAddEvent = {
        name: tempTrailFeature.name,
        trailIds: trail?.id ? [trail.id] : undefined,
        active: tempTrailFeature.active,
      }

      // adding a new trail feature
      addTrailFeature.mutate({ trailFeature: formattedTrailFeature })
    } else {
      // identify the properties that have changed
      const didChange = (field: keyof TrailFeature) => {
        return tempTrailFeature[field] !== undefined && tempTrailFeature[field] !== trailFeature[field]
      }

      const changedProperties: TrailFeatureUpdateEvent = {
        id: trailFeature.id,
        name: didChange('name') ? tempTrailFeature.name : undefined,
        active: didChange('active') ? tempTrailFeature.active : undefined,
      }

      // edit an existing trail feature
      if (!Object.keys(changedProperties).filter((key) => key !== 'id').length) {
        // nothing changed so just close the dialog
        onCancel()
      } else {
        updateTrailFeature.mutate({ id: trailFeature.id, changedProperties })
      }
    }
  }

  useEffect(() => {
    if (saveTrigger) {
      // if outside consumer triggered save, then submit
      handleSubmit()
    }
  }, [saveTrigger])

  const handleCancel = () => {
    if (typeof onCancel === 'function') {
      onCancel()
    }
  }

  const keyDownSubmit = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSubmit()
    }
  }

  return (
    <>
      {isSubmitting && <Loading sx={{ py: 20 }} />}
      {!isSubmitting && (
        <>
          <Stack spacing={2} sx={{ my: 2 }}>
            {!trailFeature?.id && trail?.id && (
              <>
                <Typography variant="h6">Add new feature to {trail?.name}</Typography>
                <Divider />
              </>
            )}
            <Divider />
            <DetailInput<TrailFeature>
              label="Name"
              field="name"
              tempDetail={tempTrailFeature}
              setTempDetail={setTempTrailFeature}
              keyDownSubmit={keyDownSubmit}
            />
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="trail-feature-active-select-label">Active</InputLabel>
              <Select
                labelId="trail-feature-active-select-label"
                id="trail-feature-active-select"
                value={tempTrailFeature.active === false ? 'false' : 'true'}
                label="Active"
                onChange={(e) => {
                  setTempTrailFeature((prev) => ({ ...prev, active: e.target.value === 'false' ? false : true }))
                }}
              >
                <MenuItem value="true">True</MenuItem>
                <MenuItem value="false">False</MenuItem>
              </Select>
            </FormControl>
          </Stack>
          <Stack direction="row" justifyContent="end" alignItems="center" spacing={2}>
            <Button variant="outlined" onClick={handleCancel}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSubmit}>
              Save
            </Button>
          </Stack>
        </>
      )}
    </>
  )
}
export default TrailFeatureForm

import { TrailStatusFromWeatherResistanceEvent } from '../../events/TrailStatusFromWeatherResistanceEvent'
import { FreezeThawStatus } from '../../models/FreezeThawStatus.model'
import { FreezeThawStatusDTO } from '../../models/FreezeThawStatusDTO.model'
import { TrailStatus } from '../../models/TrailStatus.model'
import { TrailStatusDTO } from '../../models/TrailStatusDTO.model'

import FlowfeedApiQueryService, {
  FlowfeedApiQueryServiceGetEvent,
} from '../FlowfeedApiQueryService/FlowfeedApiQueryService'
import { WeatherRecordService } from '../WeatherRecordService/WeatherRecordService'

export class TrailStatusService {
  /**
   * @description Fetch a trail status from the supplied weather resistance and weather data
   *
   * @param weatherResistance 1-12
   * @param precipDecayed amount of rain still on the trail
   * @param precipLastSevenDays amount of rain in the last 7 days
   * @param saturationModifier 0-1 (eg. 0.5 = 50%)
   *
   * @returns promise for a a status of 1, 2, or 3
   */
  public static async statusFromWeatherResistance({
    weatherResistance,
    precipDecayed,
    precipLastSevenDays,
    saturationModifier,
  }: TrailStatusFromWeatherResistanceEvent): Promise<1 | 2 | 3> {
    const params = {
      weatherResistance,
      precipDecayed,
      precipLastSevenDays,
      saturationModifier,
    }
    const flowfeedApiQueryRequest: FlowfeedApiQueryServiceGetEvent = {
      endpoint: `trail-status/status-from-weather-resistance`,
      params,
    }

    const flowfeedApiQueryService = new FlowfeedApiQueryService()
    try {
      return flowfeedApiQueryService.get<1 | 2 | 3>(flowfeedApiQueryRequest)
    } catch (error) {
      console.error(error)
      throw 'Sorry, there was a problem with your request.'
    }
  }

  public static formatTrailStatus(trailStatus: TrailStatusDTO): TrailStatus {
    return {
      date: new Date(trailStatus.date),
      status: trailStatus.status,
      displayWeather: trailStatus.displayWeather
        ? WeatherRecordService.formatWeatherRecord(trailStatus.displayWeather)
        : undefined,
      precipitationDecayed: trailStatus.precipitationDecayed,
      precipitationLastTwoHours: trailStatus.precipitationLastTwoHours,
      precipitationLastFortyEightHours: trailStatus.precipitationLastFortyEightHours,
      precipitationLastSevenDays: trailStatus.precipitationLastSevenDays,
      freezeThawStatus: TrailStatusService.formatFreezeThawStatus(trailStatus.freezeThawStatus),
    }
  }

  public static formatFreezeThawStatus(freezeThawStatus: FreezeThawStatusDTO): FreezeThawStatus {
    return {
      date: new Date(freezeThawStatus.date),
      freezeCounter: freezeThawStatus.freezeCounter,
      thawCounter: freezeThawStatus.thawCounter,
      status: freezeThawStatus.status,
    }
  }
}

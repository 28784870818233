import { ImgHTMLAttributes, useState } from 'react'
import { Box, Dialog, DialogTitle, IconButton, SxProps } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

type LightboxImageProps = ImgHTMLAttributes<HTMLImageElement> & {
  sx?: SxProps
  lightboxSx?: SxProps
}
export default function LightboxImage(props: LightboxImageProps): JSX.Element {
  const { lightboxSx, ...rest } = props
  const [open, setOpen] = useState(false)
  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={lightboxSx}
      >
        <DialogTitle sx={{ m: 0, p: 1, textAlign: 'right' }}>
          <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Box sx={{ bgcolor: 'background.paper' }}>
          <Box {...rest} component="img" sx={{ width: 'auto', height: 'auto', maxWidth: '100%', maxHeight: '100%' }} />
        </Box>
      </Dialog>
      <Box {...rest} component="img" onClick={() => setOpen(true)} sx={{ ...rest?.sx, cursor: 'pointer' }} />
    </>
  )
}

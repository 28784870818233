import { useAuth0 } from '@auth0/auth0-react'
import { Navigate } from 'react-router-dom'

import { AppPermissions } from '../../models/AppPermissions.model'
import UserService from '../../services/UserService/UserService'

type PrivateRouteProps = {
  children: unknown
  permissions?: AppPermissions[]
}
const PrivateRouteWrapper = ({ children, permissions = [] }: PrivateRouteProps): JSX.Element => {
  const { user, isAuthenticated } = useAuth0()

  const formattedUser = user ? UserService.formatUser(user) : undefined

  const isLoggedIn = isAuthenticated && formattedUser ? true : false
  let hasPermissions = true
  if (permissions) {
    // check if the user is logged in and if so, if they have one of the required permissions
    hasPermissions = isLoggedIn && permissions.some((permission) => formattedUser?.permissions.includes(permission))
  }

  return (
    <>
      {!isLoggedIn ? (
        <Navigate to={`/login/redirect/${encodeURIComponent(window.location.pathname)}`} replace />
      ) : !hasPermissions ? (
        <Navigate to="/unauthorized" state={{ returnUrl: window.location.pathname }} />
      ) : (
        children
      )}
    </>
  )
}
export default PrivateRouteWrapper

import React from 'react'

import { Alert, Snackbar } from '@mui/material'

type SnackbarContextType = {
  show: (message: string, severity?: 'success' | 'info' | 'warning' | 'error') => void
}
export const SnackbarContext: SnackbarContextType = {
  show: () => {
    // if used outside of SnackbarProvider, this function will not have been replaced so throw error
    throw new Error('SnackbarContext must be used within a SnackbarProvider')
  },
}

type SnackbarProviderProps = { children: React.ReactNode }

export const SnackbarProvider = ({ children }: SnackbarProviderProps): JSX.Element => {
  const [isShowing, setIsShowing] = React.useState(false)
  const [snackbarMessage, setSnackbarMessage] = React.useState<string>('')
  const [snackbarSeverity, setSnackbarSeverity] = React.useState<'success' | 'info' | 'warning' | 'error'>('success')

  const handleClose = () => {
    setIsShowing(false)
  }

  SnackbarContext.show = (message, severity) => {
    setIsShowing(true)
    setSnackbarMessage(message || '')
    setSnackbarSeverity(severity || 'success')
  }

  return (
    <>
      {children}
      <Snackbar
        open={isShowing}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          variant="filled"
          severity={snackbarSeverity}
          color={snackbarSeverity}
          sx={{
            width: '100%',
            background: (theme) => theme.palette[snackbarSeverity].main,
            color: (theme) => theme.palette[snackbarSeverity].contrastText,
          }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  )
}

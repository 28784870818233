import { PaginatedResponse } from '../../models/PaginatedResponse.model'
import { WeatherGroup } from '../../models/WeatherGroup.model'
import { WeatherGroupDTO } from '../../models/WeatherGroupDTO.model'
import { WeatherGroupAddEvent } from '../../events/WeatherGroupAddEvent'
import { WeatherGroupUpdateEvent } from '../../events/WeatherGroupUpdateEvent'
import { WeatherGroupSearchEvent } from '../../events/WeatherGroupSearchEvent'

import FlowfeedApiQueryService, {
  FlowfeedApiQueryServiceGetEvent,
} from '../FlowfeedApiQueryService/FlowfeedApiQueryService'

export class WeatherGroupService {
  /**
   * @description Search for a single weatherGroup by id
   *
   * @param id
   *
   * @returns promise for a WeatherGroup
   */
  public async get(id?: number): Promise<WeatherGroup> {
    const flowfeedApiQueryRequest: FlowfeedApiQueryServiceGetEvent = {
      endpoint: `weather-group/${id}`,
    }

    const flowfeedApiQueryService = new FlowfeedApiQueryService()
    try {
      const weatherGroupRaw = await flowfeedApiQueryService.get<WeatherGroupDTO>(flowfeedApiQueryRequest)
      return WeatherGroupService.formatWeatherGroup(weatherGroupRaw)
    } catch (error) {
      console.error(error)
      throw 'Sorry, there was a problem with your request.'
    }
  }

  public async search(event: WeatherGroupSearchEvent): Promise<PaginatedResponse<WeatherGroup>> {
    const params = {
      ...event,
      name: event.name || undefined,
      orderDirection: event.orderDirection?.toUpperCase(),
    }

    const flowfeedApiQueryRequest: FlowfeedApiQueryServiceGetEvent = {
      endpoint: 'weather-group',
      params,
    }

    const weatherGroups: {
      data: WeatherGroup[]
      total: number
    } = {
      data: [],
      total: 0,
    }
    try {
      const flowfeedApiQueryService = new FlowfeedApiQueryService()
      const rawWeatherGroups = await flowfeedApiQueryService.get<PaginatedResponse<WeatherGroupDTO>>(
        flowfeedApiQueryRequest,
      )
      // wrap to avoid errors if the request is cancelled
      if (rawWeatherGroups?.data) {
        weatherGroups.data = rawWeatherGroups.data.map((weatherGroup) =>
          WeatherGroupService.formatWeatherGroup(weatherGroup),
        )
        weatherGroups.total = rawWeatherGroups.total
      }
    } catch (error) {
      console.error(error)
      throw 'Sorry, there was a problem with your request.'
    }

    return weatherGroups
  }

  public async add(weatherGroup: WeatherGroupAddEvent): Promise<WeatherGroup> {
    const flowfeedApiQueryRequest = {
      endpoint: 'weather-group',
      data: weatherGroup,
    }

    const flowfeedApiQueryService = new FlowfeedApiQueryService()
    try {
      const weatherGroupRaw = await flowfeedApiQueryService.post<WeatherGroupDTO>(flowfeedApiQueryRequest)
      return WeatherGroupService.formatWeatherGroup(weatherGroupRaw)
    } catch (error) {
      console.error(error)
      if (error instanceof Error) {
        throw error.message
      } else if ((error as { data: { message: string } })?.data?.message) {
        throw (error as { data: { message: string } })?.data?.message
      }
      throw error
    }
  }

  public async update(id: number, weatherGroup: WeatherGroupUpdateEvent): Promise<WeatherGroup> {
    const flowfeedApiQueryRequest = {
      endpoint: `weather-group/${id}`,
      data: weatherGroup,
    }
    try {
      const flowfeedApiQueryService = new FlowfeedApiQueryService()
      const weatherGroupRaw = await flowfeedApiQueryService.patch<WeatherGroupDTO>(flowfeedApiQueryRequest)
      return WeatherGroupService.formatWeatherGroup(weatherGroupRaw)
    } catch (error) {
      console.error(error)
      if (error instanceof Error) {
        throw error.message
      } else if ((error as { data: { message: string } })?.data?.message) {
        throw (error as { data: { message: string } })?.data?.message
      }
      throw error
    }
  }

  public async delete(id: number): Promise<void> {
    const flowfeedApiQueryRequest = {
      endpoint: `weather-group/${id}`,
    }

    try {
      const flowfeedApiQueryService = new FlowfeedApiQueryService()
      await flowfeedApiQueryService.delete(flowfeedApiQueryRequest)
    } catch (error) {
      console.error(error)
      if (error instanceof Error) {
        throw error.message
      } else if ((error as { data: { message: string } })?.data?.message) {
        throw (error as { data: { message: string } })?.data?.message
      }
      throw error
    }
  }

  public async import(
    weatherGroups: Partial<WeatherGroup>[],
    options?: {
      matchingField?: string | undefined
      deleteUnmatchedExisting?: boolean
    },
  ): Promise<void> {
    const data = weatherGroups.map((weatherGroup) => {
      return {
        ...weatherGroup,
        id: weatherGroup.id ? Number(weatherGroup.id) : undefined,
      }
    })
    const flowfeedApiQueryRequest = {
      endpoint: `weather-group/sync`,
      data: {
        data,
        matchingProp: options?.matchingField,
        deleteMissing: options?.deleteUnmatchedExisting,
      },
    }

    try {
      const flowfeedApiQueryService = new FlowfeedApiQueryService()
      await flowfeedApiQueryService.post(flowfeedApiQueryRequest)
    } catch (error) {
      console.error(error)
      if (error instanceof Error) {
        throw error.message
      } else if ((error as { data: { message: string } })?.data?.message) {
        throw (error as { data: { message: string } })?.data?.message
      }
      throw error
    }
  }

  public static formatWeatherGroup(weatherGroup: WeatherGroupDTO): WeatherGroup {
    return {
      id: weatherGroup.id,
      name: weatherGroup.name,
      region: weatherGroup.region,
      weatherSource: weatherGroup.weatherSource,
      weatherSourceId: weatherGroup.weatherSourceId,
      type: weatherGroup.type,
      updatedAt: new Date(weatherGroup.updatedAt),
      createdAt: new Date(weatherGroup.createdAt),
    }
  }
}

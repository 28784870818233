import React from 'react'
import { AppState, Auth0Provider } from '@auth0/auth0-react'
import { useNavigate } from 'react-router-dom'

type Auth0ProviderWithHistoryProps = { children: React.ReactNode }

const Auth0ProviderWithHistory = ({ children }: Auth0ProviderWithHistoryProps): JSX.Element => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN || ''
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID || ''

  const navigate = useNavigate()

  const onRedirectCallback = (appState?: AppState) => {
    /**
     * Auth0 always sends you to the value of the redirectUri which can't be dynamic
     * since it is restricted to only values in the allowed callback urls list in the
     * Auth0 dashboard so here, we immediately redirect to the correct page after the
     * Auth0 redirect.
     *
     * We've created a blank route for this purpose at /redirect to avoid wasting
     * resources on a component that we don't need to render.
     */
    navigate(appState?.redirectUrl || '/profile', { replace: true })
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: `${window.location.origin}/redirect`,
        audience: `https://${domain}/api/v2/`,
        scope: 'profile email read:current_user',
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  )
}

export default Auth0ProviderWithHistory

import { useParams } from 'react-router'
import { useAuth0 } from '@auth0/auth0-react'
import { Stack } from '@mui/material'

const Login = (): JSX.Element => {
  const { loginWithRedirect } = useAuth0()
  const { redirect } = useParams<{ redirect?: string }>()

  loginWithRedirect({
    authorizationParams: {
      scope: 'profile email read:current_user',
    },
    appState: { redirectUrl: redirect ? decodeURIComponent(redirect) : '/' },
  })

  return (
    <Stack direction="row" alignItems="center" justifyContent="center" sx={{ width: '100%', height: '100%' }}>
      Redirecting to login...
    </Stack>
  )
}

export default Login

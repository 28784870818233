import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import './App.css'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'

import AppTheme from './App.theme'
import { SnackbarProvider } from './providers/SnackbarContext'
import Auth0ProviderWithHistory from './providers/Auth0ProviderWithHistory'
import { AppUpdateProvider } from './providers/AppUpdateContext'
import { AppStateProvider } from './providers/AppStateContext'
import { AuthStateProvider } from './providers/AuthStateContext'
import AppLayout from './providers/AppLayout'

import { AppPermissions } from './models/AppPermissions.model'

import PrivateRouteWrapper from './components/PrivateRouteWrapper/PrivateRouteWrapper.component'
import { CssBaseline, ThemeProvider } from '@mui/material'

import Home from './routes/Home/Home.route'
import Login from './routes/Login/Login.route'
import Logout from './routes/Logout/Logout.route'
import Unauthorized from './routes/Unauthorized/Unauthorized.route'
import NotFound from './routes/NotFound/NotFound.route'
import WeatherGroupSearch from './routes/WeatherGroups/WeatherGroupSearch/WeatherGroupSearch.route'
import WeatherGroupDetail from './routes/WeatherGroups/WeatherGroupDetail/WeatherGroupDetail.route'
import TrailSystemDetail from './routes/TrailSystems/TrailSystemDetail/TrailSystemDetail.route'
import TrailSystemSearch from './routes/TrailSystems/TrailSystemSearch/TrailSystemSearch.route'
import TrailSearch from './routes/Trails/TrailSearch/TrailSearch.route'
import TrailDetail from './routes/Trails/TrailDetail/TrailDetail.route'
import ManualTrailStatusSearch from './routes/ManualTrailStatuses/ManualTrailStatusSearch/ManualTrailStatusSearch.route'
import ManualTrailStatusDetail from './routes/ManualTrailStatuses/ManualTrailStatusDetail/ManualTrailStatusDetail.route'
import WeatherRecordDetail from './routes/WeatherRecords/WeatherRecordDetail/WeatherRecordDetail.route'
import WeatherRecordSearch from './routes/WeatherRecords/WeatherRecordSearch/WeatherRecordSearch.route'
import SponsorSearch from './routes/Sponsors/SponsorSearch/SponsorSearch.route'
import SponsorDetail from './routes/Sponsors/SponsorDetail/SponsorDetail.route'
import TrailFeatureSearch from './routes/TrailFeatures/TrailFeatureSearch/TrailFeatureSearch.route'
import TrailFeatureDetail from './routes/TrailFeatures/TrailFeatureDetail/TrailFeatureDetail.route'
import TrailFinderQuestionSearch from './routes/TrailFinderQuestions/TrailFinderQuestionSearch/TrailFinderQuestionSearch.route'
import TrailFinderQuestionDetail from './routes/TrailFinderQuestions/TrailFinderQuestionDetail/TrailFinderQuestionDetail.route'
import TrailFinderAnswerDetail from './routes/TrailFinderAnswers/TrailFinderAnswerDetail/TrailFinderAnswerDetail.route'

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // cacheTime: Infinity,
      refetchOnWindowFocus: false,
      retry: false,
      // staleTime: Infinity,

      staleTime: 5 * (60 * 1000), // 5 mins
      cacheTime: 10 * (60 * 1000), // 10 mins
    },
  },
})

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={AppTheme}>
        <CssBaseline />
        <SnackbarProvider>
          <BrowserRouter>
            <Auth0ProviderWithHistory>
              <AppUpdateProvider>
                <AppStateProvider>
                  <AuthStateProvider>
                    <AppLayout>
                      <Routes>
                        <Route path="/unauthorized" element={<Unauthorized />} />
                        <Route path="/login/redirect/:redirect" element={<Login />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/logout" element={<Logout />} />
                        <Route path="/redirect" element={<div>Redirecting...</div>} />
                        <Route path="/404" element={<NotFound />} />
                        {/* BE SURE TO WRAP ALL NEW ROUTES IN PrivateRouteWrapper */}
                        <Route
                          path="/"
                          element={
                            <PrivateRouteWrapper permissions={Object.values(AppPermissions)}>
                              <Home />
                            </PrivateRouteWrapper>
                          }
                        />
                        <Route
                          path="/manual-trail-statuses"
                          element={
                            <PrivateRouteWrapper permissions={[AppPermissions.viewManualTrailStatus]}>
                              <ManualTrailStatusSearch />
                            </PrivateRouteWrapper>
                          }
                        />
                        <Route
                          path="/manual-trail-statuses/:id"
                          element={
                            <PrivateRouteWrapper permissions={[AppPermissions.viewManualTrailStatus]}>
                              <ManualTrailStatusDetail />
                            </PrivateRouteWrapper>
                          }
                        />
                        <Route
                          path="/trails"
                          element={
                            <PrivateRouteWrapper permissions={[AppPermissions.viewTrail]}>
                              <TrailSearch />
                            </PrivateRouteWrapper>
                          }
                        />
                        <Route
                          path="/trails/:id"
                          element={
                            <PrivateRouteWrapper permissions={[AppPermissions.viewTrail]}>
                              <TrailDetail />
                            </PrivateRouteWrapper>
                          }
                        />
                        <Route
                          path="/trail-systems"
                          element={
                            <PrivateRouteWrapper permissions={[AppPermissions.viewTrailSystem]}>
                              <TrailSystemSearch />
                            </PrivateRouteWrapper>
                          }
                        />
                        <Route
                          path="/trail-systems/:id"
                          element={
                            <PrivateRouteWrapper permissions={[AppPermissions.viewTrailSystem]}>
                              <TrailSystemDetail />
                            </PrivateRouteWrapper>
                          }
                        />
                        <Route
                          path="/sponsors"
                          element={
                            <PrivateRouteWrapper permissions={[AppPermissions.viewSponsor]}>
                              <SponsorSearch />
                            </PrivateRouteWrapper>
                          }
                        />
                        <Route
                          path="/sponsors/:id"
                          element={
                            <PrivateRouteWrapper permissions={[AppPermissions.viewSponsor]}>
                              <SponsorDetail />
                            </PrivateRouteWrapper>
                          }
                        />
                        <Route
                          path="/trail-finder-questions"
                          element={
                            <PrivateRouteWrapper permissions={[AppPermissions.viewTrailFinder]}>
                              <TrailFinderQuestionSearch />
                            </PrivateRouteWrapper>
                          }
                        />
                        <Route
                          path="/trail-finder-questions/:id"
                          element={
                            <PrivateRouteWrapper permissions={[AppPermissions.viewTrailFinder]}>
                              <TrailFinderQuestionDetail />
                            </PrivateRouteWrapper>
                          }
                        />
                        <Route
                          path="/trail-finder-answers/:id"
                          element={
                            <PrivateRouteWrapper permissions={[AppPermissions.viewTrailFinder]}>
                              <TrailFinderAnswerDetail />
                            </PrivateRouteWrapper>
                          }
                        />
                        <Route
                          path="/trail-features"
                          element={
                            <PrivateRouteWrapper permissions={[AppPermissions.viewTrailFeature]}>
                              <TrailFeatureSearch />
                            </PrivateRouteWrapper>
                          }
                        />
                        <Route
                          path="/trail-features/:id"
                          element={
                            <PrivateRouteWrapper permissions={[AppPermissions.viewTrailFeature]}>
                              <TrailFeatureDetail />
                            </PrivateRouteWrapper>
                          }
                        />
                        <Route
                          path="/weather-groups"
                          element={
                            <PrivateRouteWrapper permissions={[AppPermissions.viewWeatherGroup]}>
                              <WeatherGroupSearch />
                            </PrivateRouteWrapper>
                          }
                        />
                        <Route
                          path="/weather-groups/:id"
                          element={
                            <PrivateRouteWrapper permissions={[AppPermissions.viewWeatherGroup]}>
                              <WeatherGroupDetail />
                            </PrivateRouteWrapper>
                          }
                        />
                        <Route
                          path="/weather-records"
                          element={
                            <PrivateRouteWrapper permissions={[AppPermissions.viewWeatherRecord]}>
                              <WeatherRecordSearch />
                            </PrivateRouteWrapper>
                          }
                        />
                        <Route
                          path="/weather-records/:id"
                          element={
                            <PrivateRouteWrapper permissions={[AppPermissions.viewWeatherRecord]}>
                              <WeatherRecordDetail />
                            </PrivateRouteWrapper>
                          }
                        />
                        {/* Default route to /404 */}
                        <Route
                          path="*"
                          element={<Navigate to="/404" state={{ originalUrl: window.location.pathname }} replace />}
                        />
                      </Routes>
                    </AppLayout>
                  </AuthStateProvider>
                </AppStateProvider>
              </AppUpdateProvider>
            </Auth0ProviderWithHistory>
          </BrowserRouter>
        </SnackbarProvider>
      </ThemeProvider>
    </QueryClientProvider>
  )
}

export default App

import { useEffect, useMemo, useState } from 'react'
import camelCase from 'lodash/camelCase'
import startCase from 'lodash/startCase'
import { useAuth0 } from '@auth0/auth0-react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { SnackbarContext } from '../../../providers/SnackbarContext'
import { AppStateContext } from '../../../providers/AppStateContext'
import { generatePermalink } from '../../../utils/formatting'

import { TrailSystem } from '../../../models/TrailSystem.model'
import { TrailSystemAddEvent } from '../../../events/TrailSystemAddEvent'
import { TrailSystemUpdateEvent } from '../../../events/TrailSystemUpdateEvent'
import { AppPermissions } from '../../../models/AppPermissions.model'
import { USStates } from '../../../models/USStates.model'

import UserService from '../../../services/UserService/UserService'
import { WeatherGroupService } from '../../../services/WeatherGroupService/WeatherGroupService'
import { TrailSystemService } from '../../../services/TrailSystemService/TrailSystemService'

import {
  Stack,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
  Button,
  Select,
  MenuItem,
  InputAdornment,
} from '@mui/material'
import Loading from '../../../components/Loading/Loading.component'
import DetailInput from '../../../components/DetailInput/DetailInput.component'
import { TrailSystemRelations } from '../../../models/TrailSystemRelations.model'
import { useAppNavigate } from '../../../utils/url'

export type TrailSystemFormProps = {
  trailSystem?: TrailSystem
  onAdd?: (trailSystem: TrailSystem) => void
  onEdit?: (trailSystem: TrailSystem) => void
  onCancel: () => void
  // used to remotely trigger the save action
  saveTrigger?: number
  disableRedirect?: boolean
}
const TrailSystemForm = (props: TrailSystemFormProps) => {
  const { trailSystem, onCancel, onAdd, onEdit, saveTrigger, disableRedirect = false } = props

  const navigate = useAppNavigate()

  const { user } = useAuth0()
  const formattedUser = useMemo(() => (user ? UserService.formatUser(user) : undefined), [user])
  const hasViewWeatherGroupPermissions = UserService.hasPermissions(user, [AppPermissions.viewWeatherGroup])

  const isAdding = typeof onAdd === 'function'
  const isEditing = typeof onEdit === 'function'

  const [tempTrailSystem, setTempTrailSystem] = useState<
    Partial<TrailSystem> & {
      weatherGroupId?: number
      displayWeatherGroupId?: number
    }
  >(
    {
      ...trailSystem,
      weatherGroupId: trailSystem?.weatherGroup?.id,
      displayWeatherGroupId: trailSystem?.displayWeatherGroup?.id,
    } || {},
  )
  const [isSubmitting, setIsSubmitting] = useState(false)

  const queryClient = useQueryClient()

  // if they have hasViewWeatherGroupPermissions and the trail system passed does not have weather group relations, fetch the trail system with the relations
  const { isLoading: isLoadingTrailSystemWithWeatherGroups, data: trailSystemWithWeatherGroups } = useQuery(
    [
      'TrailSystemService.get',
      { id: trailSystem?.id, relations: [TrailSystemRelations.weatherGroups] },
      AppStateContext.getRegion(),
    ],
    () => trailSystemService.get({ id: trailSystem?.id, relations: [TrailSystemRelations.weatherGroups] }),
    {
      enabled: hasViewWeatherGroupPermissions && trailSystem?.id && !trailSystem?.weatherGroup?.id ? true : false, // prevent the query from running unless they have the permissions and the trail system does not have weather group relations
      onError: (err) => {
        SnackbarContext.show(
          `Failed to fetch weather group assignments for the trail system: ${typeof err === 'string' ? err : ''}`,
          'error',
        )
        console.error(err)
      },
    },
  )
  // useEffect usage instead of onSuccess or onSettled to ensure we always trigger even when the query is cached
  useEffect(() => {
    setTempTrailSystem({
      ...tempTrailSystem,
      weatherGroup: trailSystemWithWeatherGroups?.weatherGroup,
      displayWeatherGroup: trailSystemWithWeatherGroups?.displayWeatherGroup,
      weatherGroupId: trailSystemWithWeatherGroups?.weatherGroup?.id,
      displayWeatherGroupId: trailSystemWithWeatherGroups?.displayWeatherGroup?.id,
    })
  }, [trailSystemWithWeatherGroups])

  // if they have hasViewWeatherGroupPermissions, fetch the list of weather groups for them to select from
  const weatherGroupService = useMemo(() => new WeatherGroupService(), []) // memo to prevent re-creating service on every render
  const { isLoading: isLoadingWeatherGroups, data: weatherGroupsResponse } = useQuery(
    ['WeatherGroupService.search', { pageSize: 0 }, AppStateContext.getRegion()],
    () => weatherGroupService.search({ pageSize: 0 }),
    {
      enabled: hasViewWeatherGroupPermissions, // prevent the query from running unless they have the permissions
      onError: (err) => {
        SnackbarContext.show(
          `Failed to fetch weather groups for the form: ${typeof err === 'string' ? err : ''}`,
          'error',
        )
        console.error(err)
      },
    },
  )

  const trailSystemService = useMemo(() => new TrailSystemService(), []) // memo to prevent re-creating service on every render

  const addTrailSystem = useMutation(
    (mutationParams: { trailSystem: TrailSystemAddEvent }) => {
      setIsSubmitting(true)
      return trailSystemService.add(mutationParams.trailSystem)
    },
    {
      onSuccess: (result) => {
        SnackbarContext.show('Trail System added successfully!')

        if (!disableRedirect) {
          // redirect to the new trail system
          navigate(`/trail-systems/${result.id}`)
          // remove the search query to prevent auto refresh of the search results in case we are on that page and redirecting
          queryClient.removeQueries(['TrailSystemService.search'])
        }
        // invalidate all other queries to ensure any queries that are using the modified record are updated
        queryClient.invalidateQueries()
        setIsSubmitting(false)

        if (typeof onAdd === 'function') {
          onAdd(result)
        }
      },
      onError: (err) => {
        SnackbarContext.show(`Trail System failed to add: ${err}`, 'error')
        console.error(err)
        setIsSubmitting(false)
      },
    },
  )

  const updateTrailSystem = useMutation(
    (mutationParams: { id: number; changedProperties: TrailSystemUpdateEvent }) => {
      setIsSubmitting(true)
      return trailSystemService.update(mutationParams.id, mutationParams.changedProperties)
    },
    {
      onSuccess: (result) => {
        SnackbarContext.show('Trail System updated successfully!')

        // invalidate all queries to ensure any queries that are using the modified record are updated
        queryClient.invalidateQueries()
        setIsSubmitting(false)

        if (typeof onEdit === 'function') {
          onEdit(result)
        }
      },
      onError: (err) => {
        SnackbarContext.show(`Trail System failed to update: ${err}`, 'error')
        console.error(err)
        setIsSubmitting(false)
      },
    },
  )

  const handleSubmit = () => {
    if (!tempTrailSystem.name) {
      SnackbarContext.show(`Trail System name is required`, 'error')
      return
    }
    if (!tempTrailSystem.street) {
      SnackbarContext.show(`Trail System street is required`, 'error')
      return
    }
    if (!tempTrailSystem.city) {
      SnackbarContext.show(`Trail System city is required`, 'error')
      return
    }
    if (!tempTrailSystem.state) {
      SnackbarContext.show(`Trail System state is required`, 'error')
      return
    }
    if (!tempTrailSystem.weatherGroupId) {
      SnackbarContext.show(`Trail System weather group is required`, 'error')
      return
    }
    if (!tempTrailSystem.displayWeatherGroupId) {
      SnackbarContext.show(`Trail System display weather group is required`, 'error')
      return
    }

    if (!trailSystem) {
      // format the data to match the API
      const formattedTrailSystem: TrailSystemAddEvent = {
        name: tempTrailSystem.name,
        permalink: tempTrailSystem.permalink || undefined,
        description: tempTrailSystem.description || undefined,
        street: tempTrailSystem.street,
        city: tempTrailSystem.city,
        state: tempTrailSystem.state,
        parkingGpsLat: tempTrailSystem.parkingGpsLat || undefined,
        parkingGpsLong: tempTrailSystem.parkingGpsLong || undefined,
        weatherGroupId: tempTrailSystem.weatherGroupId,
        displayWeatherGroupId: tempTrailSystem.displayWeatherGroupId,
        sortWeight: tempTrailSystem.sortWeight,
        active: tempTrailSystem.active,
      }

      // adding a new trailSystem
      addTrailSystem.mutate({ trailSystem: formattedTrailSystem })
    } else {
      // identify the properties that have changed
      const didChange = (field: keyof TrailSystem) => {
        return tempTrailSystem[field] !== undefined && tempTrailSystem[field] !== trailSystem[field]
      }

      if (!tempTrailSystem.permalink) {
        SnackbarContext.show(`Trail System permalink cannot be blank`, 'error')
        return
      }

      // determine the original weather group id based on whether it was passed in or we had to fetch it
      const originalWeatherGroupId =
        trailSystem && !trailSystem?.weatherGroup?.id
          ? trailSystemWithWeatherGroups?.weatherGroup?.id
          : trailSystem?.weatherGroup?.id
      const originalDisplayWeatherGroupId =
        trailSystem && !trailSystem?.displayWeatherGroup?.id
          ? trailSystemWithWeatherGroups?.displayWeatherGroup?.id
          : trailSystem?.displayWeatherGroup?.id

      const changedProperties: TrailSystemUpdateEvent = {
        id: trailSystem.id,
        name: didChange('name') ? tempTrailSystem.name : undefined,
        permalink: didChange('permalink') ? tempTrailSystem.permalink : undefined,
        description: didChange('description') ? tempTrailSystem.description : undefined,
        street: didChange('street') ? tempTrailSystem.street : undefined,
        city: didChange('city') ? tempTrailSystem.city : undefined,
        state: didChange('state') ? tempTrailSystem.state : undefined,
        parkingGpsLat: didChange('parkingGpsLat') ? tempTrailSystem.parkingGpsLat : undefined,
        parkingGpsLong: didChange('parkingGpsLong') ? tempTrailSystem.parkingGpsLong : undefined,
        weatherGroupId:
          tempTrailSystem.weatherGroupId !== undefined && tempTrailSystem.weatherGroupId !== originalWeatherGroupId
            ? tempTrailSystem.weatherGroupId
            : undefined,
        displayWeatherGroupId:
          tempTrailSystem.displayWeatherGroupId !== undefined &&
          tempTrailSystem.displayWeatherGroupId !== originalDisplayWeatherGroupId
            ? tempTrailSystem.displayWeatherGroupId
            : undefined,
        sortWeight: didChange('sortWeight') ? tempTrailSystem.sortWeight : undefined,
        active: didChange('active') ? tempTrailSystem.active : undefined,
      }

      if (!Object.keys(changedProperties).filter((key) => key !== 'id').length) {
        // nothing changed so just close the dialog
        onCancel()
      } else {
        // edit an existing trailSystem
        updateTrailSystem.mutate({ id: trailSystem.id, changedProperties })
      }
    }
  }

  useEffect(() => {
    if (saveTrigger) {
      // if outside consumer triggered save, then submit
      handleSubmit()
    }
  }, [saveTrigger])

  const handleCancel = () => {
    if (typeof onCancel === 'function') {
      onCancel()
    }
  }

  const keyDownSubmit = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSubmit()
    }
  }

  return (
    <>
      {(isLoadingTrailSystemWithWeatherGroups || isLoadingWeatherGroups || isSubmitting) && <Loading sx={{ py: 20 }} />}
      {!isLoadingTrailSystemWithWeatherGroups && !isLoadingWeatherGroups && !isSubmitting && (
        <>
          <Stack spacing={2} sx={{ my: 2 }}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Name</InputLabel>
              <OutlinedInput
                id="trailSystem-name"
                label="Name"
                value={tempTrailSystem.name || ''}
                required
                onChange={(e) => {
                  setTempTrailSystem((prev) => ({ ...prev, name: e.target.value }))
                }}
                onKeyDown={keyDownSubmit}
              />
            </FormControl>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Permalink</InputLabel>
              <OutlinedInput
                id="trail-system-permalink"
                label="Permalink"
                value={tempTrailSystem.permalink || ''}
                required={isEditing}
                startAdornment={
                  <InputAdornment position="start">
                    https://{AppStateContext.getRegion().toLowerCase()}.flowfeed.app/trail-system/
                  </InputAdornment>
                }
                onChange={(e) => {
                  setTempTrailSystem((prev) => ({ ...prev, permalink: generatePermalink(e.target.value) }))
                }}
                onKeyDown={keyDownSubmit}
              />
              <FormHelperText
                sx={{
                  mb: 1, // extra space to compensate for the helper text visually separate from the next item's label
                }}
              >
                This is the unique identifier for this trail system used in the URL for this trail system's page within
                the app. It can only contain letters, numbers, and dashes. Invalid characters will be removed.
                {isAdding ? ' If you leave this blank, one will be generated for you.' : ''}
              </FormHelperText>
            </FormControl>
            <DetailInput<TrailSystem>
              label="Description"
              field="description"
              tempDetail={tempTrailSystem}
              setTempDetail={setTempTrailSystem}
              keyDownSubmit={keyDownSubmit}
            />
            <DetailInput<TrailSystem>
              label="Street"
              field="street"
              tempDetail={tempTrailSystem}
              setTempDetail={setTempTrailSystem}
              keyDownSubmit={keyDownSubmit}
            />
            <DetailInput<TrailSystem>
              label="City"
              field="city"
              tempDetail={tempTrailSystem}
              setTempDetail={setTempTrailSystem}
              keyDownSubmit={keyDownSubmit}
            />
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="trail-system-state-select-label">State</InputLabel>
              <Select
                labelId="trail-system-state-select-label"
                id="trail-system-state-select"
                value={tempTrailSystem.state || ''}
                label="State"
                onChange={(e) => {
                  setTempTrailSystem((prev) => ({ ...prev, state: e.target.value as USStates }))
                }}
              >
                {Object.entries(USStates).map((eachState) => {
                  return (
                    <MenuItem key={eachState[1]} value={eachState[1]}>
                      {startCase(camelCase(eachState[0]))}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
            <DetailInput<TrailSystem>
              label="Parking GPS Latitude"
              field="parkingGpsLat"
              type="number"
              placeholder="e.g. 42.3601"
              helperText="Up to 15 points of precision are supported for GPS latitude."
              tempDetail={tempTrailSystem}
              setTempDetail={setTempTrailSystem}
              keyDownSubmit={keyDownSubmit}
            />
            <DetailInput<TrailSystem>
              label="Parking GPS Longitude"
              field="parkingGpsLong"
              type="number"
              placeholder="e.g. 42.3601"
              helperText="Up to 15 points of precision are supported for GPS latitude."
              tempDetail={tempTrailSystem}
              setTempDetail={setTempTrailSystem}
              keyDownSubmit={keyDownSubmit}
            />
            {hasViewWeatherGroupPermissions && weatherGroupsResponse?.data && (
              <>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="trail-system-weather-group-select-label">Weather Group</InputLabel>
                  <Select
                    labelId="trail-system-weather-group-select-label"
                    id="trail-system-weather-group-select"
                    value={tempTrailSystem.weatherGroupId || ''}
                    label="Weather Group"
                    onChange={(e) => {
                      setTempTrailSystem((prev) => ({ ...prev, weatherGroupId: Number(e.target.value) }))
                    }}
                  >
                    <MenuItem value="">-Select-</MenuItem>
                    {weatherGroupsResponse.data.map((eachWeatherGroup) => {
                      return (
                        <MenuItem key={eachWeatherGroup.id} value={eachWeatherGroup.id}>
                          {eachWeatherGroup.name}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="trail-system-display-weather-group-select-label">Display Weather Group</InputLabel>
                  <Select
                    labelId="trail-system-display-weather-group-select-label"
                    id="trail-system-display-weather-group-select"
                    value={tempTrailSystem.displayWeatherGroupId || ''}
                    label="Display Weather Group"
                    onChange={(e) => {
                      setTempTrailSystem((prev) => ({ ...prev, displayWeatherGroupId: Number(e.target.value) }))
                    }}
                  >
                    <MenuItem value="">-Select-</MenuItem>
                    {weatherGroupsResponse.data.map((eachWeatherGroup) => {
                      return (
                        <MenuItem key={eachWeatherGroup.id} value={eachWeatherGroup.id}>
                          {eachWeatherGroup.name}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </>
            )}
            {formattedUser && !formattedUser.trailSystems.length && (
              <DetailInput<TrailSystem>
                label="Sort Weight"
                field="sortWeight"
                type="number"
                helperText="Lower numbers will appear first in lists (default 99)"
                tempDetail={tempTrailSystem}
                setTempDetail={setTempTrailSystem}
                keyDownSubmit={keyDownSubmit}
              />
            )}
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="trail-system-active-select-label">Active</InputLabel>
              <Select
                labelId="trail-system-active-select-label"
                id="trail-system-active-select"
                value={tempTrailSystem.active === false ? 'false' : 'true'}
                label="Active"
                onChange={(e) => {
                  setTempTrailSystem((prev) => ({ ...prev, active: e.target.value === 'false' ? false : true }))
                }}
              >
                <MenuItem value="true">True</MenuItem>
                <MenuItem value="false">False</MenuItem>
              </Select>
            </FormControl>
          </Stack>
          <Stack direction="row" justifyContent="end" alignItems="center" spacing={2}>
            <Button variant="outlined" onClick={handleCancel}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSubmit}>
              Save
            </Button>
          </Stack>
        </>
      )}
    </>
  )
}
export default TrailSystemForm

import React, { useEffect, useMemo, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useUrlStateParams } from '../utils/url'

import { AppRegions } from '../models/AppRegions.model'

import Loading from '../components/Loading/Loading.component'
import { Box } from '@mui/material'
import UserService from '../services/UserService/UserService'

type AppStateContextType = {
  setAppLoading: (newIsLoading?: boolean) => void
  setRegion: (newRegion: AppRegions) => void
  getRegion: () => AppRegions
}
export const AppStateContext: AppStateContextType = {
  setAppLoading: () => {
    // if used outside of AppStateProvider, this function will not have been replaced so throw error
    throw new Error('AppStateContext must be used within a AppStateProvider')
  },
  setRegion: () => {
    // if used outside of AppStateProvider, this function will not have been replaced so throw error
    throw new Error('AppStateContext must be used within a AppStateProvider')
  },
  getRegion: () => {
    // if used outside of AppStateProvider, this function will not have been replaced so throw error
    throw new Error('AppStateContext must be used within a AppStateProvider')
  },
}

type AppStateProviderProps = { children: React.ReactNode }

export const AppStateProvider = ({ children }: AppStateProviderProps): JSX.Element => {
  const { user, isLoading } = useAuth0()
  const formattedUser = useMemo(() => (user ? UserService.formatUser(user) : undefined), [user])
  const [appLoading, setAppLoading] = useState(true)
  const defaultRegion = useMemo(() => formattedUser?.regions[0] || AppRegions.NWA, [formattedUser])
  const [region, setRegion] = useUrlStateParams<AppRegions>(
    defaultRegion,
    'region',
    (value) => value,
    (value) => (Object.values(AppRegions).includes(value as AppRegions) ? (value as AppRegions) : defaultRegion),
  )

  useEffect(() => {
    setAppLoading(isLoading)
  }, [isLoading])

  useEffect(() => {
    AppStateContext.setRegion(defaultRegion)
  }, [defaultRegion])

  AppStateContext.setAppLoading = (newIsLoading = true) => {
    setAppLoading(newIsLoading)
  }
  AppStateContext.setRegion = (newRegion = AppRegions.NWA) => {
    setRegion(newRegion)
    // trigger app reload
    setAppLoading(true)
    setTimeout(() => {
      setAppLoading(false)
    }, 1000)
  }
  AppStateContext.getRegion = () => {
    return region
  }
  return (
    <>
      {appLoading ? (
        <Box sx={{ width: '100%', height: '100vh' }}>
          <Loading />
        </Box>
      ) : (
        children
      )}
    </>
  )
}

import React from 'react'
import { AppUpdatePrompter } from '../components/AppUpdatePrompter/AppUpdatePrompter.component'

type AppUpdateContextType = {
  triggerAppUpdate: () => void
}
export const AppUpdateContext: AppUpdateContextType = {
  triggerAppUpdate: () => {
    // if used outside of AppUpdateProvider, this function will not have been replaced so throw error
    throw new Error('AppUpdateContext must be used within a AppUpdateProvider')
  },
}

type AppUpdateProviderProps = { children: React.ReactNode }

export const AppUpdateProvider = ({ children }: AppUpdateProviderProps): JSX.Element => {
  const [needsUpdate, setNeedsUpdate] = React.useState(false)

  AppUpdateContext.triggerAppUpdate = () => {
    setNeedsUpdate(true)
  }
  return (
    <>
      {needsUpdate && <AppUpdatePrompter />}
      {children}
    </>
  )
}

import { FormControl, FormHelperText, InputLabel, OutlinedInput } from '@mui/material'

type DetailInputProps<T> = {
  label: string
  field: keyof T
  type?: 'text' | 'number' | 'email' | 'url'
  required?: boolean
  placeholder?: string
  helperText?: string
  tempDetail: Partial<T>
  setTempDetail: React.Dispatch<React.SetStateAction<Partial<T>>>
  keyDownSubmit: (e: React.KeyboardEvent) => void
}
function DetailInput<T>(props: DetailInputProps<T>) {
  const {
    label,
    field,
    required = false,
    helperText,
    placeholder,
    type = 'text',
    tempDetail,
    setTempDetail,
    keyDownSubmit,
  } = props

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel>{label}</InputLabel>
      <OutlinedInput
        id={`detail-input-${String(field)}`}
        label={label}
        value={tempDetail[field] === null || tempDetail[field] === undefined ? '' : tempDetail[field]}
        type={type}
        placeholder={placeholder}
        required={required}
        onChange={(e) => {
          setTempDetail((prev) => ({ ...prev, [field]: e.target.value }))
        }}
        onKeyDown={keyDownSubmit}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}
export default DetailInput
